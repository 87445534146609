import { FC, useState } from 'react'
import { Outlet } from 'react-router'
import LoadingModal from './LoadingModal'

const AuthLayout: FC = () => {
  const [loading, setLoading] = useState(false)

  return (
    <div className={`h-[100vh] w-[100vw] flex items-center justify-center ${loading ? 'bg-dark-blue' : ''}`}>
      <Outlet context={{ setLoading: setLoading }}/>
      {!loading && <LoadingModal />}
    </div>
  )
}

export default AuthLayout
