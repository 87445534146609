import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus
} from '@azure/msal-browser'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { JwtPayload, jwtDecode } from 'jwt-decode'
import { Button } from 'primereact/button'
import { FC, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { Navigate, useLocation, useOutletContext } from 'react-router'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { useGetUser } from '../api/user-API'
import { ReactComponent as LogoIcon } from '../assets/svg/gm-logo.svg'
import { protoService } from '../proto/ProtoService'
import { User } from '../types/types'
import {
  AccessTokenKey,
  CurrentUserKey,
  GMMSRaceTeamKey,
  RescaleTokenKey
} from '../utils/constants'
import { appEventInstance } from '../utils/event'
import { currentTeamState, currentUserState, teamListState } from '../utils/state-atoms'

// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/login-user.md#redirecturi-considerations

export const accessTokenRequest = {
  scopes: [process.env.REACT_APP_AD_CLIENT_SCOPE || '', 'user.read'],
  account: undefined
}

interface IAccessToken extends JwtPayload {
  roles: string[]
}

const savedTeam = localStorage.getItem(GMMSRaceTeamKey)

const Login: FC = () => {
  const { state } = useLocation()
  const { instance: msalInstance, inProgress } = useMsal()
  const isAuthenticated = useIsAuthenticated()
  const [{ data: userData, loading: loadingUserInfo }, fetchUser] = useGetUser('')
  const [tokenIsReady, setTokenIsReady] = useState(false)
  const [userInfoIsReady, setUserInfoIsReady] = useState(false)
  const setUserInfo = useSetRecoilState(currentUserState)
  const [teamList, setTeamList] = useRecoilState(teamListState)
  const [currentTeam, setCurrentTeam] = useRecoilState(currentTeamState)
  const outletSetLoading = useOutletContext() as any
  outletSetLoading.setLoading(!isAuthenticated && inProgress === InteractionStatus.None && !loadingUserInfo)

  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS && event.payload) {
      console.log('Token is ready:', event)
      const authenticationResult = event.payload as AuthenticationResult
      localStorage.setItem('ACCESS_TOKEN', authenticationResult.accessToken)
      msalInstance.setActiveAccount(authenticationResult.account)
      setTokenIsReady(true)
    }
  })

  useEffect(() => {
    const accounts = msalInstance.getAllAccounts()
    console.log(accounts)
    if (accounts && accounts.length) {
      msalInstance.setActiveAccount(accounts[0])
    } else {
      //
    }
  }, [])

  useEffect(() => {
    if (userData) {
      // console.log('>>>User Data: ', userData)
      if (userData.protoBytesList && userData.protoBytesList.length > 0) {
        const user: User = protoService.deSerialize(userData.protoBytesList[0], 'user')
        setUserInfo(user)
        appEventInstance.userLoggedIn()
        localStorage.setItem(CurrentUserKey, JSON.stringify(user))
        const savedTeam = localStorage.getItem(GMMSRaceTeamKey)
        if (savedTeam && teamList.includes(savedTeam)) {
          setCurrentTeam(savedTeam)
        } else if (teamList.includes(user.raceTeamId)) {
          if (user.raceTeamId) {
            if (!localStorage.getItem(GMMSRaceTeamKey)) {
              localStorage.setItem(GMMSRaceTeamKey, user.raceTeamId)
            }
            setCurrentTeam(user.raceTeamId)
          }
        }

        setUserInfoIsReady(true)
        if (user.rescaleTokens && user.rescaleTokens.length) {
          localStorage.setItem(RescaleTokenKey, user.rescaleTokens[0].token)
        }
      }
    }
  }, [userData])

  useEffect(() => {
    if (isAuthenticated) {
      let acct
      if (acct = msalInstance.getActiveAccount()) {
        msalInstance
          .acquireTokenSilent(accessTokenRequest)
          .then((accessTokenResponse) => {
            // Acquire token silent success
            // Set local storage token
            localStorage.setItem(AccessTokenKey, accessTokenResponse.accessToken)
            const decodedToken = jwtDecode<IAccessToken>(accessTokenResponse.accessToken)
            let defaultTeam
            if (decodedToken && decodedToken.roles) {
              const teamFlag = 'RaceTeam.'
              const teams = decodedToken.roles
                .filter((item) => item.startsWith(teamFlag))
                .map((item) => {
                  return item.replace(teamFlag, '')
                })
              setTeamList(teams)
              if (teams.length > 0) {
                defaultTeam = teams[0]
              }
              if (!teams.includes(currentTeam)) {
                setCurrentTeam(defaultTeam)
              }
              if (!savedTeam) {
                localStorage.setItem(GMMSRaceTeamKey, defaultTeam)
              }
            }
            fetchUser({
              url: `/Users/ref/${accessTokenResponse.account.localAccountId}`
            })
          })
          .catch((err) => {
            console.error(err)
            toast.error('Error logging in.')
          })
      }
    }
  }, [isAuthenticated])

  console.log(isAuthenticated, tokenIsReady, userInfoIsReady)
  if (isAuthenticated && tokenIsReady && userInfoIsReady) {
    console.log('>>>Logged in and navigate to home')
    return <Navigate to={(state && state.ref) || '/'} replace={true} />
  }

  const handleLogin = async () => {
    try {
      await msalInstance.loginRedirect()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="basis-0 grow flex flex-col items-center justify-center space-y-4 bg-white py-20 mx-auto max-w-[600px]">
      {!isAuthenticated && inProgress === InteractionStatus.None && !loadingUserInfo && (
        <>
          <LogoIcon className="w-20 h-20 fill-gm-blue" />
          <h1 className="text-gm-blue text-xl font-semibold">AERO DESIGN</h1>
          <div className="pt-4">Login to continue</div>
          <Button className="px-14" onClick={handleLogin} label="Login Now" />
        </>
      )}
    </div>
  )
}

export default Login
