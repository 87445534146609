import { Crumb } from "../components/Breadcrumbs";
import { Tree, TreeNode } from "./tree";

export class BreadcrumbPaths {
  private breadcrumbTreeQueryIds = new Tree()
  private breadcrumbTreeTitles = new Tree()
  private breadcrumbTreeNames = new Tree()
  private breadcrumbTreeUrlLinks = new Tree()


  public addBreadcrumbPath(routePath: string[], queryIds: string[], titles: string[]) {

    this.breadcrumbTreeQueryIds.addDescendantValues(routePath, queryIds)

    this.breadcrumbTreeTitles.addDescendantValues(routePath, titles)

    this.breadcrumbTreeNames.createDescendantBranch(routePath)

    this.breadcrumbTreeUrlLinks.createDescendantBranch(routePath)
  }

  public getBreadcrumbRoutePath(routeName: string): string[] {
    return this.breadcrumbTreeQueryIds.getDescendantNamePath(routeName)
  }

  /**
   * Description placeholder
   *
   * @public
   * @param {string} route ex: "/submissions", "/projects"
   * @param {string} name breadcrumb name for the specific route ex: submission id, project id
   */
  public setBreadcrumbName(route: string, name: string) {
    const nameNode: TreeNode | null = this.breadcrumbTreeNames.findNode(route)
    nameNode?.setValue(name)  // set value so can persist when page refreshes, reroutes, etc
  }

  public getBreadcrumbNames(routePath: string[]): string[] {
    return this.breadcrumbTreeNames.getDescendantValues(routePath)
  }

  public getBreadcrumbTitles(routePath: string[]): string[] {
    return this.breadcrumbTreeTitles.getDescendantValues(routePath)
  }

  public findNodeRoute(route: string): TreeNode | null {
    return this.breadcrumbTreeQueryIds.findNode(route)
  }

  /**
   * Description placeholder
   *
   * @public
   * @param {string} route
   * @param {string} url (e.g. "/submissions/666a0347d1a30849d87bcef3", "/projects?vehicleId=66857ee159a15bd4829ff73c")
   */
  public setBreadcrumbUrl(route: string, url: string) {
    const urlNode: TreeNode | null = this.breadcrumbTreeUrlLinks.findNode(route)
    urlNode?.setValue(url)
  }

  public getBreadcrumbUrl(routePath: string[]): string[] {
    return this.breadcrumbTreeUrlLinks.getDescendantValues(routePath)
  }

  public print(): void {
    console.log("Breadcrumb Tree Query Ids:")
    this.breadcrumbTreeQueryIds.print()

    console.log("Breadcrumb Tree Titles:")
    this.breadcrumbTreeTitles.print()

    console.log("Breadcrumb Tree Names:")
    this.breadcrumbTreeNames.print()

    console.log("Breadcrumb Tree Url Links:")
    this.breadcrumbTreeUrlLinks.print()
  }
}


export function createBreadcrumbs(titles: string[], names: string[], links: string[]) {
  let result: Crumb[] = []

  const breadcrumbLength: number = titles.length
  for (let i = 0; i < breadcrumbLength; i++) {
    result.push({
      title: titles[i],
      name: names[i],
      url: links[i],
    })
  }

  return result
}
