import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { FC, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import {
  useGetMethodologySet,
  useGetMethodologySets,
} from '../api/methodology-set-API'
import { PlusIcon } from '../components/Icons'
import { protoService } from '../proto/ProtoService'
import { MethodologySet } from '../types/types'
import { useForm } from 'react-hook-form'
import { DropdownStyle, TabMenuStyle } from '../utils/styling-constants'
import { TabMenu } from 'primereact/tabmenu'
import { JsonView, allExpanded, defaultStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';

const tabItems = [
  { label: 'ANSA' },
  { label: 'StarCCM' },
  { label: 'Periscope' },
];

const properties = [
  "ansa", "starccm", "periscope"
]

type BaiscMethodologyInfo = {
  name: string
  version: string
}

const MethodologySets: FC = () => {
  const [mode, setMode] = useState('')
  const [{ loading: loadingMethodologySets, data: methodologySetsData }, fetchMethodologySets] = useGetMethodologySets()
  const [{ loading: loadingOneMethodologySet, data: oneMethodologySetData }, fetchOneMethodologySet] = useGetMethodologySet()

  const [methodologySets, setMethodologySets] = useState<MethodologySet[]>([])
  const [currentMethodologySet, setCurrentMethodologySet] = useState<MethodologySet>()
  const [currentTabIndex, setCurrentTabIndex] = useState(0)
  let currentTabInfo = ""
  if (currentMethodologySet) {
    currentTabInfo = JSON.stringify(
      currentMethodologySet[properties[currentTabIndex]], null, 2
    )
  }

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm<MethodologySet>()

  useEffect(() => {
    fetchMethodologySets().catch((err) => {
      toast.error('Error loading methodology sets.')
    })
  }, [])

  useEffect(() => {
    if (methodologySetsData) {
      const tmpList = methodologySetsData.protoBytesList.map((item: string) => {
        return protoService.deSerialize(item, 'methodologyset')
      })
      tmpList && setMethodologySets(tmpList)
    } else {
      setMethodologySets([])
    }
  }, [methodologySetsData])

  useEffect(() => {
    if (oneMethodologySetData) {
      const tmpList = oneMethodologySetData.protoBytesList.map((item: string) => {
        return protoService.deSerialize(item, 'methodologyset')
      })
      tmpList && tmpList.length && setCurrentMethodologySet(tmpList[0])
    } else {
      setCurrentMethodologySet(undefined)
    }
  }, [oneMethodologySetData])

  const handleCreateNewMethodologySet = () => {
    if (mode === 'new') {
      return
    }
    reset()
    setMode('new')
    setValue('name', "")
    setValue('description', "")
    setCurrentMethodologySet({} as MethodologySet)
  }

  const handleItemSelected = (methodologySet) => {
    if (!methodologySet) {
      return
    }
    fetchOneMethodologySet(methodologySet._id).catch((err) => {
      toast.error('Error loading methodology set.')
    })

    setCurrentMethodologySet(methodologySet)
    setMode('edit')
    reset()
    setValue('name', methodologySet.name)
    setValue('description', methodologySet.description)
  }

  return (
    <div className="basis-0 grow flex flex-col items-stretch mt-6 min-w-full space-y-4">
      <div className="flex items-center justify-between pb-4">
        <h1 className="page-title">Methodology Sets</h1>
        <Button
          type="button"
          onClick={handleCreateNewMethodologySet}
          severity="secondary"
          className="icon-button hidden"
          disabled={mode === 'new'}
          tooltip='Create New Methodology Set'
          tooltipOptions={{ position: 'bottom' }}
          data-pr-at='center+45 bottom'
        >
          <PlusIcon className="fill-white" />
        </Button>
      </div>
      <form
        onSubmit={handleSubmit(() => { })}
        className="w-full grid grid-cols-2 gap-x-2 gap-y-4 mt-8">
        <div className="flex items-center col-span-2 space-x-2">
          <Dropdown
            filter
            loading={loadingMethodologySets}
            filterBy="name"
            filterMatchMode="contains"
            showFilterClear
            value={currentMethodologySet}
            emptyMessage="No MethodologySets Found"
            pt={{
              ...DropdownStyle,
              root: {
                className: DropdownStyle.root.className + ' !min-w-[30%] !max-w-[50%]'
              },
            }}
            optionValue="_id"
            optionLabel="name"
            useOptionAsValue
            options={methodologySets}
            valueTemplate={(option) => {
              let value
              if (mode === 'new') {
                value = 'New MethodologySet'
              } else if (option) {
                value = option.name
              } else {
                value = (currentMethodologySet && currentMethodologySet.name) || 'Select Methodology Set'
              }
              return <div>{value}</div>
            }}
            itemTemplate={(option) => {
              return (
                <div>{option && option._id ? `${option.name}` : ''}</div>
              )
            }}
            onChange={(e) => handleItemSelected(e.value)}
            placeholder="Select Methodology Set"
          />
        </div>
      </form>

      <div className="card">
        <TabMenu model={tabItems}
          onChange={(e) => console.log(e)}
          activeIndex={currentTabIndex}
          pt={TabMenuStyle}
          onTabChange={(e) => setCurrentTabIndex(e.index)} />

        {currentMethodologySet &&
          <JsonView data={currentMethodologySet[properties[currentTabIndex]]} shouldExpandNode={allExpanded} style={defaultStyles} />}
      </div>

    </div>
  )
}

export default MethodologySets
