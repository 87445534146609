import { Button } from 'primereact/button'
import { FC, useEffect, useState } from 'react'
import Modal from './Modal'
import CloseButton from './buttons/CloseButton'
import { classNames } from 'primereact/utils'
import { Controller, useForm } from 'react-hook-form'
import { InputText } from 'primereact/inputtext'
import {
  Trajectory
} from '@aero-platform/shared'
import { MultiSelect } from 'primereact/multiselect'
import { SelectOption } from '../types/types'
import { MultiSelectCheckboxStyle } from '../utils/styling-constants'

const title = {
  'edit': 'Edit Trajectory',
  'new': 'Add New Trajectory'
}

type AttitudeSetTrajectoryEditorProps = {
  mode: string,
  allStates: SelectOption[],
  trajectories: Trajectory[],
  trajIndex: number,
  onConfirm: (trajectory: Trajectory, mode: string) => void
  onCancel: () => void
}

const AttitudeSetTrajectoryEditor: FC<AttitudeSetTrajectoryEditorProps> = ({
  mode,
  allStates,
  trajectories,
  trajIndex,
  onConfirm,
  onCancel
}) => {
  const {
    control,
    setValue,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<Trajectory>()
  const [selectedTrajectory] = useState<Trajectory>(
    mode === 'new' ? ({} as Trajectory) : { ...trajectories[trajIndex] } as Trajectory)

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="error">{errors[name]?.message}</small>
    ) : (
      <small className="error">&nbsp;</small>
    )
  }

  const handleSave = async (formData) => {
    console.log('formData', formData)
    if (trajectories.find(
      (trajItem, index) => trajItem.name === formData.name && (trajIndex !== index || mode === 'new'))
    ) {
      setError('name', { type: 'manual', message: 'Trajectory name already exists.' })
      return
    }
    onConfirm && onConfirm(formData, mode as string)
  }

  const handleConfirm = () => {
    onCancel && onCancel()
  }

  useEffect(() => {
    if (mode === 'edit') {
      setValue('name', selectedTrajectory.name)
      setValue('attitudeStates', selectedTrajectory.attitudeStates)
    }
  }, [])

  return (
    <Modal
      isOpen={true}
      withAction={false}
      easyClose={true}
      onCancel={() => onCancel && onCancel()}
      title={title[mode || 'new']}
      showCancelButton={true}
      zIndex={30}
      onConfirm={handleConfirm}>
      <div className='relative'>
        <form
          onSubmit={handleSubmit(handleSave)}
          className="w-full grid grid-cols-8 gap-x-2 gap-y-4 mt-8">
          <div className="field-box col-span-2">
            <Controller
              name="name"
              control={control}
              rules={{ required: 'Name is required.' }}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className="mb-2">
                    Name
                  </label>
                  <InputText
                    id={field.name}
                    {...field}
                    className={classNames({ error: fieldState.error })}
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>

          <div className="field-box col-span-6">
            <Controller
              // @ts-ignore
              name="attitudeStates"
              control={control}
              rules={{ required: 'State is required.' }}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className="mb-2">
                    Attitude States
                  </label>
                  <MultiSelect
                    id={field.name}
                    value={field.value}
                    options={allStates}
                    onChange={(e) => field.onChange(e.value)}
                    emptyMessage='No states selected'
                    display='chip'
                    pt={{
                      ...MultiSelectCheckboxStyle,
                      root: {
                        className: 'py-1 min-w-[100%] max-w-[100%] bg-transparent dark:border-neutral-70 dark:bg-transparent'
                      },
                      token: {
                        className: '!py-0'
                      },
                      header: {
                        className: 'dark:bg-neutral-70 dark:border-neutral-70'
                      },
                      filterContainer: {
                        className: 'flex items-center basis-0 grow'
                      },
                      filterIcon: {
                        className: 'right-2'
                      },
                      filterInput: {
                        root: {
                          className: '!py-1 min-w-full bg-transparent dark:bg-transparent dark:!border-neutral-10'
                        }
                      },
                      wrapper: {
                        className: 'dark:bg-neutral-70 dark:border-neutral-70'
                      },
                      item: {
                        className: 'py-2 dark:hover:bg-gm-80 dark:bg-neutral-70'
                      },
                      emptyMessage: {
                        className: 'px-4'
                      }
                    }}

                  />
                </>
              )}
            />
            {getFormErrorMessage('states')}
          </div>


          <div className="w-full col-span-8 pt-6 flex items-center justify-end space-x-6">
            <Button
              type="submit"
              label="Save Trajectory"
            />
            <CloseButton label="Close" onClick={() => onCancel && onCancel()} />
          </div>
        </form>
      </div >
    </Modal >
  )
}

export default AttitudeSetTrajectoryEditor
