import { RunStatus, WorkflowRunGroup } from "@aero-platform/shared/generated/AeroStructures";
import { RunStatusCircle } from "./StatusCircle";
import { RunStatusDatatableText } from "./DataTableText";

export const MasterStatesNameBodyTemplates = (rowData: WorkflowRunGroup, status: RunStatus | undefined) => {
  // note: need Workflow's (overallStatus?: RunStatus;), so can add supplumental value to body template
  // dependant on workflowTasks' WorkflowTaskStatus.runStatus
  return (
    <div className="flex items-center">
      <div className="flex justify-center items-center">  {/* border-solid border-2 border-black*/}
        <RunStatusCircle status={status} />
      </div>
      <div className="flex flex-col ">
        <RunStatusDatatableText status={status}>
          {rowData.name}
        </RunStatusDatatableText>
      </div>
    </div>
  )
}
