import { FC } from 'react'
import { Outlet } from 'react-router'
import ProtectedRoutes from '../routing/ProtectedRoutes'
import Footer from './Footer'
import Navbar from './Navbar'

const MainLayout: FC = () => {
  return (
    <ProtectedRoutes>
      <div className="min-h-[100vh] flex flex-col items-stretch dark:bg-neutral-90 dark:text-neutral-10">
        <Navbar />
        <div className="px-8 w-full flex flex-col items-center basis-0 grow">
          <Outlet />
        </div>
        <Footer />
      </div>
    </ProtectedRoutes>
  )
}

export default MainLayout
