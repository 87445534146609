import classNames from 'classnames'
import { FC } from 'react'
import { ProjectStatus } from '../../types/types'
import { ProjectStatusDef } from '../../utils/constants'

type ProjectStatusCellProps = {
  status: ProjectStatus
}

const ProjectStatusCell: FC<ProjectStatusCellProps> = ({ status }) => {
  if (status === ProjectStatus.NOT_USED) {
    return null
  }

  const statusClass = classNames('rounded-full min-w-3 min-h-3 max-w-3 max-h-3', {
    'bg-red-500': status === ProjectStatus.ARCHIVED,
    'bg-green-500': status === ProjectStatus.ACTIVE
  })

  return (
    <div className="flex items-center max-w-[120px]">
      <span className={statusClass}></span>
      <span className="ml-2 dark:text-white">
        {ProjectStatusDef[status] && ProjectStatusDef[status].name}
      </span>
    </div>
  )
}

export default ProjectStatusCell
