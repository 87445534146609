import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { classNames } from 'primereact/utils'
import { FC, useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useRecoilValue } from 'recoil'
import { useGetSubmission, useGetSubmissions } from '../../api/submission-API'
import { protoService } from '../../proto/ProtoService'
import {
  Project,
  RunConfig,
  RunConfigProto,
  WorkflowSubmission,
  WorkflowSubmissionTemplate,
  RunStatus,
  AttitudeSet,
  TyreSet,
  DomainSet,
  SimulationSet,
  MethodologySet,
  Trajectory
} from '../../types/types'
import {
  queryString2JsonString,
  cloneWorkflowSubmissionTemplate,
  cloneMethodologySet,
  cloneAttitudeSet,
  cloneDomainSet,
  cloneTyreSet,
  cloneSimulationSet,
  cloneTrajectory,
  cloneRunConfigCase,
} from '../../types/util'
import {
  currentTeamState,
  methodologySetListState,
  submissionTemplateListState,
  attitudeSetListState,
  domainSetListState,
  simulationSetListState,
  tyreSetListState,
} from '../../utils/state-atoms'
import { CopyIcon, LoadingIcon, SaveIcon } from '../Icons'
import Modal from '../Modal'
import CloseButton from '../buttons/CloseButton'
import { DropdownStyle } from '../../utils/styling-constants'
import { RunConfigCase } from '@aero-platform/shared'
import { simpleRunProjection } from '../../utils/constants'


type SubmissionModalProps = {
  mode: string
  submitting: boolean
  project: Project
  latestRuns?: WorkflowSubmission[]
  existingRun?: WorkflowSubmission
  onConfirm: (runConfig: RunConfigProto, submission: WorkflowSubmission) => void
  onCancel: () => void
}

const editorTitle = {
  new: 'Create New Run',
  edit: 'Edit Run'
}

const SubmissionModal: FC<SubmissionModalProps> = ({
  mode,
  submitting,
  project,
  latestRuns,
  existingRun,
  onConfirm,
  onCancel
}) => {
  const {
    control,
    setValue,
    handleSubmit,
    setError,
    watch,
    formState: { errors }
  } = useForm<RunConfig>()

  const currentTeam = useRecoilValue(currentTeamState)
  const methodologySets = useRecoilValue(methodologySetListState)
  const templateList = useRecoilValue(submissionTemplateListState)
  const attitudeSets = useRecoilValue(attitudeSetListState)
  const domainSets = useRecoilValue(domainSetListState)
  const tyreSets = useRecoilValue(tyreSetListState)
  const simulationSets = useRecoilValue(simulationSetListState)


  const [attitudeSetList, setAttitudeSetList] = useState<AttitudeSet[]>(attitudeSets || [])
  const [domainSetList, setDomainSetList] = useState<DomainSet[]>(domainSets || [])
  const [tyreSetList, setTyreSetList] = useState<TyreSet[]>(tyreSets || [])
  const [simulationSetList, setSimulationSetList] = useState<SimulationSet[]>(simulationSets || [])
  const [trajectoryList, setTrajectoryList] = useState<Trajectory[]>([])
  const [methodologySetList, setMethodologySetList] = useState<MethodologySet[]>(methodologySets || [])

  const [projectList] = useState<Project[]>([project])
  const [runFilterFor, setRunFilterFor] = useState<string>('')
  const [geoRuns, setGeoRuns] = useState<WorkflowSubmission[]>(latestRuns || [])
  const [refRuns, setRefRuns] = useState<WorkflowSubmission[]>(latestRuns || [])
  const [dupeRuns, setDupeRuns] = useState<WorkflowSubmission[]>(latestRuns || [])
  const [dupeRun, setDupeRun] = useState<WorkflowSubmission>()
  const [{ data: runData, loading: loadingRun }, fetchRuns] = useGetSubmissions()
  const [{ data: oneRunData, loading: loadingOneRun }, fetchOneRun] = useGetSubmission()
  useEffect(() => {
    let setRunsFunc
    if (runFilterFor === 'geo') {
      setRunsFunc = setGeoRuns
    } else if (runFilterFor === 'dup') {
      setRunsFunc = setDupeRuns
    } else if (runFilterFor === 'ref') {
      setRunsFunc = setRefRuns
    }
    if (runData && runData.protoBytesList.length) {
      const submissions = protoService.decodeProtoResponse(runData, 'workflowsubmission')
      setRunsFunc && setRunsFunc(
        (submissions as WorkflowSubmission[]).map((item) => {
          return item
        })
      )
      // runFilterFor === 'dup' && setRuns(submissions)
    } else {
      /**
       * this is supposed to set a empty array, because a bug of filter resetting,
       * we have to use this for now
       */
      console.log('no runs found')
      setRunsFunc && setRunsFunc([{
        _id: '',
        name: ' '
        // name: 'No runs found'
      } as WorkflowSubmission
      ])
      // runFilterFor === 'dup' && setRuns([])
    }
  }, [runData, runFilterFor])

  useEffect(() => {
    const proj = projectList.find((item) => item._id === project._id)
    setValue('project', proj)
    setValue('selectedMethod', project.method)

    if (mode === 'new') {
      if (project.submissionTemplateObj && project.submissionTemplateObj._id) {
        setValue('template', project.submissionTemplateObj)
      }

      if (project.attitudeSet && project.attitudeSet._id) {
        const selectedAttitudeSet = attitudeSetList.find(
          (item) => item._id === project.attitudeSet._id
        )
        if (selectedAttitudeSet) {
          setValue('selectedAttitudeSet', selectedAttitudeSet)
          setTrajectoryList(selectedAttitudeSet.trajectories || [])
          // fetchVehicleStates(`query=${queryString2JsonString('sequenceId=' + selectedSeq.name)}`)
        } else {
          setAttitudeSetList([...attitudeSetList, project.attitudeSet])
          setValue('selectedAttitudeSet', project.attitudeSet)
          setTrajectoryList(project.attitudeSet.trajectories || [])
        }
        // parseCFDSequence(project.cfdSequence)
      }

      if (project.methodSet && project.methodSet._id) {
        const selectedMethodSet = methodologySetList.find(
          (item) => item._id === project.methodSet._id
        )
        if (selectedMethodSet) {
          setValue('selectedMethodSet', selectedMethodSet)
        } else {
          setMethodologySetList([...methodologySetList, project.methodSet])
          setValue('selectedMethodSet', project.methodSet)
        }
      }

      if (project.domainSet && project.domainSet._id) {
        const selectedDomainSet = domainSetList.find(
          (item) => item._id === project.domainSet._id
        )
        if (selectedDomainSet) {
          setValue('selectedDomainSet', selectedDomainSet)
        } else {
          setDomainSetList([...domainSetList, project.domainSet])
          setValue('selectedDomainSet', project.domainSet)
        }
      }

      if (project.tyreSet && project.tyreSet._id) {
        const selectedTyreSet = tyreSetList.find(
          (item) => item._id === project.tyreSet._id
        )
        if (selectedTyreSet) {
          setValue('selectedTyreSet', selectedTyreSet)
        } else {
          setTyreSetList([...tyreSetList, project.tyreSet])
          setValue('selectedTyreSet', project.tyreSet)
        }
      }

      if (project.simulationSet && project.simulationSet._id) {
        const selectedSimulationSet = simulationSetList.find(
          (item) => item._id === project.simulationSet._id
        )
        if (selectedSimulationSet) {
          setValue('selectedSimulationSet', selectedSimulationSet)
        } else {
          setSimulationSetList([...simulationSetList, project.simulationSet])
          setValue('selectedSimulationSet', project.simulationSet)
        }
      }

      if (project.referenceRunName) {
        setValue('referenceRunId', project.referenceRun)
        setValue('referenceRunName', project.referenceRunName)
        if (!refRuns.find(item => item._id === project.referenceRun)) {
          const tmpRun = {
            _id: project.referenceRun,
            name: project.referenceRunName
          } as WorkflowSubmission
          setRefRuns([...refRuns, tmpRun])
        }
      }

      if (project.baselineRunName) {
        setValue('baselineRunId', project.baselineRunId)
        setValue('baselineRunName', project.baselineRunName)
        if (!geoRuns.find(item => item.name === project.baselineRunName)) {
          const tmpRun = {
            _id: project.baselineRunId,
            name: project.baselineRunName
          } as WorkflowSubmission
          setGeoRuns([...geoRuns, tmpRun])
        }
      }

    } else if (mode === 'edit' && existingRun) {
      if (existingRun?.templateOriginId) {
        const tmpTemplate = templateList.find(item => item._id === existingRun.templateOriginId)
        setValue('template', tmpTemplate as WorkflowSubmissionTemplate)
      }

      const existingConfig = existingRun.runConfig
      setValue('RATGstatus', existingConfig.RATGstatus)

      if (existingConfig.selectedAttitudeSet && existingConfig.selectedAttitudeSet._id) {
        const selectedAttitudeSet = attitudeSetList.find(
          (item) => item._id === existingConfig.selectedAttitudeSet._id
        )
        let trajs: Trajectory[]
        if (selectedAttitudeSet) {
          setValue('selectedAttitudeSet', selectedAttitudeSet)
          trajs = selectedAttitudeSet.trajectories || []
          // fetchVehicleStates(`query=${queryString2JsonString('sequenceId=' + selectedSeq.name)}`)
        } else {
          setAttitudeSetList([...attitudeSetList, existingConfig.selectedAttitudeSet])
          setValue('selectedAttitudeSet', existingConfig.selectedAttitudeSet)
          trajs = existingConfig.selectedAttitudeSet.trajectories || []
        }
        setTrajectoryList(trajs)
        if (existingConfig.selectedTrajectory) {
          const selectedTraj = trajs.find((item) => item.name === existingConfig.selectedTrajectory.name)
          if (selectedTraj) {
            setValue('selectedTrajectory', selectedTraj)
          }
        }
      }

      if (existingConfig.selectedMethodSet && existingConfig.selectedMethodSet._id) {
        const selectedMethodSet = methodologySetList.find(
          (item) => item._id === existingConfig.selectedMethodSet._id
        )
        if (selectedMethodSet) {
          setValue('selectedMethodSet', selectedMethodSet)
        } else {
          setMethodologySetList([...methodologySetList, existingConfig.selectedMethodSet])
          setValue('selectedMethodSet', existingConfig.selectedMethodSet)
        }
      }

      if (existingConfig.selectedDomainSet && existingConfig.selectedDomainSet._id) {
        const selectedDomainSet = domainSetList.find(
          (item) => item._id === existingConfig.selectedDomainSet._id
        )
        if (selectedDomainSet) {
          setValue('selectedDomainSet', selectedDomainSet)
        } else {
          setDomainSetList([...domainSetList, existingConfig.selectedDomainSet])
          setValue('selectedDomainSet', existingConfig.selectedDomainSet)
        }
      }

      if (existingConfig.selectedTyreSet && existingConfig.selectedTyreSet._id) {
        const selectedTyreSet = tyreSetList.find(
          (item) => item._id === existingConfig.selectedTyreSet._id
        )
        if (selectedTyreSet) {
          setValue('selectedTyreSet', selectedTyreSet)
        } else {
          setTyreSetList([...tyreSetList, existingConfig.selectedTyreSet])
          setValue('selectedTyreSet', existingConfig.selectedTyreSet)
        }
      }

      if (existingConfig.selectedSimulationSet && existingConfig.selectedSimulationSet._id) {
        const selectedSimulationSet = simulationSetList.find(
          (item) => item._id === existingConfig.selectedSimulationSet._id
        )
        if (selectedSimulationSet) {
          setValue('selectedSimulationSet', selectedSimulationSet)
        } else {
          setSimulationSetList([...simulationSetList, existingConfig.selectedSimulationSet])
          setValue('selectedSimulationSet', existingConfig.selectedSimulationSet)
        }
      }

      // master geo init
      if (existingRun.runConfig.baselineRunId) {
        // setValue('baselineRun', existingRun.runConfig.baselineRun)
        if (!geoRuns.find(item => item._id === existingRun.runConfig.baselineRunId)) {
          const tmpRunObj = {
            _id: existingRun.runConfig.baselineRunId,
            name: existingRun.runConfig.baselineRunName
          } as WorkflowSubmission
          setGeoRuns([tmpRunObj, ...geoRuns])
        }
        setValue('baselineRunName', existingRun.runConfig.baselineRunName)
        setValue('baselineRunId', existingRun.runConfig.baselineRunId)
      }

      // ref run init
      if (existingRun.runConfig.referenceRunId) {
        // setValue('baselineRun', existingRun.runConfig.baselineRun)
        if (!refRuns.find(item => item._id === existingRun.runConfig.referenceRunId)) {
          const tmpRunObj = {
            _id: existingRun.runConfig.referenceRunId,
            name: existingRun.runConfig.referenceRunName
          } as WorkflowSubmission
          setRefRuns([tmpRunObj, ...refRuns])
        }
        setValue('referenceRunName', existingRun.runConfig.referenceRunName)
        setValue('referenceRunId', existingRun.runConfig.referenceRunId)
      }

      setValue('description', existingRun.runConfig.description)
    }
  }, [])

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="error">{errors[name]?.message}</small>
    ) : (
      <small className="error">&nbsp;</small>
    )
  }

  const handleDuplicateRun = async () => {
    if (!dupeRun) {
      return
    }

    let tmpRun = dupeRuns?.find(item => item._id === dupeRun._id)
    if (!tmpRun) {
      return
    }

    try {
      const resp = await fetchOneRun(tmpRun._id)
      if (resp && resp.data && resp.data.protoBytesList.length) {
        const submissions = protoService.decodeProtoResponse(resp.data, 'workflowsubmission')
        tmpRun = submissions[0]
      } else {
        toast.error('No run is found.')
        return
      }
    } catch (err) {
      toast.error('Error loading the run.')
      return
    }

    const existingConfig: RunConfig = tmpRun.runConfig
    setValue('RATGstatus', existingConfig.RATGstatus)
    if (existingConfig.selectedAttitudeSet && existingConfig.selectedAttitudeSet._id) {
      const selectedAttitudeSet = attitudeSetList.find(
        (item) => item._id === existingConfig.selectedAttitudeSet._id
      )
      let trajs: Trajectory[]
      if (selectedAttitudeSet) {
        setValue('selectedAttitudeSet', selectedAttitudeSet)
        trajs = selectedAttitudeSet.trajectories || []
      } else {
        setAttitudeSetList([...attitudeSetList, existingConfig.selectedAttitudeSet])
        setValue('selectedAttitudeSet', existingConfig.selectedAttitudeSet)
        trajs = existingConfig.selectedAttitudeSet.trajectories || []
      }
      setTrajectoryList(trajs)
      if (existingConfig.selectedTrajectory) {
        const selectedTraj = trajs.find((item) => item.name === existingConfig.selectedTrajectory.name)
        if (selectedTraj) {
          setValue('selectedTrajectory', selectedTraj)
        }
      }
    }

    if (existingConfig.selectedDomainSet && existingConfig.selectedDomainSet._id) {
      const selectedDomainSet = domainSetList.find(
        (item) => item._id === existingConfig.selectedDomainSet._id
      )
      if (selectedDomainSet) {
        setValue('selectedDomainSet', selectedDomainSet)
      } else {
        setDomainSetList([...domainSetList, existingConfig.selectedDomainSet])
        setValue('selectedDomainSet', existingConfig.selectedDomainSet)
      }
    }

    if (existingConfig.selectedTyreSet && existingConfig.selectedTyreSet._id) {
      const selectedTyreSet = tyreSetList.find(
        (item) => item._id === existingConfig.selectedTyreSet._id
      )
      if (selectedTyreSet) {
        setValue('selectedTyreSet', selectedTyreSet)
      } else {
        setTyreSetList([...tyreSetList, existingConfig.selectedTyreSet])
        setValue('selectedTyreSet', existingConfig.selectedTyreSet)
      }
    }

    if (existingConfig.selectedSimulationSet && existingConfig.selectedSimulationSet._id) {
      const selectedSimulationSet = simulationSetList.find(
        (item) => item._id === existingConfig.selectedSimulationSet._id
      )
      if (selectedSimulationSet) {
        setValue('selectedSimulationSet', selectedSimulationSet)
      } else {
        setSimulationSetList([...simulationSetList, existingConfig.selectedSimulationSet])
        setValue('selectedSimulationSet', existingConfig.selectedSimulationSet)
      }
    }

    if (existingConfig.selectedCases) {
      // @ts-ignore
      let key = new Date().getTime()
      const newCases: RunConfigCase[] = existingConfig.selectedCases.map((item) => {
        return {
          ...item,
          key: '' + key++
        } as RunConfigCase
      })

      setValue('selectedCases', newCases)
    }

    if (tmpRun?.templateOriginId) {
      const tmpTemplate = templateList.find(item => item._id === tmpRun.templateOriginId)
      setValue('template', tmpTemplate as WorkflowSubmissionTemplate)
    }

    if (existingConfig.selectedMethodSet) {
      const tmpMethod = methodologySetList.find(item => item._id === existingConfig.selectedMethodSet._id)
      tmpMethod && setValue('selectedMethodSet', tmpMethod as MethodologySet)
    }

    setValue('baselineRunName', tmpRun.name)
    setValue('referenceRunName', tmpRun.name)

    const geoRunObj = geoRuns.find(item => item._id === tmpRun._id)
    const refRunObj = refRuns.find(item => item._id === tmpRun._id)
    if (!geoRunObj) {
      setGeoRuns([...geoRuns, tmpRun])
    }
    if (!refRunObj) {
      setRefRuns([...refRuns, tmpRun])
    }
    setValue('baselineRunId', dupeRun._id)
    setValue('referenceRunId', dupeRun._id)

    setValue('description', existingConfig.description)
  }

  const onSubmit: SubmitHandler<RunConfig> = async (formData: RunConfig) => {
    console.log('Run Form Data=', formData)

    try {
      // prepare RunConfig
      const tmpRunConfig = new RunConfigProto()

      tmpRunConfig.raceTeamId = currentTeam
      tmpRunConfig.RATGstatus = formData.RATGstatus
      tmpRunConfig.expandForVehicleStates = true
      tmpRunConfig.description = formData.description
      if (formData.selectedMethodSet) {
        tmpRunConfig.selectedMethodSet = cloneMethodologySet(formData.selectedMethodSet)
      }
      if (formData.selectedTrajectory) {
        tmpRunConfig.selectedTrajectory = cloneTrajectory(formData.selectedTrajectory)
      }
      if (formData.selectedAttitudeSet) {
        tmpRunConfig.selectedAttitudeSet = cloneAttitudeSet(formData.selectedAttitudeSet)
      }
      if (formData.selectedDomainSet) {
        tmpRunConfig.selectedDomainSet = cloneDomainSet(formData.selectedDomainSet)
      }
      if (formData.selectedTyreSet) {
        tmpRunConfig.selectedTyreSet = cloneTyreSet(formData.selectedTyreSet)
      }
      if (formData.selectedSimulationSet) {
        tmpRunConfig.selectedSimulationSet = cloneSimulationSet(formData.selectedSimulationSet)
      }
      if (formData.selectedCases) {
        tmpRunConfig.selectedCases = formData.selectedCases.map((item) => {
          return cloneRunConfigCase(item)
        })
      }
      if (formData.baselineRunId) {
        tmpRunConfig.baselineRunId = formData.baselineRunId as string
        const runName = geoRuns.find((item) => item._id === formData.baselineRunId)?.name as string
        tmpRunConfig.baselineRunName = runName
      }
      if (formData.referenceRunId) {
        tmpRunConfig.referenceRunId = formData.referenceRunId as string
        const runName = refRuns.find((item) => item._id === formData.referenceRunId)?.name as string
        tmpRunConfig.referenceRunName = runName
      }
      if (formData.template?._id) {
        tmpRunConfig.workflowSubmissionTemplate = cloneWorkflowSubmissionTemplate(formData.template)
      }

      // prepare sumbission
      const tmpSubmission = new WorkflowSubmission()
      if (tmpSubmission) {
        // project info
        if (formData.project) {
          tmpSubmission.projectId = formData.project._id
          tmpSubmission.projectName = formData.project.name
        }
        tmpSubmission.raceTeamId = currentTeam

        if (formData.template?._id) {
          tmpSubmission.templateOriginId = formData.template._id
          tmpSubmission.templateOriginName = formData.template.name
        }

        onConfirm && onConfirm(tmpRunConfig, tmpSubmission)
      }
    } catch (err) {
      console.error(err)
      toast.error('Error saving run.')
    }
  }

  const handleConfirm = () => {
    onCancel && onCancel()
  }

  return (
    <Modal
      isOpen={true}
      withAction={false}
      easyClose={true}
      onCancel={() => onCancel && onCancel()}
      title={editorTitle[mode] + (mode === 'edit' ? `: ${existingRun?.name}` : '')}
      showCancelButton={true}
      zIndex={30}
      onConfirm={handleConfirm}>
      <div className='flex flex-col items-stretch'>
        {mode === 'new' && <div className="flex mt-4">
          <div className="flex items-center space-x-2 p-2 rounded-md bg-light-blue dark:bg-neutral-80 min-w-fit">
            <div className="dark:text-neutral">Duplicate Run</div>
            <Dropdown
              disabled={submitting}
              loading={loadingRun && runFilterFor === 'dup'}
              value={dupeRun}
              pt={{
                ...DropdownStyle,
                root: {
                  ...DropdownStyle.root,
                  className: 'min-w-[250px] max-w-[250px] dark:bg-transparent dark:border-neutral-70'
                },
              }}
              filter
              filterBy='name'
              dataKey='_id'
              showClear
              onFilter={(e) => {
                if (e.filter.length < 2) {
                  return
                }
                setRunFilterFor('dup')
                const nameSearch = { $regex: e.filter, $options: 'i' }
                const query = queryString2JsonString(
                  'name=' + encodeURIComponent(JSON.stringify(nameSearch))
                )
                fetchRuns(`query=${query}&proj=${simpleRunProjection}`)
              }}
              onChange={async (e) => {
                if (e.value) {
                  e.value._id && setDupeRun(e.value)
                } else {
                  setDupeRun(e.value)
                }
              }}
              itemTemplate={(item) => {
                return item._id ? item.name : null
              }}
              options={dupeRuns}
              optionLabel="name"
              optionValue="_id"
              useOptionAsValue
              placeholder="Select Run"
              emptyMessage="&nbsp;&nbsp;Use Search to select run"
              emptyFilterMessage="&nbsp;&nbsp;No runs found"
            />
            <Button
              onClick={handleDuplicateRun}
              disabled={!dupeRun || !dupeRun?._id || submitting}
            >
              {loadingOneRun && (<LoadingIcon className="w-4 h-4 fill-white animate-spin" />)}
              {!loadingOneRun && (<CopyIcon className="w-4 h-4 fill-white" />)}
              <span className="ml-1">Duplicate</span>
            </Button>
          </div>
        </div>}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full grid grid-cols-3 gap-x-2 gap-y-4 mt-8">

          <div className="field-box">
            <Controller
              name="project"
              rules={{ required: 'Project is required.' }}
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className="mb-2">
                    Project
                  </label>
                  <Dropdown
                    disabled
                    value={field.value}
                    pt={DropdownStyle}
                    onChange={(e) => {
                      field.onChange(e.value)
                    }}
                    className={classNames({ error: fieldState.error })}
                    options={projectList}
                    optionLabel="name"
                    optionValue="_id"
                    useOptionAsValue
                    placeholder="Select Project"
                    emptyMessage="&nbsp;&nbsp;No Projects"
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>

          <div className="field-box col-span-1">
            <Controller
              name="RATGstatus"
              disabled={submitting || (mode === 'edit' && existingRun?.overallStatus !== RunStatus.CREATED)}
              control={control}
              defaultValue="True"
              rules={{ required: 'RATG status is required.' }}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className="mb-2">
                    RATG
                  </label>
                  <Dropdown
                    value={field.value}
                    pt={DropdownStyle}
                    onChange={(e) => {
                      field.onChange(e.value)
                    }}
                    className={classNames({ error: fieldState.error })}
                    options={['True', 'False']}
                    useOptionAsValue
                    placeholder="Select Status"
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>
          <div className="field-box  col-span-1">
            <Controller
              name="selectedMethodSet"
              disabled={submitting || (mode === 'edit' && existingRun?.overallStatus !== RunStatus.CREATED)}
              control={control}
              rules={{ required: 'Methodology set is required.' }}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className="mb-2">
                    Methodology Set
                  </label>
                  <Dropdown
                    value={field.value}
                    pt={DropdownStyle}
                    onChange={(e) => field.onChange(e.value)}
                    className={classNames({ error: fieldState.error })}
                    options={methodologySetList}
                    filter
                    filterBy="name"
                    optionLabel="name"
                    optionValue="_id"
                    useOptionAsValue
                    placeholder="Select Methodology Set"
                    emptyMessage="&nbsp;&nbsp;No data"
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>
          <div className="field-box">
            <Controller
              name="template"
              disabled={submitting || (mode === 'edit' && existingRun?.overallStatus !== RunStatus.CREATED)}
              control={control}
              rules={{ required: 'Submission template is required.' }}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className="mb-2">
                    Submission Template
                  </label>
                  <Dropdown
                    value={field.value}
                    pt={DropdownStyle}
                    onChange={(e) => {
                      console.log(e)
                      field.onChange(e.value)
                    }}
                    filter
                    filterBy="name"
                    className={classNames({ error: fieldState.error })}
                    options={templateList}
                    optionLabel="name"
                    optionValue="_id"
                    useOptionAsValue
                    placeholder="Select Template"
                    emptyMessage="&nbsp;&nbsp;No data"
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>

          <div className="field-box">
            <Controller
              name="selectedAttitudeSet"
              disabled={submitting || (mode === 'edit' && existingRun?.overallStatus !== RunStatus.CREATED)}
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className="mb-2">
                    Attitude Set
                  </label>
                  <Dropdown
                    value={field.value}
                    pt={DropdownStyle}
                    filter
                    onChange={(e) => {
                      field.onChange(e.value)
                      // @ts-ignore
                      setTrajectoryList((e.value as AttitudeSet).trajectories || [])
                      // @ts-ignore
                      setValue('selectedTrajectory', null as any)
                      // fetchVehicleStates(`query=${queryString2JsonString('sequenceId=' + e.value.name)}`)
                      // parseCFDSequence(e.value as CFDSequence)
                    }}
                    className={classNames({ error: fieldState.error })}
                    options={attitudeSetList}
                    optionLabel="name"
                    optionValue="_id"
                    useOptionAsValue
                    placeholder="Select Attitude Set"
                    emptyMessage="&nbsp;&nbsp;No data"
                    itemTemplate={(item) => {
                      return item.name + (item.status ? ` (${item.status.substring(0, 1)})` : '')
                    }}
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>

          <div className="field-box">
            <Controller
              name="selectedTrajectory"
              disabled={submitting || (mode === 'edit' && existingRun?.overallStatus !== RunStatus.CREATED)}
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className="mb-2">
                    Trajectory
                  </label>
                  <Dropdown
                    value={field.value}
                    pt={DropdownStyle}
                    onChange={(e) => {
                      field.onChange(e.value)
                    }}
                    className={classNames({ error: fieldState.error })}
                    options={trajectoryList}
                    optionLabel="name"
                    optionValue="name"
                    useOptionAsValue
                    placeholder="Select Trajectory"
                    emptyMessage="&nbsp;&nbsp;No Trajectories"
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>

          <div className="field-box">
            <Controller
              name="selectedDomainSet"
              control={control}
              rules={{ required: 'Domain set is required.' }}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className="mb-2">
                    Domain Set
                  </label>
                  <Dropdown
                    disabled={submitting || (mode === 'edit' && existingRun?.overallStatus !== RunStatus.CREATED)}
                    value={field.value}
                    pt={DropdownStyle}
                    filter
                    onChange={(e) => field.onChange(e.value)}
                    className={classNames({ error: fieldState.error })}
                    options={domainSetList}
                    optionLabel="name"
                    optionValue="_id"
                    useOptionAsValue
                    readOnly={mode === 'view'}
                    placeholder="Select Domain Set"
                    emptyMessage="&nbsp;&nbsp;No Domain Set"
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>
          <div className="field-box">
            <Controller
              name="selectedTyreSet"
              control={control}
              rules={{ required: 'Tyre set is required.' }}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className="mb-2">
                    Tyre Set
                  </label>
                  <Dropdown
                    disabled={submitting || (mode === 'edit' && existingRun?.overallStatus !== RunStatus.CREATED)}
                    value={field.value}
                    pt={DropdownStyle}
                    filter
                    onChange={(e) => field.onChange(e.value)}
                    className={classNames({ error: fieldState.error })}
                    options={tyreSetList}
                    optionLabel="name"
                    optionValue="_id"
                    useOptionAsValue
                    readOnly={mode === 'view'}
                    placeholder="Select Tyre Set"
                    emptyMessage="&nbsp;&nbsp;No Tyre Set"
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>

          <div className="field-box">
            <Controller
              name="selectedSimulationSet"
              control={control}
              rules={{ required: 'Simulation set is required.' }}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className="mb-2">
                    Simulation Set
                  </label>
                  <Dropdown
                    disabled={submitting || (mode === 'edit' && existingRun?.overallStatus !== RunStatus.CREATED)}
                    value={field.value}
                    pt={DropdownStyle}
                    filter
                    onChange={(e) => field.onChange(e.value)}
                    className={classNames({ error: fieldState.error })}
                    options={simulationSetList}
                    optionLabel="name"
                    optionValue="_id"
                    useOptionAsValue
                    readOnly={mode === 'view'}
                    placeholder="Select Simulation Set"
                    emptyMessage="&nbsp;&nbsp;No Simulation Set"
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>

          <div className="field-box">
            <Controller
              name="baselineRunId"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className="mb-2">
                    Master Geometry
                  </label>
                  <Dropdown
                    disabled={submitting || (mode === 'edit' && existingRun?.overallStatus !== RunStatus.CREATED)}
                    value={field.value}
                    pt={DropdownStyle}
                    filter
                    showClear
                    filterBy="name"
                    optionLabel="name"
                    optionValue="_id"
                    loading={loadingRun && runFilterFor === 'geo'}
                    onFilter={(e) => {
                      if (e.filter.length < 2) {
                        return
                      }
                      setRunFilterFor('geo')
                      const nameSearch = { $regex: e.filter, $options: 'i' }
                      const query = queryString2JsonString(
                        'name=' + encodeURIComponent(JSON.stringify(nameSearch))
                      )
                      fetchRuns(`query=${query}&proj=${simpleRunProjection}`)
                    }}
                    onChange={(e) => {
                      if (e.value) {
                        field.onChange(e.value)
                      } else {
                        // @ts-ignore
                        setValue('baselineRunId', '')
                      }
                    }}
                    className={classNames({ error: fieldState.error })}
                    options={geoRuns}
                    placeholder="None"
                    emptyMessage="&nbsp;&nbsp;Use Search to select run"
                    emptyFilterMessage="&nbsp;&nbsp;No runs found"
                    filterPlaceholder="Enter run ID to search"
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>

          <div className="field-box">
            <Controller
              name="referenceRunId"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className="mb-2">
                    Reference Run
                  </label>
                  <Dropdown
                    disabled={submitting || (mode === 'edit' && existingRun?.overallStatus !== RunStatus.CREATED)}
                    loading={loadingRun && runFilterFor === 'ref'}
                    value={field.value}
                    pt={DropdownStyle}
                    filter
                    filterBy="name"
                    dataKey="_id"
                    showClear
                    onFilter={(e) => {
                      if (e.filter.length < 2) {
                        return
                      }
                      setRunFilterFor('ref')
                      const nameSearch = { $regex: e.filter, $options: 'i' }
                      const query = queryString2JsonString(
                        'name=' + encodeURIComponent(JSON.stringify(nameSearch))
                      )
                      fetchRuns(`query=${query}&proj=${simpleRunProjection}`)
                    }}
                    onChange={(e) => {
                      if (e.value) {
                        field.onChange(e.value)
                      } else {
                        setValue('referenceRunId', '')
                      }
                    }}
                    className={classNames({ error: fieldState.error })}
                    options={refRuns}
                    optionLabel="name"
                    optionValue="_id"
                    placeholder="None"
                    emptyMessage="&nbsp;&nbsp;Use Search to select run"
                    emptyFilterMessage="&nbsp;&nbsp;No runs found"
                    filterPlaceholder="Enter run ID to search"
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>

          <div className="field-box">
            <Controller
              name="description"
              control={control}
              disabled={submitting}
              rules={{ required: 'Description is required.' }}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className="mb-2">
                    Description
                  </label>
                  <InputText
                    id={field.name}
                    {...field}
                    className={classNames({ error: fieldState.error })}
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>

          <div className="w-full col-span-3 flex items-center justify-end space-x-4">
            <Button
              type="submit"
              loading={submitting}>
              {submitting && <LoadingIcon className="fill-white w-4 h-4 animate-spin" />}
              {!submitting && <SaveIcon className="fill-white w-4 h-4" />}
              <span className="ml-1">Save</span>
            </Button>
            <CloseButton label="Close" onClick={() => onCancel && onCancel()} />
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default SubmissionModal
