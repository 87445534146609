import { RunConfigCase, WorkflowRunGroup } from "@aero-platform/shared"
import { DataTable } from "primereact/datatable"
import { FC, useState } from "react"
import { CompactTableHeaderClassName } from "../../utils/constants"
import { DeleteIcon, EditIcon, EyeIcon } from "../Icons"
import { Column } from "primereact/column"
import { classNames } from "primereact/utils"
import { Dialog } from "primereact/dialog"
import IconButton from "../buttons/IconButton"

type NewCaseGroupTableProps = {
  workflowSubmissionId: string
  submissionGroupName: string
  cases: RunConfigCase[]
  isCommitting: boolean
  isDeletingCase: boolean
  isUpdatingCase: boolean
  onEditCase: (submissionGroupName: string, caseName: string, caseIndex: number) => void
  onDeleteCase: (submissionGroupName: string, caseName: string, caseIndex: number) => void
}


const NewCaseGroupTable: FC<NewCaseGroupTableProps> = ({
  workflowSubmissionId, cases, submissionGroupName, isCommitting, isDeletingCase, isUpdatingCase, onEditCase, onDeleteCase
}: NewCaseGroupTableProps) => {
  const [caseToShow, setCaseToShow] = useState<RunConfigCase>()
  const [showCaseDetail, setShowCaseDetail] = useState(false)

  const columns = [
    {
      header: 'Name',
      field: 'name',
      headerClassName: CompactTableHeaderClassName,
      bodyClassName: 'font-semibold',
    },
    {
      header: 'FW',
      bodyClassName: 'w-[75px]',
      field: 'selectedWingState.fwState',
      body: (rowData: RunConfigCase) => {
        console.log("rowData===>", rowData)
        const num = rowData.selectedWingState.fwState
        return <span>{num && num.toFixed(1)}</span>
      }
    },
    {
      header: 'DRS(%)',
      bodyClassName: 'w-[75px]',
      field: 'selectedWingState.drs',
    },
    {
      header: 'Attitude',
      field: 'selectedAttitudeState',
      bodyClassName: 'w-[120px]',
      body: (rowData: RunConfigCase) => {
        return (
          <div>{rowData.selectedAttitudeState.name}</div>
        )
      },
    },
    {
      header: 'Domain',
      field: 'selectedDomainState',
      bodyClassName: 'w-[120px]',
      body: (rowData: RunConfigCase) => {
        return (
          <div>{rowData.selectedDomainState.name}</div>
        )
      },
    },
    {
      header: 'Tyre Set',
      field: 'selectedTyreState',
      bodyClassName: 'w-[100px] min-w-[100px]',
      body: (rowData: RunConfigCase) => {
        return (
          <div>{rowData.selectedTyreState.name}</div>
        )
      },
    },
    {
      header: 'Sim Type',
      field: 'selectedSimulationState',
      bodyClassName: 'w-[100px] min-w-[100px]',
      body: (rowData: RunConfigCase) => {
        return (
          <div>{rowData.selectedSimulationState?.name}</div>
        )
      },
    },
    {
      header: 'FRH',
      field: 'selectedAttitudeState.frh',
      bodyClassName: 'w-[75px] max-w-[75px]'
    },
    {
      header: 'RRH',
      field: 'selectedAttitudeState.rrh',
      bodyClassName: 'w-[75px] max-w-[75px]'
    },
    {
      header: 'Steer',
      field: 'selectedAttitudeState.steer',
      bodyClassName: 'w-[75px] max-w-[75px]'
    },
    {
      header: 'Roll',
      field: 'selectedAttitudeState.roll',
      bodyClassName: 'w-[75px] max-w-[75px]',
      body: (rowData: RunConfigCase) => {
        const num = rowData.selectedAttitudeState.roll
        return <span>{num && num.toFixed(1)}</span>
      }
    },
    {
      header: 'Yaw',
      field: 'selectedAttitudeState.yaw',
      bodyClassName: 'w-[75px] max-w-[75px]'
    },
    {
      header: 'Rad',
      field: 'selectedAttitudeState.radius',
      bodyClassName: 'w-[75px] max-w-[75px]'
    },
    {
      header: 'Spd',
      field: 'selectedAttitudeState.speed',
      bodyClassName: 'w-[75px] max-w-[75px]'
    },
    {
      header: 'Exh',
      field: 'selectedAttitudeState.exhaust',
      bodyClassName: 'w-[75px] max-w-[75px]'
    },
  ]

  return (
    <>
      <DataTable
        value={cases}
        stripedRows
        dataKey="name"
        pt={{
          bodyRow: {
            className: 'table-row'
          }
        }}>
        {false &&
          <Column
            rowEditor
            className="case-checkbox"
            headerClassName="one-col-editor-header editor-header"
            bodyStyle={{ textAlign: 'center', padding: '0px 0px' }}
            body={(rowData, options) => {
              return (
                <div
                  className="flex items-center justify-center w-[30px] min-w-full min-h-full"
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                >
                </div>
              )
            }} />
        }

        {columns.map((item) => {
          return (
            <Column
              className={classNames({ 'case-name': item.field === 'name' })}
              key={item.field}
              field={item.field}
              header={item.header}
              headerClassName={item.headerClassName}
              bodyClassName={item.bodyClassName}
              body={item.body}
            >
            </Column>
          )
        })}

        <Column
          rowEditor
          headerClassName="one-col-editor-header editor-header"
          bodyClassName="w-[150px] pr-0"
          bodyStyle={{ textAlign: 'center', paddingBottom: 24 }}
          pt={{
            root: {
              className: 'px-2 py-2 text-center'
            }
          }}
          body={(rowData, options) => {
            const rowName: string = rowData.name
            return (
              <div className="flex items-center justify-end w-[30px] min-w-full min-h-full space-x-1">
                <IconButton
                  severity="info"
                  icon={<EyeIcon className="fill-white dark:fill-light-blue h-4 w-4" />}
                  onClick={() => {
                    setShowCaseDetail(true)
                    setCaseToShow(rowData)
                  }}
                  title="View Case Details"
                />
                <IconButton
                  title='Edit'
                  severity="secondary"
                  onClick={() => {
                    onEditCase(submissionGroupName, rowName, options.rowIndex)
                  }}
                  icon={<EditIcon className="fill-white w-4 h-4" />}
                />
                <IconButton
                  icon={<DeleteIcon className="fill-white m-0.5 h-3 w-3" />}
                  severity="danger"
                  onClick={() => {
                    onDeleteCase(submissionGroupName, rowName, options.rowIndex)
                  }}
                  title="Remove Case"
                />
              </div>
            )
          }} />
      </DataTable>
      <Dialog
        header={`${caseToShow} Details`}
        visible={showCaseDetail}
        closeOnEscape
        pt={
          {
            root: {
              className: 'w-[60vw]'
            },
            mask: {
              className: 'bg-blue-backdrop dark:bg-neutral-80'
            },
            content: {
              className: 'max-h-[80vh] overflow-y-auto'
            }
          }
        }
        onHide={() => { if (!showCaseDetail) return; setShowCaseDetail(false); }}>
        <p>
          <pre className="whitespace-pre-wrap">
            {caseToShow && JSON.stringify(caseToShow, null, 4)}
          </pre>
        </p>
      </Dialog>
    </>
  )
}

export default NewCaseGroupTable
