import { RunStatus, WorkflowRunGroup } from "@aero-platform/shared"
import { DataTable, DataTableExpandedRows } from "primereact/datatable"
import { FC, useState } from "react"
import { CompactTableHeaderClassName } from "../../utils/constants"
import { CollapseIcon, DeleteIcon, EditIcon, ExpandIcon, EyeIcon } from "../Icons"
import { Column } from "primereact/column"
import StateRowExpansionTemplate, { getMatchingWorkflowRunGroup } from "./CaseRowExpansion"
import { classNames } from "primereact/utils"
import { runCaseNameBodyTemplates } from "./DataTableBodyTemplate"
import { Dialog } from "primereact/dialog"
import IconButton from "../buttons/IconButton"
import Modal from "../Modal"

type CaseGroupTableProps = {
  workflowSubmissionId: string
  submissionGroupName: string
  workflowRunGroups: WorkflowRunGroup[]
  isCommitting: boolean
  isDeletingCase: boolean
  isUpdatingCase: boolean
  onEditCase: (submissionGroupName: string, caseName: string, caseIndex: number) => void
  onDeleteCase: (submissionGroupName: string, caseName: string, caseIndex: number) => void
}


const CaseGroupTable: FC<CaseGroupTableProps> = ({
  workflowSubmissionId, workflowRunGroups, submissionGroupName, isCommitting, isDeletingCase, isUpdatingCase, onEditCase, onDeleteCase
}: CaseGroupTableProps) => {
  const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows>({})
  const [caseNameToShow, setCaseNameToShow] = useState<string>('')
  const [errorResultText, setErrorResultText] = useState<string>()
  const [showCaseDetail, setShowCaseDetail] = useState(false)
  const [showErrorResultModal, setShowErrorResultModal] = useState(false)

  let caseDetail = {}
  if (caseNameToShow) {
    const grp = workflowRunGroups.find((item) => item.name === caseNameToShow)
    if (grp) {
      caseDetail = {
        ...grp.runGroupCase
      }
    }
  }

  const columns = [
    {
      header: 'Name',
      field: 'name',
      headerClassName: CompactTableHeaderClassName,
      bodyClassName: 'id-cell',
      body: (rowData: WorkflowRunGroup) => {
        const rowName: string = rowData.name
        // get currentWkflSubmission's workflowRunGroups.Workflow[]'s
        const workflowGroup: WorkflowRunGroup | null = getMatchingWorkflowRunGroup(workflowRunGroups, rowName)
        return (
          <div className='flex items-center justify-between' id="case-name">
            <div
              className="flex items-center space-x-2 cursor-pointer"
              onClick={() => {
                // get current row id => if in expandRows, remove; else, append
                const copyExpandedRows: DataTableExpandedRows = { ...expandedRows }
                if (expandedRows[rowName]) {  // if rowName exists in expandedRows
                  // delete rowName from expandedRows
                  delete copyExpandedRows[rowName]
                } else { // if rowName doesnt exist, add rowName to expandedRows
                  copyExpandedRows[rowName] = true
                }
                setExpandedRows(copyExpandedRows)
              }}>
              {
                expandedRows[rowName] ?
                  <CollapseIcon className="fill-dark-blue dark:fill-light-blue h-6 w-6" /> :
                  <ExpandIcon className="fill-dark-blue dark:fill-light-blue h-6 w-6" />
              }
              {runCaseNameBodyTemplates(rowData.runGroupCase, workflowGroup?.overallStatus)}
            </div>
          </div>
        )
      },
    },
    {
      header: 'FW',
      bodyClassName: 'w-[75px]',
      field: 'runGroupCase.selectedWingState.fwState',
      body: (rowData: WorkflowRunGroup) => {
        console.log("rowData===>", rowData)
        const num = rowData.runGroupCase.selectedWingState.fwState
        return <span>{num && num.toFixed(1)}</span>
      }
    },
    {
      header: 'DRS(%)',
      bodyClassName: 'w-[75px]',
      field: 'runGroupCase.selectedWingState.drs',
    },
    {
      header: 'Attitude',
      field: 'runGroupCase.selectedAttitudeState',
      bodyClassName: 'w-[120px]',
      body: (rowData: WorkflowRunGroup) => {
        return (
          <div>{rowData.runGroupCase.selectedAttitudeState.name}</div>
        )
      },
    },
    {
      header: 'Domain',
      field: 'runGroupCase.selectedDomainState',
      bodyClassName: 'w-[120px]',
      body: (rowData: WorkflowRunGroup) => {
        return (
          <div>{rowData.runGroupCase.selectedDomainState.name}</div>
        )
      },
    },
    {
      header: 'Tyre Set',
      field: 'selectedTyreState',
      bodyClassName: 'w-[100px] min-w-[100px]',
      body: (rowData: WorkflowRunGroup) => {
        return (
          <div>{rowData.runGroupCase.selectedTyreState.name}</div>
        )
      },
    },
    {
      header: 'Sim Type',
      field: 'runGroupCase.selectedSimulationState',
      bodyClassName: 'w-[100px] min-w-[100px]',
      body: (rowData: WorkflowRunGroup) => {
        return (
          <div>{rowData.runGroupCase.selectedSimulationState?.name}</div>
        )
      },
    },
    {
      header: 'FRH',
      field: 'runGroupCase.selectedAttitudeState.frh',
      bodyClassName: 'w-[75px] max-w-[75px]'
    },
    {
      header: 'RRH',
      field: 'runGroupCase.selectedAttitudeState.rrh',
      bodyClassName: 'w-[75px] max-w-[75px]'
    },
    {
      header: 'Steer',
      field: 'runGroupCase.selectedAttitudeState.steer',
      bodyClassName: 'w-[75px] max-w-[75px]'
    },
    {
      header: 'Roll',
      field: 'runGroupCase.selectedAttitudeState.roll',
      bodyClassName: 'w-[75px] max-w-[75px]',
      body: (rowData: WorkflowRunGroup) => {
        const num = rowData.runGroupCase.selectedAttitudeState.roll
        return <span>{num && num.toFixed(1)}</span>
      }
    },
    {
      header: 'Yaw',
      field: 'runGroupCase.selectedAttitudeState.yaw',
      bodyClassName: 'w-[75px] max-w-[75px]'
    },
    {
      header: 'Rad',
      field: 'runGroupCase.selectedAttitudeState.radius',
      bodyClassName: 'w-[75px] max-w-[75px]'
    },
    {
      header: 'Spd',
      field: 'runGroupCase.selectedAttitudeState.speed',
      bodyClassName: 'w-[75px] max-w-[75px]'
    },
    {
      header: 'Exh',
      field: 'runGroupCase.selectedAttitudeState.exhaust',
      bodyClassName: 'w-[75px] max-w-[75px]'
    },
  ]

  return (
    <>
      <DataTable
        value={workflowRunGroups}
        expandedRows={expandedRows}
        rowExpansionTemplate={
          StateRowExpansionTemplate(workflowSubmissionId, workflowRunGroups, expandedRows, setExpandedRows)
        }
        dataKey="name"
        editMode="row"
        selectOnEdit={true}
        pt={{
          bodyRow: {
            className: 'table-row'
          }
        }}>
        {columns.map((item) => {
          return (
            <Column
              className={classNames({ 'case-name': item.field === 'name' })}
              key={item.field}
              field={item.field}
              header={item.header}
              headerClassName={item.headerClassName}
              bodyClassName={item.bodyClassName}
              body={item.body}
            >
            </Column>
          )
        })}

        <Column
          rowEditor
          headerClassName="one-col-editor-header editor-header"
          bodyClassName="w-[150px]"
          pt={{
            root: {
              className: 'px-2 py-2 text-center'
            }
          }}
          bodyStyle={{ textAlign: 'right', paddingBottom: 24 }}
          body={(rowData, options) => {
            const rowName: string = rowData.name
            const workflowRunGroup: WorkflowRunGroup | null = getMatchingWorkflowRunGroup(
              workflowRunGroups, rowName
            )
            return (
              <div className="flex items-center justify-center w-[30px] min-w-full min-h-full space-x-1">
                <IconButton
                  severity="info"
                  icon={<EyeIcon className="fill-white dark:fill-light-blue h-4 w-4" />}
                  onClick={() => {
                    setShowCaseDetail(true)
                    setCaseNameToShow(rowName)
                  }}
                  title="View Case Details"
                />
                {(workflowRunGroup && workflowRunGroup.overallStatus === RunStatus.CREATED) && (
                  <IconButton
                    title='Edit'
                    severity="secondary"
                    onClick={() => {
                      onEditCase(submissionGroupName, rowName, options.rowIndex)
                    }}
                    icon={<EditIcon className="fill-white w-4 h-4" />}
                  />)}
                {workflowRunGroup && workflowRunGroup.overallStatus === RunStatus.CREATED &&
                  (<IconButton
                    icon={<DeleteIcon className="fill-white m-0.5 h-3 w-3" />}
                    severity="danger"
                    onClick={() => {
                      onDeleteCase(submissionGroupName, rowName, options.rowIndex)
                    }}
                    title="Remove Case"
                  />)
                }
              </div>
            )
          }} />
      </DataTable>
      <Dialog
        header={`${caseNameToShow} Details`}
        visible={showCaseDetail}
        closeOnEscape
        pt={
          {
            root: {
              className: 'w-[60vw]'
            },
            mask: {
              className: 'bg-blue-backdrop dark:bg-neutral-80'
            },
            content: {
              className: 'max-h-[80vh] overflow-y-auto'
            }
          }
        }
        onHide={() => { if (!showCaseDetail) return; setShowCaseDetail(false); }}>
        <p>
          <pre className="whitespace-pre-wrap">
            {caseDetail && JSON.stringify(caseDetail, null, 4)}
          </pre>
        </p>
      </Dialog>
      {
        showErrorResultModal && (
          <Modal
            title="Rescale Job Error Log"
            isOpen={showErrorResultModal}
            withAction={false}
            showClose={true}
            easyClose={true}
            onClose={() => setShowErrorResultModal(false)}
            onCancel={() => setShowErrorResultModal(false)}>
            <pre
              className="whitespace-pre-wrap overflow-y-auto max-h-[70vh]"
              style={{ maxHeight: 'calc(100%-100px) !important' }}>
              {errorResultText}
            </pre>
          </Modal>
        )
      }

    </>
  )
}

export default CaseGroupTable
