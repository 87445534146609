import { Button } from 'primereact/button'
import { PropsWithChildren, ReactNode } from 'react'
import { LoadingIcon } from '../Icons'
import { classNames } from 'primereact/utils'

type IconButtonProps = {
  title?: string
  severity?: 'secondary' | 'success' | 'info' | 'warning' | 'danger' | 'help' | undefined
  disabled?: boolean
  loading?: boolean
  clear?: boolean
  link?: boolean
  icon: ReactNode
  onClick?: () => void
  dataPrAt?: string
}

const IconButton = ({ severity, icon, title, disabled, clear, loading, link, onClick, dataPrAt }: IconButtonProps & PropsWithChildren) => {
  return (
    <Button
      type="button"
      onClick={onClick}
      severity={severity}
      disabled={disabled}
      link={link}
      className={classNames("icon-button cursor-pointer", { '!bg-transparent': clear, '!cursor-not-allowed': disabled })}
      tooltip={title}
      tooltipOptions={{ position: 'bottom' }}
      data-pr-at={dataPrAt || 'center bottom'}
    >
      {loading && <LoadingIcon className="w-4 h-4 fill-white animate-spin" />}
      {!loading && icon}
    </Button >
  )
}

export default IconButton
