import { UseAxiosResult } from 'axios-hooks'
import { useAxiosClient } from './configs/axios-setup'

export interface IRequestError {
  status: number
  text: string
}

export const useGetMethodologySets = (): UseAxiosResult => {
  const [{ data, loading, error }, doFetch] = useAxiosClient(
    {
      url: '/MethodologySets',
      method: 'GET'
    },
    { manual: true, autoCancel: false, useCache: false }
  )

  const refetch = (query) => {
    return doFetch({
      url: '/MethodologySets' + (query ? `?${query}` : '')
    })
  }

  return [
    { data, loading, error },
    refetch,
    () => {
      return
    }
  ]
}

export const useGetMethodologySet = (): UseAxiosResult => {
  const [{ data, loading, error }, doFetch] = useAxiosClient(
    {
      method: 'GET'
    },
    { manual: true, autoCancel: false, useCache: false }
  )

  const refetch = (id) => {
    return doFetch({
      url: `/MethodologySets/${id}`,
    })
  }

  return [
    { data, loading, error },
    refetch,
    () => {
      return
    }
  ]
}

export const useCreateMethodologySet = (): UseAxiosResult => {
  const [{ data, loading, error }, doFetch] = useAxiosClient(
    {
      url: '/MethodologySets',
      method: 'POST'
    },
    { manual: true, autoCancel: false, useCache: false }
  )

  return [
    { data, loading, error },
    doFetch,
    () => {
      return
    }
  ]
}

export const useUpdateMethodologySet = (id: string): UseAxiosResult => {
  const [{ data, loading, error }, doFetch] = useAxiosClient(
    {
      url: `/MethodologySet/${id}`,
      method: 'PUT'
    },
    { manual: true, autoCancel: false, useCache: false }
  )

  return [
    { data, loading, error },
    doFetch,
    () => {
      return
    }
  ]
}
