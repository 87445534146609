import { Button } from 'primereact/button'
import { PropsWithChildren } from 'react'

type CloseButtonProps = {
  label?: string
  onClick: () => void
}

const CloseButton = ({ children, label, onClick }: CloseButtonProps & PropsWithChildren) => {
  return (
    <Button
      type="button"
      label={children ? undefined : label}
      pt={{
        root: {
          className: 'bg-gray-600 dark:!bg-white'
        },
        label: {
          className: 'dark:!text-black'
        }
      }}
      onClick={onClick}
      >
      {children}
    </Button>
  )
}

export default CloseButton
