export const DataTablePaginatorClass = 'flex min-w-[100%] justify-between !px-0'

export const TablePaginatorStyle = {
  root: {
    className: 'justify-end'
  },
  RPPDropdown: {
    root: {
      className: 'max-w-[90px] py-0'
    },
    input: {
      className: 'py-1.5'
    },
    list: {
      className: 'my-0 !py-0'
    },
    item: {
      className: 'h-8 py-2 h-auto'
    }
  },
  prevPageButton: {
    className: 'paginator-prev-page-button'
  }
}

const checkBoxSize = '!w-5 !h-5'
const checkBoxInputStyle = `${checkBoxSize} rounded-[4px] border-[1px]`

export const CheckboxStyle = {
}

export const MultiSelectCheckboxStyle = {
  headerCheckboxContainer: {
    className: checkBoxSize
  },
  headerCheckbox: {
    root: {
      className: 'rounded-none'
    },
    input: {
      className: checkBoxInputStyle
    },
    box: {
      className: checkBoxInputStyle
    }
  },
  checkboxContainer: {
    className: checkBoxSize
  },
  checkbox: {
    className: checkBoxInputStyle,
    box: {
      className: checkBoxInputStyle
    }
  }
}


export const DropdownStyle = {
  root: {
    className: 'min-w-full bg-transparent dark:border-neutral-70 dark:bg-transparent hover:border-gm-blue dark:hover:border-light-blue'
  },
  input: {
    className: 'py-2'
  },
  filterInput: {
    className: '!py-1 min-w-[fit] bg-transparent dark:bg-transparent dark:border-neutral-10'
  },
  header: {
    className: 'dark:bg-neutral-70 dark:border-neutral-70'
  },
  filterIcon: {
    className: 'dark:text-neutral-10'
  },
  wrapper: {
    className: 'dark:bg-neutral-70 dark:border-neutral-70'
  },
  item: {
    className: 'dark:hover:bg-gm-80 dark:focused:bg-neutral-80 focused:bg-neutral-80'
  },
}


export const TabMenuStyle = {
  action: {
    className: 'outline-none dark:bg-neutral-80',
  },
  menu: {
    className: '!bg-transparent dark:border-b-blue-trans',
  },
}
