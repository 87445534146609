import { UseAxiosResult } from 'axios-hooks'
import { useAxiosClient } from './configs/axios-setup'

export interface IRequestError {
  status: number
  text: string
}

export const useGetAttitudeSets = (): UseAxiosResult => {
  const [{ data, loading, error }, doFetch] = useAxiosClient(
    {
      url: '/AttitudeSets',
      method: 'GET'
    },
    { manual: true, autoCancel: false, useCache: false }
  )

  const refetch = (query) => {
    return doFetch({
      url: '/AttitudeSets' + (query ? `?${query}` : '')
    })
  }

  return [
    { data, loading, error },
    refetch,
    () => {
      return
    }
  ]
}

export const useGetAttitudeSet = (): UseAxiosResult => {
  const [{ data, loading, error }, doFetch] = useAxiosClient(
    {
      method: 'GET'
    },
    { manual: true, autoCancel: false, useCache: false }
  )

  const refetch = (id) => {
    return doFetch({
      url: `/AttitudeSets/${id}`
    })
  }

  return [
    { data, loading, error },
    refetch,
    () => {
      return
    }
  ]
}

export const useCreateAttitudeSet = (): UseAxiosResult => {
  const [{ data, loading, error }, doFetch] = useAxiosClient(
    {
      url: '/AttitudeSets',
      method: 'POST'
    },
    { manual: true, autoCancel: false, useCache: false }
  )

  return [
    { data, loading, error },
    doFetch,
    () => {
      return
    }
  ]
}

export const useUpdateAttitudeSet = (): UseAxiosResult => {
  const [{ data, loading, error }, doFetch] = useAxiosClient(
    {
      method: 'PUT'
    },
    { manual: true, autoCancel: false, useCache: false }
  )

  const refetch = ({ id, data }: any) => {
    return doFetch({
      url: `/AttitudeSets/${id}`,
      data
    })
  }

  return [
    { data, loading, error },
    refetch,
    () => {
      return
    }
  ]
}
