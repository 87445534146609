import routes from "../routing/routes";

export class TreeNode {
  private name: string = ""; // the unique name to repr a Node
  private value: string = "" // the value the node holds
  private children = new Map<string, TreeNode>(); // note: each children name is unique

  public constructor(name: string) {
    this.name = name;
  }

  public getName(): string {
    return this.name;
  }

  public getValue(): string {
    return this.value
  }

  public getChildren(): string[] {
    return Array.from(this.children.keys())
  }

  public setValue(value: string) {
    this.value = value;
  }

  public addChild(child: TreeNode): void {
    if (this.hasChild(child.getName())) {
      return;
    }
    this.children.set(child.getName(), child)
  }

  public hasChild(name: string): boolean {
    return this.children.has(name)
  }

  public getChild(name: string): TreeNode {
    const child: TreeNode | undefined = this.children.get(name)
    if (child) {
      return child
    } else {
      //return null
      throw new Error(`TreeNode ${this.name} DOES NOT have child with name ${name}`)
    }
  }

  public findNode(name: string): TreeNode | null {
    if (this.hasChild(name)) {
      return this.getChild(name)
    } else if (name === this.name) {
      return this
    }

    for (let childNode of this.children.values()) {
      const out: TreeNode | null = childNode.findNode(name)
      if (out != null) {
        return out
      }
    }

    return null
  }
  /*
  findParent(c)
  a
  |
  b
  | \
  c d
  */
  public findParent(name: string): TreeNode | null {
    if (this.hasChild(name)) {
      return this
    }

    for (let childNode of this.children.values()) {
      const out: TreeNode | null = childNode.findParent(name)
      if (out != null) {
        return out
      }
    }
    return null
  }

  public print(depth: number) {
    console.log("\t".repeat(depth), `${this.name}: ${this.value}`)
    this.children.forEach((node: TreeNode, name: string) => {
      node.print(depth + 1)
    });
  }

  public getTreePathName(nodeName: string): string[] {
    if (this.name === nodeName) {
      return [nodeName]
    }

    for (let childNode of this.children.values()) {

      const out: string[] = childNode.getTreePathName(nodeName)
      if (out.length > 0) {
        return [this.name].concat(out)
      }
    }
    return []
  }
}

export class Tree {
  private dummyRoot: TreeNode = new TreeNode("/")

  public createDescendantBranch(descendantNames: string[]) {
    // not adding children, but adding next "generations"
    //(ex: root -> child -> grandchild -> great grandchild)

    let traverseNode: TreeNode = this.dummyRoot
    for (let i = 0; i < descendantNames.length; i++) {  // +1 bc of dummy data
      const descendantName: string = descendantNames[i]

      // console.log(`node ${traverseNode.getName()} has child ${descendantName}?  ${traverseNode.hasChild(descendantName)}` )
      if (traverseNode.hasChild(descendantName)) {
        let childNode = traverseNode.getChild(descendantName)
        traverseNode = childNode
      } else {
        let childNode = new TreeNode(descendantNames[i])
        traverseNode.addChild(childNode)
        traverseNode = childNode
      }
    }
  }

  /**
   * Description placeholder
   *
   * @public
   * @param {string[]} descendantNames
   * @param {string[]} values
   */
  public addDescendantValues(descendantNames: string[], values: string[]) {
    // not adding children, but adding next "generations"
    //(ex: root -> child -> grandchild -> great grandchild)

    //makes sure all TreeNode names exists
    this.createDescendantBranch(descendantNames)

    //now set respective id keys to ordered descendants
    if (values != null && descendantNames.length !== values.length) {
      //Error!
      console.error("when adding children, provided names and idKey are respective to each index")
      return;
    }

    let traverseNode: TreeNode = this.dummyRoot
    for (let i = 0; i < descendantNames.length; i++) {  // +1 bc of dummy data
      const descendantName: string = descendantNames[i]
      const childNode: TreeNode = traverseNode.getChild(descendantName)

      const descendantIdKey: string = values[i]
      childNode.setValue(descendantIdKey)

      traverseNode = childNode
    }
  }

  public getDescendantValues(descendantNames: string[]): string[] {
    //makes sure all TreeNode names exists
    this.createDescendantBranch(descendantNames)

    let descendantValues: string[] = []
    let traverseNode: TreeNode = this.dummyRoot
    for (let i = 0; i < descendantNames.length; i++) {  // +1 bc of dummy data
      const descendantName: string = descendantNames[i]
      const childNode: TreeNode = traverseNode.getChild(descendantName)

      const value: string = childNode.getValue()
      descendantValues.push(value)

      traverseNode = childNode
    }
    return descendantValues
  }

  public findNode(name: string): TreeNode | null {
    return this.dummyRoot.findNode(name)
  }

  public findParent(name: string): TreeNode | null {
    return this.dummyRoot.findParent(name)
  }

  public getDescendantNamePath(nodeName: string): string[] {
    let out: string[] = this.dummyRoot.getTreePathName(nodeName)

    return out.slice(1)  // remove dummy node's "/"
  }

  public print() {
    this.dummyRoot.print(0)
  }
}

export const workflowSubmissionApiRoutes: string[] = [
  routes.Projects.absolutePath,
  routes.EditProject.absolutePath,
  routes.Submission.absolutePath
]
export const workflowSubmissionPathLinkageKeys: string[] = ["vehicleId", "projectId", "workflowSubmissionId"]
export const workflowSubmissionPathNameKeys: string[] = ["name", "name", "workflowSubmissionId"]
export const workflowSubmissionBreadcrumbTitles: string[] = ["Car", "Project", "Run"]

// values referenced from class ProtoService's deSerialize options
export const routeToProtoType = new Map<string, string>([
  [routes.Projects.absolutePath, "vehicle"],
  [routes.EditProject.absolutePath, "project"],
  [routes.Submission.absolutePath, "workflowsubmission"]
]);
