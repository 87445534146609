import { FC } from 'react'

const NotFound: FC = () => {
  return (
    <div className="basis-0 grow flex flex-col items-center justify-center w-full h-full">
      <h1 className="text-xl">The page has not been built, please come back later!</h1>
    </div>
  )
}

export default NotFound
