import { Button } from 'primereact/button'
import { FC } from 'react'
import Modal from './Modal'
import CloseButton from './buttons/CloseButton'

type ConfirmModalProps = {
  header?: string
  message: string
  confirmLabel?: string
  cancelLabel?: string
  onConfirm: () => void
  onCancel: () => void
}

const ConfirmModal: FC<ConfirmModalProps> = ({
  header,
  message,
  confirmLabel,
  cancelLabel,
  onConfirm,
  onCancel
}) => {

  const handleConfirm = () => {
    onConfirm && onConfirm()
  }

  const handleCancel = () => {
    onCancel && onCancel()
  }

  return (
    <Modal
      isOpen={true}
      withAction={false}
      easyClose={true}
      onCancel={() => onCancel && onCancel()}
      title={header || 'Confirmation'}
      showCancelButton={true}
      zIndex={30}
      onConfirm={handleConfirm}>
      <div className="flex flex-col items-stretch">
        <div className='py-4'>
          {message}
        </div>
        <div className="flex items-center justify-end space-x-6">
          <CloseButton label={cancelLabel || 'No'} onClick={handleCancel} />
          <Button label={confirmLabel || 'Yes'} onClick={handleConfirm} />
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmModal
