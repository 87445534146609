import { Button } from 'primereact/button'
import { FC, useEffect, useState } from 'react'
import {
  WorkflowSubmission
} from '../../types/types'
import Modal from '../Modal'
import CloseButton from '../buttons/CloseButton'
import { classNames } from 'primereact/utils'
import { Controller, useForm } from 'react-hook-form'
import { DropdownStyle } from '../../utils/styling-constants'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { InputNumber } from 'primereact/inputnumber'
import {
  AttitudeState,
  DomainState,
  RunConfigCase,
  SimulationState,
  TyreState
} from '@aero-platform/shared'
import { Dialog } from 'primereact/dialog'
import toast from 'react-hot-toast'

const prefix = '|'

const attitudeStateFields = [
  {
    name: 'frh',
    label: 'FRH',
    namePrefix: 'FR'
  },
  {
    name: 'rrh',
    label: 'RRH',
    namePrefix: 'RR'
  },
  {
    name: 'steer',
    label: 'Steer',
    namePrefix: 'S'
  },
  {
    name: 'roll',
    label: 'Roll',
    namePrefix: 'R'
  },
  {
    name: 'yaw',
    label: 'Yaw',
    namePrefix: 'Y'
  },
  {
    name: 'radius',
    label: 'Rad'
  },
  {
    name: 'speed',
    label: 'Spd'
  },
  {
    name: 'exhaust',
    label: 'Exh'
  }
]
const title = {
  'edit': 'Edit Case',
  'new': 'Add New Case'
}

type NamePortion = {
  frh: string
  rrh: string
  yaw: string
  steer: string
  roll: string
  domain: string
  simulation: string
  fw: string
  drs: string
}

type AddCaseModalProps = {
  mode?: string,
  caseName?: string,
  caseIndex?: number,
  submitting: boolean
  submission: WorkflowSubmission,
  onConfirm: (runConfigCase: RunConfigCase, mode: string) => void
  onCancel: () => void
}

const formatFW = (fwNum: number | null) => {
  if (fwNum === null) {
    return ''
  }
  let fw = fwNum.toFixed(1)
  fw = fw.replaceAll('.', 'p')

  return `${fw}FF`
}

const formatDRS = (drsNum: number | null) => {
  if (drsNum === null) {
    return ''
  }
  let drs = drsNum.toFixed(0)
  return `${drs}RF`
}

const AddCaseModal: FC<AddCaseModalProps> = ({
  mode,
  caseIndex,
  caseName,
  submitting,
  submission,
  onConfirm,
  onCancel
}) => {
  const [attitudeStates, setAttitudeStates] = useState<AttitudeState[]>(submission.runConfig.selectedAttitudeSet.attitudeStates || [])
  const [domainStates] = useState<DomainState[]>(submission.runConfig.selectedDomainSet.domainStates || [])
  const [tyreSetStates] = useState<TyreState[]>(submission.runConfig.selectedTyreSet.tyreStates || [])
  const [simulationStates] = useState<SimulationState[]>(submission.runConfig.selectedSimulationSet.simulationStates || [])
  const [baseAttitudeState, setBaseAttitudeState] = useState<AttitudeState>()
  const [nameSegment, setNameSegment] = useState<NamePortion>(
    { frh: '', rrh: '', yaw: '', steer: '', roll: '', fw: '', drs: '', domain: '', simulation: '' } as NamePortion
  )
  const [selectedAttitudeState, setSelectedAttitudeState] = useState<AttitudeState>({} as AttitudeState)
  const [showAttitudeStateDialog, setShowAttitudeStateDialog] = useState(false)
  const [stateName, setStateName] = useState('')
  const {
    control,
    setValue,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<RunConfigCase>()

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="error">{errors[name]?.message}</small>
    ) : (
      <small className="error">&nbsp;</small>
    )
  }

  const handleSave = async (formData) => {
    formData.selectedAttitudeState = { ...selectedAttitudeState }
    const existingCase = submission.runConfig.selectedCases.find(
      (caseItem, index) => {
        // found the same name
        if (caseItem.name === formData.name) {
          if (mode === 'new') {
            return true
          } else if (mode === 'edit' && caseItem.key !== formData.key) {
            return true
          }
        }
        // name is not the same
        return false
      }
    )
    if (existingCase) {
      setError('name', { type: 'manual', message: 'Case name already exists.' })
      return
    }
    onConfirm && onConfirm(formData, mode as string)
  }

  const handleConfirm = () => {
    onCancel && onCancel()
  }

  useEffect(() => {
    if (mode === 'edit') {
      const selectedCase = submission.runConfig.selectedCases.find((caseItem) => caseItem.name === caseName)
      if (selectedCase) {
        setValue('key', selectedCase.key)
        setValue('name', selectedCase.name)
        setValue('selectedWingState.fwState', selectedCase.selectedWingState.fwState)
        setValue('selectedWingState.drs', selectedCase.selectedWingState.drs)
        if (selectedCase.selectedDomainState) {
          const tmpDomainState = domainStates.find((domainState) => domainState.name === selectedCase.selectedDomainState.name)
          setValue('selectedDomainState', tmpDomainState as DomainState)
        }
        setValue('selectedTyreState', selectedCase.selectedTyreState)
        if (selectedCase.selectedTyreState) {
          const tmpTyreState = tyreSetStates.find((tyreState) => tyreState.name === selectedCase.selectedTyreState.name)
          setValue('selectedTyreState', tmpTyreState as TyreState)
        }
        setValue('selectedSimulationState', selectedCase.selectedSimulationState)
        if (selectedCase.selectedSimulationState) {
          const tmpSimulationState = simulationStates.find((simulationState) => simulationState.name === selectedCase.selectedSimulationState.name)
          setValue('selectedSimulationState', tmpSimulationState as SimulationState)
        }

        const fw = formatFW(selectedCase.selectedWingState.fwState)
        const drs = formatDRS(selectedCase.selectedWingState.drs)
        const domain = prefix + selectedCase.selectedDomainState.name
        const simulation = prefix + selectedCase.selectedSimulationState.name

        if (selectedCase.selectedAttitudeState) {
          console.log('selectedCase.selectedAttitudeState===>', selectedCase.selectedAttitudeState)
          const selectedCaseName = selectedCase.name
          if (selectedCaseName.includes(prefix)) {  // custom case name
            const [name, ...deltas] = selectedCaseName.split(prefix) // get base name
            const oldAttitudeState = attitudeStates.find((attitudeState) => attitudeState.name === name)
            setValue('selectedAttitudeState', oldAttitudeState as AttitudeState)
            setSelectedAttitudeState({
              name,
              frh: selectedCase.selectedAttitudeState.frh,
              rrh: selectedCase.selectedAttitudeState.rrh,
              yaw: selectedCase.selectedAttitudeState.yaw,
              steer: selectedCase.selectedAttitudeState.steer,
              roll: selectedCase.selectedAttitudeState.roll,
              radius: selectedCase.selectedAttitudeState.radius,
              speed: selectedCase.selectedAttitudeState.speed,
              exhaust: selectedCase.selectedAttitudeState.exhaust
            } as AttitudeState)
            let frh = '', rrh = '', yaw = '', steer = '', roll = ''
            deltas.forEach((delta, index) => {
              if (index >= deltas.length - 3) {
                // skip last two deltas
              } else if (delta.includes('FR') && !frh) {
                frh = prefix + delta
              } else if (delta.includes('RR') && !rrh) {
                rrh = prefix + delta
              } else if (delta.includes('Y') && !yaw) {
                yaw = prefix + delta
              } else if (delta.includes('S') && !steer) {
                steer = prefix + delta
              } else if (delta.includes('R') && !roll) {
                roll = prefix + delta
              }
            })

            setNameSegment({
              frh,
              rrh,
              yaw,
              steer,
              roll,
              fw,
              drs,
              domain,
              simulation
            })
            setBaseAttitudeState({ ...oldAttitudeState } as AttitudeState)
          } else {  // default case name
            const tmpAttitudeState = attitudeStates.find((attitudeState) => attitudeState.name === selectedCase.selectedAttitudeState.name)
            setSelectedAttitudeState(tmpAttitudeState as AttitudeState)
            setBaseAttitudeState({ ...tmpAttitudeState } as AttitudeState)
            setValue('selectedAttitudeState', selectedCase.selectedAttitudeState)
          }
        }
      }
    }
  }, [])

  if (baseAttitudeState) {
    let newName = `${nameSegment.frh}${nameSegment.rrh}${nameSegment.yaw}${nameSegment.steer}${nameSegment.roll}${nameSegment.domain}${nameSegment.simulation}`
    if (nameSegment.fw || nameSegment.drs) {
      newName = `${newName}${prefix}${nameSegment.fw}_${nameSegment.drs}`
    }
    setValue('name', baseAttitudeState.name + newName)
  } else {
    setValue('name', selectedAttitudeState.name)
  }

  return (
    <Modal
      isOpen={true}
      withAction={false}
      easyClose={true}
      onCancel={() => onCancel && onCancel()}
      title={title[mode || 'new']}
      showCancelButton={true}
      zIndex={30}
      onConfirm={handleConfirm}>
      <div className='relative'>
        <form
          onSubmit={handleSubmit(handleSave)}
          className="w-full grid grid-cols-3 gap-x-2 gap-y-4 mt-8">
          <div className="field-box col-span-1">
            <Controller
              name="selectedAttitudeState"
              rules={{ required: 'Attitude state is required.' }}
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <div className="flex justify-between">
                    <label htmlFor={field.name} className="mb-2">
                      Attitude State
                    </label>
                    <Button
                      type="button"
                      label="+ Create New State"
                      pt={{
                        root: {
                          className: 'pt-0 hidden'
                        }
                      }}
                      link
                      onClick={() => {
                        setShowAttitudeStateDialog(true)
                      }} />
                  </div>
                  <Dropdown
                    disabled={false}
                    value={field.value}
                    pt={DropdownStyle}
                    onChange={(e) => {
                      setSelectedAttitudeState(e.value)
                      setBaseAttitudeState({ ...e.value })
                      setNameSegment({ frh: '', rrh: '', yaw: '', steer: '', roll: '', fw: '', drs: '', domain: '', simulation: '' })
                      field.onChange(e.value)
                    }}
                    filter
                    filterBy="name"
                    className={classNames({ error: fieldState.error })}
                    options={attitudeStates}
                    optionValue="name"
                    optionLabel='name'
                    useOptionAsValue
                    placeholder="Select Starting State"
                    emptyMessage="&nbsp;&nbsp;No Data"
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>
          <div className="col-span-2 flex items-center justify-end space-x-2">
            {attitudeStateFields.map((field) => (
              <div className="field-box" key={field.name}>
                <label htmlFor={field.name} className="mb-2">
                  {field.label}
                </label>
                <InputNumber
                  id={field.name}
                  readOnly={submitting || !selectedAttitudeState}
                  mode="decimal"
                  maxFractionDigits={1}
                  inputClassName="w-full"
                  value={selectedAttitudeState[field.name as string]}
                  onChange={(e) => {
                    setSelectedAttitudeState({
                      ...selectedAttitudeState,
                      [field.name]: e.value || 0
                    } as AttitudeState)
                    const delta = (e.value || 0) - ((baseAttitudeState && baseAttitudeState[field.name]) || 0)
                    const newNameSegment = {
                      ...nameSegment,
                      [field.name]: delta !== 0 ? (prefix + delta.toFixed(0) + field.namePrefix) : ''
                    }
                    setNameSegment(newNameSegment)
                  }}
                />
                {getFormErrorMessage(field.name)}
              </div>
            ))}
          </div>

          <div className="field-box col-span-1">
            <Controller
              name="selectedDomainState"
              rules={{ required: 'Domain state is required.' }}
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className="mb-2">
                    Domain State
                  </label>
                  <Dropdown
                    disabled={false}
                    value={field.value}
                    pt={DropdownStyle}
                    onChange={(e) => {
                      field.onChange(e.value)
                      setNameSegment({
                        ...nameSegment,
                        domain: prefix + e.value.name
                      })
                    }}
                    className={classNames({ error: fieldState.error })}
                    options={domainStates}
                    optionValue="name"
                    optionLabel='name'
                    useOptionAsValue
                    placeholder="Select Starting State"
                    emptyMessage="&nbsp;&nbsp;No Data"
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>

          <div className="field-box col-span-1">
            <Controller
              name="selectedTyreState"
              rules={{ required: 'Tyre state is required.' }}
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className="mb-2">
                    Tyre State
                  </label>
                  <Dropdown
                    disabled={false}
                    value={field.value}
                    pt={DropdownStyle}
                    onChange={(e) => {
                      field.onChange(e.value)
                    }}
                    className={classNames({ error: fieldState.error })}
                    options={tyreSetStates}
                    optionValue="name"
                    optionLabel='name'
                    useOptionAsValue
                    placeholder="Select Starting State"
                    emptyMessage="&nbsp;&nbsp;No Data"
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>

          <div className="field-box col-span-1">
            <Controller
              name="selectedSimulationState"
              rules={{ required: 'Simulation state is required.' }}
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className="mb-2">
                    Simulation State
                  </label>
                  <Dropdown
                    disabled={false}
                    value={field.value}
                    pt={DropdownStyle}
                    onChange={(e) => {
                      field.onChange(e.value)
                      setNameSegment({
                        ...nameSegment,
                        simulation: prefix + e.value.name
                      })
                    }}
                    className={classNames({ error: fieldState.error })}
                    options={simulationStates}
                    optionValue="name"
                    optionLabel='name'
                    useOptionAsValue
                    placeholder="Select Starting State"
                    emptyMessage="&nbsp;&nbsp;No Data"
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>

          <div className="field-box">
            <Controller
              name="name"
              control={control}
              disabled={submitting}
              rules={{ required: 'Name is required.' }}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className="mb-2">
                    Name
                  </label>
                  <InputText
                    readOnly
                    id={field.name}
                    {...field}
                    className={classNames({ error: fieldState.error })}
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>

          <div className="field-box col-span-1">
            <Controller
              name="selectedWingState.fwState"
              control={control}
              disabled={submitting}
              rules={{ required: 'FW State is required.' }}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className="mb-2">
                    FW State
                  </label>
                  <InputNumber
                    id={field.name}
                    mode="decimal"
                    maxFractionDigits={1}
                    value={field.value}
                    inputClassName={classNames('w-full', { error: fieldState.error })}
                    onChange={(e) => {
                      field.onChange(e.value)
                      const fw = formatFW(e.value)
                      setNameSegment({
                        ...nameSegment,
                        fw
                      })
                    }}
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>

          <div className="field-box col-span-1">
            <Controller
              name="selectedWingState.drs"
              control={control}
              disabled={submitting}
              rules={{ required: 'DRS% is required.' }}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className="mb-2">
                    DRS%
                  </label>
                  <InputNumber
                    id={field.name}
                    mode="decimal"
                    maxFractionDigits={1}
                    value={field.value}
                    inputClassName={classNames('w-full', { error: fieldState.error })}
                    onChange={(e) => {
                      field.onChange(e.value)
                      const drs = formatDRS(e.value)
                      setNameSegment({
                        ...nameSegment,
                        drs
                      })
                    }}
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>


          <div className="w-full col-span-3 pt-8 flex items-center justify-end space-x-6">
            <Button
              type="submit"
              label="Save Case"
              loading={submitting}
            />
            <CloseButton label="Close" onClick={() => onCancel && onCancel()} />
          </div>
        </form>
      </div >
      <Dialog header="Create New Attitude State"
        visible={showAttitudeStateDialog}
        style={{ width: '500px' }}
        onHide={() => {
          if (!showAttitudeStateDialog) {
            return
          }
          setShowAttitudeStateDialog(false)
        }}
        footer={
          <div className="flex justify-end space-x-2">
            <Button
              label="Save"
              onClick={() => {
                if (stateName) {
                  if (attitudeStates.find((state) => state.name === stateName)) {
                    toast.error('Case name already exists.');
                    return
                  }
                  const newState = {
                    name: stateName,
                    frh: 0,
                    rrh: 0,
                    steer: 0,
                    roll: 0,
                    yaw: 0,
                    radius: 0,
                    speed: 0,
                    exhaust: 0
                  } as AttitudeState
                  setAttitudeStates([...attitudeStates, newState])
                  setValue('selectedAttitudeState', newState)
                  setSelectedAttitudeState(newState)
                  setBaseAttitudeState(undefined)
                  setShowAttitudeStateDialog(false)
                  return
                }
                toast.error('State name is required.');
              }} />
            <CloseButton label="Close" onClick={() => setShowAttitudeStateDialog(false)} />
          </div>
        }
      >
        <InputText
          placeholder="Enter state name"
          pt={{
            root: {
              className: 'w-full'
            }
          }}
          onChange={(e) => {
            setStateName(e.target.value)
          }} />
      </Dialog>
    </Modal >
  )
}

export default AddCaseModal
