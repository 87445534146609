import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { classNames } from 'primereact/utils'
import { FC, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useCreateGeometry, useUpdateGeometry } from '../../api/geometry-API'
import { ReactComponent as ArrowRightIcon } from '../../assets/svg/arrow-right.svg'
import { protoService } from '../../proto/ProtoService'
import { Geometry } from '../../types/types'
import { TeamId, carList } from '../../utils/constants'
import { currentUserState, geometryListState, projectListState } from '../../utils/state-atoms'
import { parseNewObjectId } from '../../utils/transform'
import Modal from '../Modal'
import CloseButton from '../buttons/CloseButton'

export const BUTTON_SAVE_AND_CREATE_SUBMISSION = 'save_and_submission'
export const BUTTON_SAVE = 'save'

type GeometryModalProps = {
  mode: string
  initGeometry: Geometry
  onConfirm: (geometry: Geometry) => void
  onCancel: () => void
}

const editorTitle = {
  new: 'New Geometry',
  edit: 'Edit Geometry'
}

let formAction = ''
const GeometryModal: FC<GeometryModalProps> = ({ mode, initGeometry, onConfirm, onCancel }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<Geometry>()
  const navigate = useNavigate()
  const [vehicle, setVehicle] = useState('')
  const [projectId, setProjectId] = useState('')
  const projectList = useRecoilValue(projectListState)
  const currentUser = useRecoilValue(currentUserState)
  const [geometries, setGeometries] = useRecoilState<Geometry[]>(geometryListState)

  const [{ data: createResData, loading: creating }, createGeometry] = useCreateGeometry()
  const [{ data: updateResData, loading: updating }, updateGeometry] = useUpdateGeometry(
    initGeometry._id
  )

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="error">{errors[name].message}</small>
    ) : (
      <small className="error">&nbsp;</small>
    )
  }

  const onSubmit: SubmitHandler<Geometry> = async (formData: Geometry) => {
    try {
      if (mode === 'new') {
        const newGeoData = {
          ...formData,
          raceTeamId: (currentUser && currentUser.role && currentUser.role.raceTeamId) || TeamId
        }
        console.log('newGeoData=', newGeoData)
        const dataToPost = protoService.encodePayload(newGeoData, 'geometry')
        const res = await createGeometry({ data: dataToPost })
        console.log(res, formAction)
        if (res.data.protoBytesList && res.data.protoBytesList.length > 0) {
          const newGeo: Geometry = protoService.deSerialize(res.data.protoBytesList[0], 'geometry')
          newGeo._id = parseNewObjectId(res.data.resultString)
          console.log('New Geometry=', newGeo)
          onConfirm && onConfirm(newGeo)
          setGeometries([...geometries, newGeo])
          if (formAction === BUTTON_SAVE_AND_CREATE_SUBMISSION) {
            navigate('/new-submission?from=geometry&geometryId=' + newGeo._id)
          }
        }
      } else if (mode === 'edit') {
        const newGeoData = new Geometry(initGeometry)
        newGeoData.name = formData.name
        newGeoData.description = formData.description
        newGeoData.cadFileName = formData.cadFileName

        console.log('newGeoData=', newGeoData)
        const dataToPost = protoService.encodePayload(newGeoData, 'geometry')
        const res = await updateGeometry({ data: dataToPost })
        console.log(res, formAction)
        if (res.data.protoBytesList && res.data.protoBytesList.length > 0) {
          const newGeo: Geometry = protoService.deSerialize(res.data.protoBytesList[0], 'geometry')
          console.log('Updated Geometry=', newGeo)
          onConfirm && onConfirm(newGeoData)
          setGeometries(
            geometries.map((item) => {
              return item._id === newGeoData._id ? newGeoData : item
            })
          )
          if (formAction === BUTTON_SAVE_AND_CREATE_SUBMISSION) {
            navigate('/new-submission?from=geometry&geometryId=' + newGeoData._id)
          }
        }
      }

      toast.success('Geometry saved.')
    } catch (err) {
      toast.error('Error saving geometry.')
    }
  }

  const handleConfirm = () => {
    onCancel && onCancel()
  }

  return (
    <Modal
      isOpen={true}
      withAction={false}
      easyClose={true}
      onCancel={() => onCancel && onCancel()}
      title={
        editorTitle[mode] +
        (mode === 'edit' ? `${initGeometry.geoId ? ' G' + initGeometry.geoId : ''}` : '')
      }
      showCancelButton={true}
      zIndex={30}
      onConfirm={handleConfirm}>
      <div className="relative">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full grid grid-cols-3 gap-x-10 gap-y-4 mt-8">
          <div className="col-span-3 font-bold items-center space-x-2 hidden">
            <div className="page-title">Car</div>
            <Dropdown
              value={vehicle}
              pt={{
                root: {
                  className: 'basis-0 w-[100px]'
                },
                input: {
                  className: 'py-1'
                }
              }}
              useOptionAsValue
              onChange={(e) => setVehicle(e.value)}
              options={carList}
            />
            <ArrowRightIcon className="w-3 h-3 fill-black dark:fill-white dark:stroke-white" />
            <div className="page-title ">Project</div>
            <Dropdown
              value={projectId}
              pt={{
                root: {
                  className: 'basis-0 w-[100px]'
                },
                input: {
                  className: 'py-1'
                }
              }}
              onChange={(e) => setProjectId(e.value)}
              options={projectList}
              optionLabel="name"
              optionValue="projectid"
              useOptionAsValue
            />
          </div>
          <div className="flex flex-col items-stretch col-span-1">
            <Controller
              name="name"
              control={control}
              rules={{ required: 'Geometry name is required.' }}
              defaultValue={initGeometry.name}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className="mb-2">
                    Geometry Name
                  </label>
                  <InputText
                    id={field.name}
                    value={field.value}
                    className={classNames({ error: fieldState.error })}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>
          <div className="flex flex-col items-stretch col-span-2">
            <Controller
              name="description"
              control={control}
              defaultValue={initGeometry.description}
              rules={{ required: 'Description is required.' }}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className="mb-2">
                    Geometry Description
                  </label>
                  <InputText
                    id={field.name}
                    {...field}
                    className={classNames({ error: fieldState.error })}
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>
          <div className="flex flex-col items-stretch col-span-3">
            <Controller
              name="cadFileName"
              control={control}
              rules={{ required: 'Geometry CAD file is required.' }}
              defaultValue={initGeometry.cadFileName}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className="mb-2">
                    Geometry CAD File
                  </label>
                  <InputText
                    id={field.name}
                    value={field.value}
                    className={classNames({ error: fieldState.error })}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>
          <div className="w-full col-span-3 py-8 flex items-center justify-end space-x-4">
            <Button
              type="submit"
              label="Save and Create New Run"
              name={BUTTON_SAVE_AND_CREATE_SUBMISSION}
              loading={creating || updating}
              onClick={() => {
                formAction = BUTTON_SAVE_AND_CREATE_SUBMISSION
              }}
            />
            <Button
              type="submit"
              label="Save"
              name={BUTTON_SAVE}
              loading={creating || updating}
              onClick={() => {
                formAction = BUTTON_SAVE
              }}
            />
            <CloseButton label="Close" onClick={() => onCancel && onCancel()} />
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default GeometryModal
