import { RefetchFunction } from "axios-hooks";
import { Dispatch, SetStateAction } from "react";

export class DependentFetch{

    private routeToIsFetched: Map<string, Boolean> | undefined;
    private routeToSetisFetched: Map<string, Dispatch<SetStateAction<Boolean>>> | undefined;
    private routeToQueryFetch: Map<string, RefetchFunction<any, any>> | undefined;
    private routeToIDFetch: Map<string, RefetchFunction<any, any>> | undefined;

    constructor(routeToIsFetched: Map<string, Boolean>, 
        routeToSetisFetched: Map<string, Dispatch<SetStateAction<Boolean>>>,
        routeToQueryFetch: Map<string, RefetchFunction<any, any>>,
        routeToIDFetch: Map<string, RefetchFunction<any, any>>
    ){
        // error check if all maps have same key sets
        if (routeToIsFetched.size !== routeToSetisFetched.size || routeToIsFetched.size !== routeToQueryFetch.size){
            throw new Error("Maps need to have same SIZE!")
        }
        for (let key in routeToIsFetched.keys()) {
            if (!routeToSetisFetched.has(key) || !routeToQueryFetch.has(key)) {
                throw new Error("Maps need to have same KEYs!")
            }
        }

        this.routeToIsFetched = routeToIsFetched
        this.routeToSetisFetched = routeToSetisFetched
        this.routeToQueryFetch = routeToQueryFetch
        this.routeToIDFetch = routeToIDFetch
    }

    public async fetchDataUsingQuery(route: string, query: string): Promise<void>{
        //query ex: /Vehicles?query={"name":"C2024"}
        // has to run in useEffect or awaitFetch and setIsFetched won't both run
        const isFetched: Boolean | undefined = this.routeToIsFetched?.get(route)
        if (!isFetched){
            const fetchFunc: RefetchFunction<any, any> | undefined = this.routeToQueryFetch?.get(route)
            
            // updates the "data" variable where the fetch() came from
            fetchFunc && await fetchFunc(query)
            
            const setIsFetched: Dispatch<SetStateAction<Boolean>> | undefined = this.routeToSetisFetched?.get(route)
            setIsFetched && setIsFetched(true)

            
            console.log("Got", this.routeToIsFetched?.get(route))
        }else{
            console.log("Not Got", this.routeToIsFetched?.get(route))
        }
    }

    public async fetchDataUsingID(route: string, id: string): Promise<void>{
        //query ex: /Vehicles?query={"name":"C2024"}
        // has to run in useEffect or awaitFetch and setIsFetched won't both run
        const isFetched: Boolean | undefined = this.routeToIsFetched?.get(route)
        if (!isFetched){
            const fetchFunc: RefetchFunction<any, any> | undefined = this.routeToIDFetch?.get(route)
            
            // updates the "data" variable where the fetch() came from
            fetchFunc && await fetchFunc(id)
            
            const setIsFetched: Dispatch<SetStateAction<Boolean>> | undefined = this.routeToSetisFetched?.get(route)
            setIsFetched && setIsFetched(true)

            
            console.log("Got", this.routeToIsFetched?.get(route))
        }else{
            console.log("Not Got", this.routeToIsFetched?.get(route))
        }
    }
}