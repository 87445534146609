import "../styles/index.css"
import { Link } from "react-router-dom"
import { Fragment } from "react/jsx-runtime"

export interface Crumb {
  title: string,
  name: string
  url: string
}

const CrumbComponent = (crumb: Crumb) => {

  return (
    <div className="flex flex-col">
      <div className="font-overpass text-neutral-90 dark:text-neutral-10 font-semibold text-xs h-3">
        {crumb.title}
      </div>
      <Link to={crumb.url} className="font-overpass text-gm-blue dark:text-light-blue font-bold text-2xl h-8">
        {crumb.name}
      </Link>
    </div>
  )
}

const Linkage = () => {
  //creates pointing-right triangle icon
  return (
    <div className="px-5">
      <svg width="12" height="13" viewBox="0 0 12 13" className="fill-neutral-90 dark:fill-neutral-10">
        <path d="M12 6.5L0.749999 12.9952L0.75 0.00480889L12 6.5Z" />
      </svg>
    </div>
  )
}

interface AncestorBreadcrumbsProps {
  ancestorBreadcrumbs?: Crumb[]
}

const Breadcrumbs = ({ ancestorBreadcrumbs = [] }: AncestorBreadcrumbsProps) => {

  const lastIndex: number = ancestorBreadcrumbs.length - 1
  return (
    <div className="basis-0 grow font-bold flex items-center space-x-2 ">
      {ancestorBreadcrumbs.map((crumb: Crumb, index: number) => (
        <Fragment key={index}>
          <CrumbComponent {...crumb} />
          {(index < lastIndex) && <Linkage />}
        </Fragment>
      ))}
    </div>
  )
}

export default Breadcrumbs
