import { Button } from 'primereact/button'
import { FC, useEffect, useState } from 'react'
import Modal from './Modal'
import CloseButton from './buttons/CloseButton'
import { classNames } from 'primereact/utils'
import { Controller, useForm } from 'react-hook-form'
import { InputText } from 'primereact/inputtext'
import { InputNumber } from 'primereact/inputnumber'
import {
  DomainState
} from '@aero-platform/shared'
import { LoadingIcon, SaveIcon } from './Icons'

const title = {
  'edit': 'Edit State',
  'new': 'Add New State'
}

type DomainStateEditorModalProps = {
  mode: string,
  states: DomainState[],
  stateIndex: number,
  saving: boolean,
  onConfirm: (domainState: DomainState, mode: string) => void
  onCancel: () => void
}

const DomainStateEditorModal: FC<DomainStateEditorModalProps> = ({
  mode,
  states,
  saving,
  stateIndex,
  onConfirm,
  onCancel
}) => {
  const {
    control,
    setValue,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<DomainState>()
  const [selectedDomainState] = useState<DomainState>(
    mode === 'new' ? ({} as DomainState) : { ...states[stateIndex] } as DomainState)

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="error">{errors[name]?.message}</small>
    ) : (
      <small className="error">&nbsp;</small>
    )
  }

  const handleSave = async (formData) => {
    console.log('formData', formData)
    onConfirm && onConfirm(formData, mode as string)
  }

  const handleConfirm = () => {
    onCancel && onCancel()
  }

  useEffect(() => {
    if (mode === 'edit') {
      setValue('name', selectedDomainState.name)
      setValue('description', selectedDomainState.description)
      if (selectedDomainState.MMSLocation) {
        setValue('MMSLocation.0', selectedDomainState.MMSLocation[0])
        setValue('MMSLocation.1', selectedDomainState.MMSLocation[1])
        setValue('MMSLocation.2', selectedDomainState.MMSLocation[2])
      }
      if (selectedDomainState.fileRef) {
        setValue('fileRef.name', selectedDomainState.fileRef.name)
        setValue('fileRef.path', selectedDomainState.fileRef.path)
        setValue('fileRef.version', selectedDomainState.fileRef.version)
      }
    }
  }, [])

  return (
    <Modal
      isOpen={true}
      withAction={false}
      easyClose={true}
      onCancel={() => onCancel && onCancel()}
      title={title[mode || 'new']}
      showCancelButton={true}
      zIndex={30}
      onConfirm={handleConfirm}>
      <div className='relative'>
        <form
          onSubmit={handleSubmit(handleSave)}
          className="w-full grid grid-cols-3 gap-x-2 gap-y-4 mt-8">
          <div className="field-box col-span-1">
            <Controller
              name="name"
              control={control}
              disabled
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className="mb-2">
                    Name (read only)
                  </label>
                  <InputText
                    id={field.name}
                    {...field}

                    className={classNames({ error: fieldState.error })}
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>

          <div className="field-box col-span-2">
            <Controller
              name="description"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className="mb-2">
                    Description
                  </label>
                  <InputText
                    id={field.name}
                    {...field}
                    className={classNames({ error: fieldState.error })}
                  />
                </>
              )}
            />
          </div>

          <div className="field-box">
            <Controller
              // @ts-ignore
              name="MMSLocation.0"
              control={control}
              rules={{ required: `MMSLocation X is required.` }}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name}>
                    MMSLocation X
                  </label>
                  <InputNumber
                    id={field.name}
                    mode="decimal"
                    maxFractionDigits={1}
                    tabIndex={1}
                    // @ts-ignore
                    value={field.value}
                    inputClassName={classNames('w-full', { error: fieldState.error })}
                    onChange={(e) => field.onChange(e.value)}
                  />
                </>
              )}
            />
            {getFormErrorMessage('MMSLocation.0')}
          </div>

          <div className="field-box">
            <Controller
              // @ts-ignore
              name="MMSLocation.1"
              control={control}
              rules={{ required: `MMSLocation Y is required.` }}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name}>
                    MMSLocation Y
                  </label>
                  <InputNumber
                    id={field.name}
                    mode="decimal"
                    maxFractionDigits={1}
                    tabIndex={1}
                    // @ts-ignore
                    value={field.value}
                    inputClassName={classNames('w-full', { error: fieldState.error })}
                    onChange={(e) => field.onChange(e.value)}
                  />
                </>
              )}
            />
            {getFormErrorMessage('MMSLocation.1')}
          </div>

          <div className="field-box">
            <Controller
              // @ts-ignore
              name="MMSLocation.2"
              control={control}
              rules={{ required: `MMSLocation Z is required.` }}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name}>
                    MMSLocation Z
                  </label>
                  <InputNumber
                    id={field.name}
                    mode="decimal"
                    maxFractionDigits={1}
                    tabIndex={1}
                    // @ts-ignore
                    value={field.value}
                    inputClassName={classNames('w-full', { error: fieldState.error })}
                    onChange={(e) => field.onChange(e.value)}
                  />
                </>
              )}
            />
            {getFormErrorMessage('MMSLocation.2')}
          </div>


          <div className="field-box col-span-1">
            <Controller
              name="fileRef.name"
              control={control}
              disabled
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className="mb-2">
                    File Name (read only)
                  </label>
                  <InputText
                    id={field.name}
                    {...field}

                    className={classNames({ error: fieldState.error })}
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>

          <div className="field-box col-span-1">
            <Controller
              name="fileRef.version"
              control={control}
              disabled
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className="mb-2">
                    File Version (read only)
                  </label>
                  <InputText
                    id={field.name}
                    {...field}

                    className={classNames({ error: fieldState.error })}
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>

          <div className="field-box col-span-3">
            <Controller
              name="fileRef.path"
              control={control}
              disabled
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className="mb-2">
                    File path (read only)
                  </label>
                  <InputText
                    id={field.name}
                    {...field}
                    className={classNames({ error: fieldState.error })}
                  />
                </>
              )}
            />
          </div>

          <div className="w-full col-span-3 pt-6 flex items-center justify-end space-x-6">
            <Button
              type="submit">
              {saving && <LoadingIcon className="fill-white w-4 h-4" />}
              {!saving && <SaveIcon className="fill-white w-4 h-4" />}
              <span className="ml-1">Save</span>
            </Button>
            <CloseButton label="Close" onClick={() => onCancel && onCancel()} />
          </div>
        </form>
      </div >
    </Modal >
  )
}

export default DomainStateEditorModal
