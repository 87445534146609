class AppEvent extends EventTarget {
  constructor() {
    super()
  }

  error401() {
    this.dispatchEvent(new Event('error401'))
  }

  errorNetwork() {
    this.dispatchEvent(new Event('errorNetwork'))
  }

  userLoggedIn() {
    this.dispatchEvent(new Event('userLoggedIn'))
  }

  teamChanged() {
    this.dispatchEvent(new Event('teamChanged'))
  }
}

export const appEventInstance = new AppEvent()
