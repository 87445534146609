import { FilterMatchMode } from 'primereact/api'
import { Column, ColumnEditorOptions } from 'primereact/column'
import {
  DataTable,
  DataTableFilterMeta,
  DataTablePageEvent,
  DataTableRowEditCompleteEvent,
  DataTableRowEditEvent,
  DataTableStateEvent
} from 'primereact/datatable'
import { FC, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router'
import { useRecoilState } from 'recoil'
import { useGetGeometries } from '../api/geometry-API'
import { ReactComponent as EditIcon } from '../assets/svg/pencil.svg'
import GeometryModal from '../components/project/GeometryModal'
import { InlineTextEditor } from '../components/table/InlineEditor'
import { Geometry, LazyTableState } from '../types/types'
import { DefaultPageSize, DummyGeometry } from '../utils/constants'
import { geometryListState } from '../utils/state-atoms'

import 'primeicons/primeicons.css'
import { Button } from 'primereact/button'
import 'primereact/resources/primereact.css'
import { protoService } from '../proto/ProtoService'
import { queryString2JsonString } from '../types/util'
import { formatDateNumber } from '../utils/intl'
import { TablePaginatorStyle } from '../utils/styling-constants'

const defaultFilters: DataTableFilterMeta = {
  name: { value: null, matchMode: FilterMatchMode.CONTAINS },
  geoId: { value: null, matchMode: FilterMatchMode.EQUALS }
}

const columns = [
  {
    header: 'Name',
    field: 'name',
    filter: true,
    filterPlaceholder: 'Search by name',
    headerStyle: {},
    headerClassName: 'py-4 px-4',
    bodyStyle: {},
    bodyClassName: '!p-1 w-[20%]',
    ptHeaderCell: undefined,
    ptBodyCell: undefined
  },
  {
    header: 'ID',
    field: 'geoId',
    filter: true,
    filterPlaceholder: 'Search by ID',
    headerStyle: {},
    headerClassName: 'py-4 px-1',
    bodyStyle: {},
    bodyClassName: '!p-1 w-[20%] max-w-[150px]',
    ptHeaderCell: undefined,
    ptBodyCell: undefined
  },
  {
    header: 'Description',
    field: 'description',
    editor: (options: ColumnEditorOptions) => InlineTextEditor(options, '100%'),
    editorWidth: 10,
    headerStyle: {},
    headerClassName: 'py-4 px-1',
    bodyStyle: {},
    bodyClassName: '!p-1',
    ptHeaderCell: undefined,
    ptBodyCell: undefined
  },
  {
    header: 'Date Created',
    field: 'timecreated',
    editorWidth: 10,
    headerStyle: {},
    headerClassName: 'py-2 px-1 w-[120px]',
    bodyStyle: {},
    bodyClassName: '!p-1',
    ptHeaderCell: undefined,
    ptBodyCell: undefined,
    body: (rowData: Geometry) => {
      return formatDateNumber(rowData.timeCreated)
    }
  }
]

const Geometries: FC = () => {
  const [showEditorModal, setShowEditorModal] = useState(false)
  const [mode, setMode] = useState('')
  const [initGeometry, setInitGeometry] = useState(DummyGeometry)
  const [{ loading, data: geometryData }, fetchGeometries] = useGetGeometries()
  const [geometries, setGeometries] = useRecoilState<Geometry[]>(geometryListState)
  const [totalRecords, setTotalRecords] = useState<number>(10)
  const [lazyState, setlazyState] = useState<LazyTableState>({
    first: 0,
    rows: DefaultPageSize,
    page: 1,
    sortField: 'name',
    sortOrder: -1,
    filters: defaultFilters,
    sumlvl: 'vehicleStatesList:0,geometryList:0,tyreSetList:0,cfdSequenceList:0,method:0'
  })

  useEffect(() => {
    const loadData = async () => {
      try {
        await fetchGeometries()
      } catch (err) {
        console.log(err)
        toast.error('Error loading data')
      }
    }
    loadData()
  }, [])

  useEffect(() => {
    console.log(geometryData)
    geometryData && setGeometries(geometryData)

    const geoList =
      geometryData &&
      geometryData.protoBytesList.map((item: string) => {
        const result = protoService.deSerialize(item, 'geometry')
        return result
      })
    geoList && setGeometries(geoList)
  }, [geometryData])

  const navigate = useNavigate()

  const handleCreateNewGeometry = () => {
    setMode('new')
    setInitGeometry(DummyGeometry)
    setShowEditorModal(true)
  }

  const onRowEditComplete = (evt: DataTableRowEditCompleteEvent) => {
    console.log(evt)
    const { newData, index } = evt
    const vs = geometries.map((item, idx) => {
      return idx === index ? newData : item
    })

    setGeometries(vs as Geometry[])
  }

  const handleEditGeometry = (geometry: Geometry) => {
    setInitGeometry(geometry)
    setMode('edit')
    setShowEditorModal(true)
  }

  const handleDataPage = (event: DataTablePageEvent) => {
    console.log(event)
    setlazyState({
      ...lazyState,
      first: event.first,
      page: event.page as number,
      rows: event.rows
    })
  }

  const handelFilterChange = (evt: DataTableStateEvent) => {
    console.log(evt)
    setlazyState({
      ...lazyState,
      first: 0,
      page: 0,
      filters: evt.filters
    })
  }

  const makeQuery = (state) => {
    let query
    console.log(state)

    if (state.filters.name['value']) {
      const nameSearch = { $regex: state.filters.name['value'], $options: 'i' }
      const q = 'name=' + encodeURIComponent(JSON.stringify(nameSearch))
      query = query ? `${query}&${q}` : q
    }

    if (state.filters.geoId['value']) {
      const q = 'geoId=' + state.filters.geoId['value']
      query = query ? `${query}&${q}` : q
    }

    query = queryString2JsonString(query)
    query = query ? `query=${query}&limit=${state.rows}` : `limit=${state.rows}`
    query = `${query}&skip=${state.first}&countTotal=true`
    if (state.sortField) {
      const sort = {
        [state.sortField]: state.sortOrder
      }
      query = `${query}&sort=${JSON.stringify(sort)}`
    }

    return query
  }

  useEffect(() => {
    const query = makeQuery(lazyState)
    fetchGeometries(query).catch((err) => {
      console.error(err)
      toast.error('Error loading geometries.')
    })
  }, [lazyState])

  const actionColumnTemplate = (rowData: Geometry) => {
    return (
      <div>
        <Button severity="secondary" onClick={() => handleEditGeometry(rowData)}>
          <EditIcon className="h-3 w-3 fill-white stroke-transparent" />
          <span className="ml-1">View/Edit</span>
        </Button>
      </div>
    )
  }

  return (
    <div className="basis-0 grow flex flex-col items-stretch mt-6 min-w-full">
      <div className="flex items-center justify-between pb-4">
        <h1 className="page-title">Geometries</h1>
        <Button onClick={handleCreateNewGeometry} label="+ New Geometry" />
      </div>
      {geometries && (
        <DataTable
          value={geometries}
          first={lazyState.first}
          totalRecords={totalRecords}
          loading={loading}
          dataKey="_id"
          stripedRows
          paginator
          filters={defaultFilters}
          onFilter={handelFilterChange}
          lazy
          filterDisplay="row"
          rows={lazyState.rows}
          onPage={handleDataPage}
          rowsPerPageOptions={[10, 20, 50]}
          pt={{
            bodyRow: {
              className:
                '!py-0 bg-white even:bg-alt-gray dark:even:bg-gray-950 hover:bg-gray-300 dark:hover:bg-gray-800'
            },
            paginator: TablePaginatorStyle
          }}
          onRowEditInit={(evt: DataTableRowEditEvent) => {
            console.log(evt)
            evt.originalEvent.stopPropagation()
            //setShowEditorModal(true)
          }}
          onRowEditComplete={onRowEditComplete}>
          {columns.map((item) => {
            return (
              <Column
                key={item.field}
                field={item.field}
                header={item.header}
                filter={item.filter}
                filterPlaceholder={item.filterPlaceholder}
                filterHeaderStyle={{ padding: '8px 0px' }}
                editor={item.editor}
                body={item.body}
                headerStyle={item.headerStyle}
                headerClassName={item.headerClassName}
                bodyStyle={item.bodyStyle}
                pt={{
                  filterMenuButton: {
                    className: 'hidden'
                  },
                  headerFilterClearButton: {
                    className: 'hidden'
                  },
                  filterInput: {
                    className: '!py-0 !my-0 !mx-0',
                    input: {
                      classNames: 'max-w-[80px]'
                    }
                  }
                }}
                bodyClassName={item.bodyClassName}></Column>
            )
          })}
          <Column
            body={actionColumnTemplate}
            headerStyle={{ width: '80px' }}
            bodyStyle={{ textAlign: 'center', padding: '12px 8px' }}></Column>
        </DataTable>
      )}

      <div className="flex items-center space-x-4 justify-end my-4"></div>

      {showEditorModal && (
        <GeometryModal
          initGeometry={initGeometry}
          mode={mode}
          onCancel={() => setShowEditorModal(false)}
          onConfirm={(geometry: Geometry) => {
            setShowEditorModal(false)
          }}
        />
      )}
    </div>
  )
}

export default Geometries
