import { Button } from 'primereact/button'
import { FC, useEffect, useState } from 'react'
import Modal from './Modal'
import CloseButton from './buttons/CloseButton'
import { classNames } from 'primereact/utils'
import { Controller, useForm } from 'react-hook-form'
import { InputText } from 'primereact/inputtext'
import { InputNumber } from 'primereact/inputnumber'
import {
  TyreState,
  RunConfigCase
} from '@aero-platform/shared'
import SaveButton from './buttons/SaveButton'
import { LoadingIcon, SaveIcon } from './Icons'

const title = {
  'edit': 'Edit State',
  'new': 'Add New State'
}

type TyreStateEditorModalProps = {
  mode: string,
  states: TyreState[],
  stateIndex: number,
  saving: boolean,
  onConfirm: (tyreState: TyreState, mode: string) => void
  onCancel: () => void
}

const TyreStateEditorModal: FC<TyreStateEditorModalProps> = ({
  mode,
  states,
  saving,
  stateIndex,
  onConfirm,
  onCancel
}) => {
  const {
    control,
    setValue,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<TyreState>()
  const [selectedTyreState] = useState<TyreState>(
    mode === 'new' ? ({} as TyreState) : { ...states[stateIndex] } as TyreState)

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="error">{errors[name]?.message}</small>
    ) : (
      <small className="error">&nbsp;</small>
    )
  }

  const handleSave = async (formData) => {
    console.log('formData', formData)
    onConfirm && onConfirm(formData, mode as string)
  }

  const handleConfirm = () => {
    onCancel && onCancel()
  }

  useEffect(() => {
    if (mode === 'edit') {
      setValue('name', selectedTyreState.name)
      setValue('description', selectedTyreState.description)
      setValue('cadDir', selectedTyreState.cadDir)
      setValue('loadedRadius.LS_FRONT', selectedTyreState.loadedRadius.LS_FRONT)
      setValue('loadedRadius.RS_FRONT', selectedTyreState.loadedRadius.RS_FRONT)
      setValue('loadedRadius.LS_REAR', selectedTyreState.loadedRadius.LS_REAR)
      setValue('loadedRadius.RS_REAR', selectedTyreState.loadedRadius.RS_REAR)
      setValue('stateTransform.FRH', selectedTyreState.stateTransform.FRH)
      setValue('stateTransform.RRH', selectedTyreState.stateTransform.RRH)
      setValue('stateTransform.ROLL', selectedTyreState.stateTransform.ROLL)
      setValue('stateTransform.STEER', selectedTyreState.stateTransform.STEER)
    }
  }, [])

  return (
    <Modal
      isOpen={true}
      withAction={false}
      easyClose={true}
      onCancel={() => onCancel && onCancel()}
      title={title[mode || 'new']}
      showCancelButton={true}
      zIndex={30}
      onConfirm={handleConfirm}>
      <div className='relative'>
        <form
          onSubmit={handleSubmit(handleSave)}
          className="w-full grid grid-cols-9 gap-x-2 gap-y-4 mt-8">
          <div className="field-box col-span-4">
            <Controller
              name="name"
              control={control}
              disabled
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className="mb-2">
                    Name (read only)
                  </label>
                  <InputText
                    id={field.name}
                    {...field}

                    className={classNames({ error: fieldState.error })}
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>

          <div className="col-span-1"></div>

          <div className="field-box col-span-4">
            <Controller
              name="description"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className="mb-2">
                    Description
                  </label>
                  <InputText
                    id={field.name}
                    {...field}
                    className={classNames({ error: fieldState.error })}
                  />
                </>
              )}
            />
          </div>

          <div className="col-span-9 grid grid-cols-9 gap-x-2">
            <div className="col-span-4 font-semibold">Loaded Radius</div>
            <div className="col-span-1"></div>
            <div className="col-span-4 font-semibold">State Transform</div>

            <div className="field-box col-span-2">
              <Controller
                // @ts-ignore
                name="loadedRadius.LS_FRONT"
                control={control}
                rules={{ required: `Left front is required.` }}
                render={({ field, fieldState }) => (
                  <>
                    <label htmlFor={field.name} className="mb-0">
                      Left Front
                    </label>
                    <InputNumber
                      id={field.name}
                      mode="decimal"
                      maxFractionDigits={1}
                      tabIndex={1}
                      // @ts-ignore
                      value={field.value}
                      inputClassName={classNames('w-full', { error: fieldState.error })}
                      onChange={(e) => field.onChange(e.value)}
                    />
                  </>
                )}
              />
              {getFormErrorMessage('loadedRadius.LS_FRONT')}
            </div>

            <div className="field-box col-span-2">
              <Controller
                // @ts-ignore
                name="loadedRadius.RS_FRONT"
                control={control}
                rules={{ required: `Right front is required.` }}
                render={({ field, fieldState }) => (
                  <>
                    <label htmlFor={field.name} className="mb-0">
                      Right Front
                    </label>
                    <InputNumber
                      id={field.name}
                      mode="decimal"
                      tabIndex={2}
                      maxFractionDigits={1}
                      // @ts-ignore
                      value={field.value}
                      inputClassName={classNames('w-full', { error: fieldState.error })}
                      onChange={(e) => field.onChange(e.value)}
                    />
                  </>
                )}
              />
              {getFormErrorMessage('loadedRadius.RS_FRONT')}
            </div>

            <div className="col-span-1"></div>


            <div className="field-box col-span-2">
              <Controller
                // @ts-ignore
                name="stateTransform.FRH"
                control={control}
                rules={{ required: `FRH is required.` }}
                render={({ field, fieldState }) => (
                  <>
                    <label htmlFor={field.name} className="mb-0">
                      FRH
                    </label>
                    <InputNumber
                      id={field.name}
                      mode="decimal"
                      tabIndex={5}
                      maxFractionDigits={0}
                      // @ts-ignore
                      value={field.value}
                      inputClassName={classNames('w-full', { error: fieldState.error })}
                      onChange={(e) => field.onChange(e.value)}
                    />
                  </>
                )}
              />
              {getFormErrorMessage('stateTransform.FRH')}
            </div>

            <div className="field-box col-span-2">
              <Controller
                // @ts-ignore
                name="stateTransform.RRH"
                control={control}
                rules={{ required: `RRH is required.` }}
                render={({ field, fieldState }) => (
                  <>
                    <label htmlFor={field.name} className="mb-0">
                      RRH
                    </label>
                    <InputNumber
                      id={field.name}
                      mode="decimal"
                      tabIndex={6}
                      maxFractionDigits={0}
                      // @ts-ignore
                      value={field.value}
                      inputClassName={classNames('w-full', { error: fieldState.error })}
                      onChange={(e) => field.onChange(e.value)}
                    />
                  </>
                )}
              />
              {getFormErrorMessage('stateTransform.RRH')}
            </div>

            <div className="field-box col-span-2">
              <Controller
                // @ts-ignore
                name="loadedRadius.LS_REAR"
                control={control}
                rules={{ required: `Left rear is required.` }}
                render={({ field, fieldState }) => (
                  <>
                    <label htmlFor={field.name} className="mb-0">
                      Left Rear
                    </label>
                    <InputNumber
                      id={field.name}
                      mode="decimal"
                      tabIndex={3}
                      maxFractionDigits={1}
                      // @ts-ignore
                      value={field.value}
                      inputClassName={classNames('w-full', { error: fieldState.error })}
                      onChange={(e) => field.onChange(e.value)}
                    />
                  </>
                )}
              />
              {getFormErrorMessage('loadedRadius.LS_REAR')}
            </div>

            <div className="field-box col-span-2">
              <Controller
                // @ts-ignore
                name="loadedRadius.RS_REAR"
                control={control}
                rules={{ required: `Right rear is required.` }}
                render={({ field, fieldState }) => (
                  <>
                    <label htmlFor={field.name} className="mb-0">
                      Right Rear
                    </label>
                    <InputNumber
                      id={field.name}
                      mode="decimal"
                      tabIndex={4}
                      maxFractionDigits={1}
                      // @ts-ignore
                      value={field.value}
                      inputClassName={classNames('w-full', { error: fieldState.error })}
                      onChange={(e) => field.onChange(e.value)}
                    />
                  </>
                )}
              />
              {getFormErrorMessage('loadedRadius.RS_REAR')}
            </div>

            <div className="col-span-1"></div>

            <div className="field-box col-span-2">
              <Controller
                // @ts-ignore
                name="stateTransform.ROLL"
                control={control}
                rules={{ required: `Roll is required.` }}
                render={({ field, fieldState }) => (
                  <>
                    <label htmlFor={field.name} className="mb-0">
                      Roll
                    </label>
                    <InputNumber
                      id={field.name}
                      mode="decimal"
                      tabIndex={7}
                      maxFractionDigits={1}
                      // @ts-ignore
                      value={field.value}
                      inputClassName={classNames('w-full', { error: fieldState.error })}
                      onChange={(e) => field.onChange(e.value)}
                    />
                  </>
                )}
              />
              {getFormErrorMessage('stateTransform.Roll')}
            </div>

            <div className="field-box col-span-2">
              <Controller
                // @ts-ignore
                name="stateTransform.STEER"
                control={control}
                rules={{ required: `Steer is required.` }}
                render={({ field, fieldState }) => (
                  <>
                    <label htmlFor={field.name} className="mb-0">
                      Steer
                    </label>
                    <InputNumber
                      id={field.name}
                      mode="decimal"
                      tabIndex={8}
                      maxFractionDigits={0}
                      // @ts-ignore
                      value={field.value}
                      inputClassName={classNames('w-full', { error: fieldState.error })}
                      onChange={(e) => field.onChange(e.value)}
                    />
                  </>
                )}
              />
              {getFormErrorMessage('stateTransform.STEER')}
            </div>
          </div>


          <div className="field-box col-span-9">
            <Controller
              name="cadDir"
              control={control}
              disabled
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className="mb-2">
                    CAD Dirrectory (read only)
                  </label>
                  <InputText
                    id={field.name}
                    {...field}
                    className={classNames({ error: fieldState.error })}
                  />
                </>
              )}
            />
          </div>

          <div className="w-full col-span-9 pt-6 flex items-center justify-end space-x-6">
            <Button
              type="submit">
              {saving && <LoadingIcon className="fill-white w-4 h-4" />}
              {!saving && <SaveIcon className="fill-white w-4 h-4" />}
              <span className="ml-1">Save</span>
            </Button>
            <CloseButton label="Close" onClick={() => onCancel && onCancel()} />
          </div>
        </form>
      </div >
    </Modal >
  )
}

export default TyreStateEditorModal
