import React, { FC } from 'react'

const ErrorPage: FC = () => {
  return (
    <div className="basis-0 grow flex flex-col items-center justify-center">
      <h1 className="text-xl text-red-500">Application Error</h1>
    </div>
  )
}

export default ErrorPage
