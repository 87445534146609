import * as Proto from '@aero-platform/shared'
import { Buffer } from 'buffer'
import * as Types from '../types/types'

export default class ProtoService {
  encode(byteArray: any) {
    return Buffer.from(byteArray).toString('base64')
  }

  decode(stringVal: string) {
    return Buffer.from(stringVal, 'base64')
  }

  encodePayload(json: any, type: string) {
    const serializedBinary = this.encode(this.serializeJson(json, type))

    return {
      specversion: '1.0',
      id: this.genCloudEventId(),
      source: 'ms-aero-srvc.gm-motorsports.com',
      type: type,
      datacontenttype: 'application/proto',
      data_base64: serializedBinary
    }
  }

  serializeJson(jsonData: any, type: string) {
    console.log('JSONDATA: ', jsonData)

    switch (type) {
      case 'project': {
        const temp: Proto.Project = new Proto.Project(jsonData)
        return temp.serializeBinary()
      }
      case 'runconfig': {
        const temp: Proto.RunConfig = new Proto.RunConfig(jsonData)
        return temp.serializeBinary()
      }
      case 'runconfigcase': {
        const temp: Proto.RunConfigCase = new Proto.RunConfigCase(jsonData)
        return temp.serializeBinary()
      }
      case 'vehicle': {
        const temp: Proto.Vehicle = new Proto.Vehicle(jsonData)
        return temp.serializeBinary()
      }
      case 'user': {
        const temp: Proto.User = new Proto.User(jsonData)
        return temp.serializeBinary()
      }
      case 'methodology': {
        const temp: Proto.Methodology = new Proto.Methodology(jsonData)
        return temp.serializeBinary()
      }
      case 'methodologyset': {
        const temp: Proto.MethodologySet = new Proto.MethodologySet(jsonData)
        return temp.serializeBinary()
      }
      case 'projectconfig': {
        const temp: Proto.ProjectConfig = new Proto.ProjectConfig(jsonData)
        return temp.serializeBinary()
      }
      case 'cfdfile': {
        const temp: Proto.CFDFile = new Proto.CFDFile(jsonData)
        return temp.serializeBinary()
      }
      case 'geometry': {
        const temp: Proto.Geometry = new Proto.Geometry(jsonData)
        return temp.serializeBinary()
      }
      case 'rescaletoken': {
        const temp: Proto.RescaleToken = new Proto.RescaleToken(jsonData)
        return temp.serializeBinary()
      }
      case 'workflowtask': {
        const temp: Proto.WorkflowTask = new Proto.WorkflowTask(jsonData)
        return temp.serializeBinary()
      }
      case 'workflow': {
        const temp: Proto.Workflow = new Proto.Workflow(jsonData)
        return temp.serializeBinary()
      }
      case 'workflowsubmission': {
        const temp: Proto.WorkflowSubmission = new Proto.WorkflowSubmission(jsonData)
        return temp.serializeBinary()
      }
      case 'tyreset': {
        const temp: Proto.TyreSet = new Proto.TyreSet(jsonData)
        return temp.serializeBinary()
      }
      case 'tyrestate': {
        const temp: Proto.TyreState = new Proto.TyreState(jsonData)
        return temp.serializeBinary()
      }
      case 'attitudeset': {
        const temp: Proto.AttitudeSet = new Proto.AttitudeSet(jsonData)
        return temp.serializeBinary()
      }
      case 'domainset': {
        const temp: Proto.DomainSet = new Proto.DomainSet(jsonData)
        return temp.serializeBinary()
      }
      case 'domainstate': {
        const temp: Proto.DomainState = new Proto.DomainState(jsonData)
        return temp.serializeBinary()
      }
      case 'simulationset': {
        const temp: Proto.SimulationSet = new Proto.SimulationSet(jsonData)
        return temp.serializeBinary()
      }
      case 'simulationstate': {
        const temp: Proto.SimulationState = new Proto.SimulationState(jsonData)
        return temp.serializeBinary()
      }
      //TODO: set up additional serialization based on given type
      default: {
        return []
      }
    }
  }

  deSerialize(data: string, type: string) {
    const decodedData = this.decode(data)
    switch (type) {
      case 'project': {
        const project = Proto.Project.deserializeBinary(decodedData)
        const projObj = project.toObject()
        const result = {
          ...projObj,
          stDate: projObj.startDate ? new Date(projObj.startDate) : null,
          engineerId: projObj.engineer?._id,
          engineerName: projObj.engineer?.firstName + ' ' + projObj.engineer?.lastName,
          methodId: projObj.method?._id,
          geometryId: projObj.baselineGeometry?._id,
          // sequenceId: projObj.baselineVSGroup?._id,
          configId: projObj.projectConfig?._id,
          contributorIds: projObj.contributors ? projObj.contributors?.map((user) => user._id) : [],
          submissionTemplateObj: {
            _id: projObj.baselineSubmissionTemplate,
            name: ''
          } as Types.WorkflowSubmissionTemplate
        } as Types.Project
        return result
      }
      case 'runconfig': {
        const runConfig = Proto.RunConfig.deserializeBinary(decodedData)
        const runConfigObj = runConfig.toObject()
        const result = {
          ...runConfigObj,
          //need to fix, selectedMethod will change into an Method Object
          methodId: runConfigObj.selectedMethod?._id,
        } as Types.RunConfig

        return result
      }
      case 'runconfigcase': {
        const runConfigCase = Proto.RunConfigCase.deserializeBinary(decodedData)
        return runConfigCase.toObject() as any
      }
      case 'vehicle': {
        const vehicle = Proto.Vehicle.deserializeBinary(decodedData)
        return vehicle.toObject() as any
      }
      case 'user': {
        const result = Proto.User.deserializeBinary(decodedData)
        return result.toObject() as any
      }
      case 'methodology': {
        const result = Proto.Methodology.deserializeBinary(decodedData)
        return result.toObject() as any
      }
      case 'methodologyset': {
        const result = Proto.MethodologySet.deserializeBinary(decodedData)
        return result.toObject() as any
      }
      case 'tyreset': {
        const result = Proto.TyreSet.deserializeBinary(decodedData)
        return result.toObject() as any
      }
      case 'tyrestate': {
        const result = Proto.TyreState.deserializeBinary(decodedData)
        return result.toObject() as any
      }
      case 'attitudeset': {
        const result = Proto.AttitudeSet.deserializeBinary(decodedData)
        return result.toObject() as any
      }
      case 'domainset': {
        const result = Proto.DomainSet.deserializeBinary(decodedData)
        return result.toObject() as any
      }
      case 'domainstate': {
        const result = Proto.DomainState.deserializeBinary(decodedData)
        return result.toObject() as any
      }
      case 'simulationset': {
        const result = Proto.SimulationSet.deserializeBinary(decodedData)
        return result.toObject() as any
      }
      case 'simulationstate': {
        const result = Proto.SimulationState.deserializeBinary(decodedData)
        return result.toObject() as any
      }
      case 'cfdfile': {
        const result = Proto.CFDFile.deserializeBinary(decodedData)
        return result.toObject() as any
      }
      case 'projectconfig': {
        const result = Proto.ProjectConfig.deserializeBinary(decodedData)
        return result.toObject() as any
      }
      case 'geometry': {
        const result = Proto.Geometry.deserializeBinary(decodedData)
        return result.toObject() as any
      }
      case 'workflowtask': {
        const result = Proto.WorkflowTask.deserializeBinary(decodedData)
        return result.toObject() as any
      }
      case 'workflow': {
        const result = Proto.Workflow.deserializeBinary(decodedData)
        return result.toObject() as any
      }
      case 'workflowsubmission': {
        const result = Proto.WorkflowSubmission.deserializeBinary(decodedData)
        return result.toObject() as any
      }
      case 'workflowsubmissiontemplate': {
        const result = Proto.WorkflowSubmissionTemplate.deserializeBinary(decodedData)
        return result.toObject() as any
      }
      default: {
        return ''
      }
    }
  }

  decodeProtoResponse(protoResponseData: Types.AxiosResponseProtoData, type: string) {
    let result
    if (protoResponseData && protoResponseData.protoBytesList) {
      result = protoResponseData.protoBytesList.map((item) => {
        return this.deSerialize(item, type)
      })
    }
    return result || []
  }

  genCloudEventId() {
    const date = Date.now().toString(36)
    const randomStr = Math.random().toString(36).substring(2, 8)
    return `${date}-${randomStr}`
  }
}

export const protoService = new ProtoService()
