import { RunStatus } from "@aero-platform/shared"
import { ComponentProps, ReactNode } from "react"
import { twMerge } from "tailwind-merge"



interface DataTableTextProp extends ComponentProps<'div'> {
  children?: ReactNode
}

const TableText = ({ children, className, ...props }: DataTableTextProp) => {
  return (
    <div
      className={twMerge("font-semibold dark:text-neutral text-xs", className)} {...props}>
      {children}
    </div>
  )
}


type RunStatusDatatableTextProps = {
  status?: RunStatus
  children?: ReactNode
}

export const RunStatusDatatableText = (props: RunStatusDatatableTextProps) => {
  switch (props.status) {
    case RunStatus.UNSPECIFIED:
    case RunStatus.CREATED:
      return (
        <TableText className="text-black">
          {props.children}
        </TableText>
      )

    case RunStatus.SUBMITTED:
    case RunStatus.PENDING:
      return (
        <TableText className="text-pending-run">
          {props.children}
        </TableText>
      )

    case RunStatus.RUNNING: {
      return (
        <TableText className="text-status-closed">
          {props.children}
        </TableText>
      )
    }
    case RunStatus.COMPLETED: {
      return (
        <TableText className="text-gm-blue">
          {props.children}
        </TableText>
      )
    }
    case RunStatus.ERROR: {
      return (
        <TableText className="text-run-error">
          {props.children}
        </TableText>
      )
    }
    default: {
      return (
        <TableText>
          {props.children}
        </TableText>
      )
    }
  }
}
