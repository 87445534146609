import React, { FC } from 'react'
import { RunStatus } from '../../types/types'
import { WorkflowStatusDef } from '../../utils/constants'
import { RunStatusCircle } from './StatusCircle'

type SubmissionStatusCellProps = {
  status: RunStatus
}

/*
export interface RunStatusMap {
  UNSPECIFIED: 0
  NOT_SUBMITTED: 1
  SUBMITTED: 2
  RUNNING: 3
  COMPLETED: 4
  ERROR: 5
  CREATED: 6
}

*/

const SubmissionStatusCell: FC<SubmissionStatusCellProps> = ({ status }) => {
  /*
  const statusClass = classNames('rounded-full w-2 h-2', {
    'bg-status-closed': status === RunStatus.CREATED,
    'bg-status-in-progress': status === RunStatus.CREATED,
    'bg-status-not-started': status === RunStatus.CREATED
  })
  */
  return (
    <div className="flex items-center ">
      <RunStatusCircle status={status} />
      <div>{WorkflowStatusDef[status].name}</div>
    </div>
  )
}

export default SubmissionStatusCell
