/* eslint-disable react/jsx-boolean-value */
import { InteractionStatus } from '@azure/msal-browser'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { Navigate, useLocation } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { currentUserState } from '../utils/state-atoms'
import routes from './routes'

type Props = {
  element: JSX.Element
}

const RequireAuth = ({ element }: Props) => {
  const location = useLocation()
  const isAuthenticated = useIsAuthenticated()
  const { inProgress } = useMsal()
  const currentUser = useRecoilValue(currentUserState)

  if ((inProgress === InteractionStatus.None && !isAuthenticated) || !currentUser) {
    return (
      <Navigate to={routes.Login.absolutePath} state={{ ref: location.pathname + location.search }} replace={true} />
    )
  } else {
    return element
  }
}

export default RequireAuth
