import { FilterMatchMode } from 'primereact/api'
import { Button } from 'primereact/button'
import { DataTableFilterMeta } from 'primereact/datatable'
import { Dropdown } from 'primereact/dropdown'
import { FC, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useRecoilValue } from 'recoil'
import {
  useCreateWorkflow,
  useGetWorkflows,
  useUpdateWorkflow
} from '../api/workflow-API'
import { CopyIcon, PlusIcon, SaveIcon } from '../components/Icons'
import { protoService } from '../proto/ProtoService'
import { LazyTableState, Workflow } from '../types/types'
import { queryString2JsonString } from '../types/util'
import { DefaultPageSize, DummyWorkflow, workflowTemplate } from '../utils/constants'
import { currentTeamState } from '../utils/state-atoms'
import { JsonEditor } from 'json-edit-react'
import SaveButton from '../components/buttons/SaveButton'
import { ca } from 'date-fns/locale'
import WorkflowTasks from './WorkflowTasks'

const defaultFilters: DataTableFilterMeta = {
  name: { value: null, matchMode: FilterMatchMode.CONTAINS },
  workflowId: { value: null, matchMode: FilterMatchMode.EQUALS },
  status: { value: null, matchMode: FilterMatchMode.EQUALS }
}

const Workflows: FC = () => {
  const currentTeam = useRecoilValue(currentTeamState)
  const [mode, setMode] = useState('')
  const [{ loading, data: workflowData }, fetchWorkflows] = useGetWorkflows()
  const [{ loading: creating }, createWorkflow] = useCreateWorkflow()
  const [{ loading: updating }, updateWorkflow] = useUpdateWorkflow('')

  const [workflows, setWorkflows] = useState<Workflow[]>([])
  const [currentWorkflow, setCurrentWorkflow] = useState(DummyWorkflow)
  const [currentJsonData, setCurrentJsonData] = useState({})
  const [lazyState, setlazyState] = useState<LazyTableState>({
    first: 0,
    rows: DefaultPageSize,
    page: 1,
    sortField: 'name',
    sortOrder: -1,
    filters: defaultFilters,
    sumlvl: 'vehicleStatesList:0,geometryList:0,tyreSetList:0,cfdSequenceList:0,workflow:0'
  })

  useEffect(() => {
    if (workflowData) {
      console.log(workflowData)
      setWorkflows(workflowData)
      const workflowList = workflowData.protoBytesList.map((item: string) => {
        const result = protoService.deSerialize(item, 'workflow')
        return result
      })
      console.log(workflowList)
      workflowList && setWorkflows(workflowList)
    }
  }, [workflowData])

  const makeQuery = (state) => {
    let query
    console.log(state)

    if (state.filters.name['value']) {
      const nameSearch = { $regex: state.filters.name['value'], $options: 'i' }
      const q = 'name=' + encodeURIComponent(JSON.stringify(nameSearch))
      query = query ? `${query}&${q}` : q
    }

    if (state.filters.workflowId['value']) {
      const q = 'workflowId=' + state.filters.workflowId['value']
      query = query ? `${query}&${q}` : q
    }

    if (state.filters.status['value']) {
      const q = 'status=' + state.filters.status['value']
      query = query ? `${query}&${q}` : q
    }

    query = queryString2JsonString(query)
    query = query ? `query=${query}&limit=${state.rows}` : `limit=${state.rows}`
    query = `${query}&skip=${state.first}&countTotal=true`
    if (state.sortField) {
      const sort = {
        [state.sortField]: state.sortOrder
      }
      query = `${query}&sort=${JSON.stringify(sort)}`
    }

    return query
  }

  useEffect(() => {
    const query = makeQuery(lazyState)
    fetchWorkflows(query).catch((err) => {
      console.error(err)
      toast.error('Error loading workflows.')
    })
  }, [lazyState])

  const handleCreateNewWorkflow = () => {
    if (mode === 'new') {
      return
    }
    setCurrentWorkflow({
      configJSON: JSON.stringify(workflowTemplate, undefined, 8)
    } as Workflow)

    setCurrentJsonData(workflowTemplate)
    setMode('new')
  }

  const handleCopyWorkflow = (e) => {
    if (mode === 'new') {
      toast.error('Cannot duplicate while creating a new workflow.')
      return
    }
    if (!currentWorkflow._id) {
      toast.error('Please select a workflow to duplicate.')
      return
    }
    setCurrentWorkflow({
      configJSON: currentWorkflow.configJSON
    } as Workflow)

    console.log(currentWorkflow)
    const tmpJsonObj = currentWorkflow.configJSON ? JSON.parse(currentWorkflow.configJSON) : '{}'
    tmpJsonObj.id = ''
    setCurrentJsonData(tmpJsonObj)

    setMode('new')
  }

  const handleWorkflowSelected = (workflow) => {
    console.log(workflow)
    setCurrentWorkflow({ ...workflow } as Workflow)
    try {
      workflow.configJSON && setCurrentJsonData(JSON.parse(workflow.configJSON))
    } catch (err) {
      console.error(err)
      console.log(workflow.configJSON)
      toast.error('Error parsing JSON data.')
    }
    setMode('edit')
  }

  const handleSaveWorkflow = async () => {
    if (mode === 'new') {
      const newWorkflowData = {
        name: '',
        configJSON: currentJsonData ? JSON.stringify(currentJsonData) : '',
        raceTeamId: currentTeam
      }
      console.log('newWorkflowData=', newWorkflowData)
      const dataToPost = protoService.encodePayload(newWorkflowData, 'workflow')
      try {
        const res = await createWorkflow({ data: dataToPost })
        if (res.data.protoBytesList && res.data.protoBytesList.length > 0) {
          const newWorkflow: Workflow = protoService.deSerialize(
            res.data.protoBytesList[0],
            'workflow'
          )
          setWorkflows([...workflows, newWorkflow])
          setCurrentWorkflow(newWorkflow)
          setCurrentJsonData(JSON.parse(newWorkflow.configJSON))
          // setSelectedWorkflowId(newWorkflow._id)
          toast.success('Workflow created.')
          setMode('edit')
        }
      } catch (err) {
        console.error(err)
        toast.error('Error creating workflow.')
      }
    } else {
      if (!currentWorkflow._id) {
        return
      }
      const newWorkflowData = {
        ...currentWorkflow,
        configJSON: currentJsonData ? JSON.stringify(currentJsonData) : '',
      } as Workflow
      console.log('newWorkflowData=', newWorkflowData)
      const dataToPost = protoService.encodePayload(newWorkflowData, 'workflow')
      try {
        await updateWorkflow({
          url: `/Workflows/${currentWorkflow._id}`,
          data: dataToPost
        })
        setWorkflows(
          workflows.map((item) => {
            if (item._id === currentWorkflow._id) {
              return newWorkflowData
            } else {
              return item
            }
          })
        )
        toast.success('Workflow updated.')
      } catch (err) {
        console.error(err)
        toast.error('Error updating workflow.')
      }
    }
  }

  return (
    <div className="basis-0 grow flex flex-col items-stretch mt-6 min-w-full space-y-4">
      <div className="flex items-center justify-between pb-4">
        <h1 className="page-title">Workflows</h1>
        <Button
          onClick={handleCreateNewWorkflow}
          severity="secondary"
          className="icon-button"
          disabled={mode === 'new'}
          tooltip='Create New Workflow'
          tooltipOptions={{ position: 'bottom' }}
          data-pr-at='center+50 bottom'
        >
          <PlusIcon className="fill-white" />
        </Button>
      </div>
      <div className='grid grid-cols-2 gap-6'>
        <div className="flex flex-col items-stretch mt-6 min-w-full space-y-4">
          <div className="flex items-center space-x-2">
            <Dropdown
              filter
              filterBy="name"
              filterMatchMode="contains"
              showFilterClear
              onFilter={(e) => {
                setlazyState({
                  ...lazyState,
                  filters: {
                    ...lazyState.filters,
                    name: { value: e.filter, matchMode: FilterMatchMode.CONTAINS }
                  }
                })
              }}
              value={currentWorkflow}
              emptyMessage="No Workflows Found"
              pt={{
                root: {
                  className: 'min-w-[50%] max-w-[50%]'
                },
                input: {
                  className: 'py-1'
                }
              }}
              optionValue="_id"
              optionLabel="name"
              useOptionAsValue
              options={workflows}
              valueTemplate={(option) => {
                let value
                if (mode === 'new') {
                  value = 'New Workflow'
                } else if (option) {
                  value = option.name
                } else {
                  value = (currentWorkflow && currentWorkflow.name) || 'Select workflow'
                }
                return <div>{value}</div>
              }}
              itemTemplate={(option) => <div>{option && option._id ? option.name : ''}</div>}
              onChange={(e) => handleWorkflowSelected(e.value)}
              placeholder="Select Workflow 1"
            />
            <Button onClick={handleCopyWorkflow} className="icon-button" tooltip='Duplicate Workflow' tooltipOptions={{ position: 'bottom' }} data-pr-at='center+5 bottom'>
              <CopyIcon className="fill-white stroke-white w-4 h-4" />
            </Button>

            <SaveButton onClick={handleSaveWorkflow} loading={creating || updating} title="Save Workflow" />
          </div>
          <div className='flex-grow overflow-auto border rounded-md p-2 border-gray-400' style={{ maxHeight: 'calc(100vh - 315px)' }}>
            {(currentWorkflow._id || mode === 'new') &&
              <JsonEditor minWidth={'100%'}
                rootName=''
                theme="githubLight"
                data={currentJsonData}
                setData={(data) => {
                  console.log(data)
                  setCurrentJsonData(data)
                }}
                enableClipboard={() => {
                  toast.success('data copied to clipboard.')
                }}
              />}
          </div>
        </div>
        <WorkflowTasks />
      </div >
      <div className="h-0.5"></div>
    </div >
  )
}

export default Workflows
