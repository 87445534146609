import { UseAxiosResult } from 'axios-hooks'
import { apiClient, useAxiosClient } from './configs/axios-setup'

export interface IRequestError {
  status: number
  text: string
}

export const useGetProjects = (): UseAxiosResult => {
  const [{ data, loading, error }, doFetch] = useAxiosClient(
    {
      url: '/Projects', //  + (query ? `?${query}` : ''),
      method: 'GET'
    },
    { manual: true, autoCancel: false, useCache: false }
  )

  const refetch = (query) => {
    return doFetch({
      url: '/Projects' + (query ? `?${query}` : '') // + ',{$sort:{timeCreated:-1},limit:3}'
    })
  }

  return [
    { data, loading, error },
    refetch,
    () => {
      return
    }
  ]
}

export const useGetProject = (): UseAxiosResult => {
  const [{ data, loading, error }, doFetch] = useAxiosClient(
    {
      url: `/Projects`,
      method: 'GET'
    },
    { manual: true, autoCancel: false, useCache: false }
  )

  const refetch = (id) => {
    return doFetch({
      url: `/Projects/${id}`,
      method: 'GET'
    })
  }

  return [
    { data, loading, error },
    refetch,
    () => {
      return
    }
  ]
}

export const useCreateProject = (): UseAxiosResult => {
  const [{ data, loading, error }, doFetch] = useAxiosClient(
    {
      url: '/Projects',
      method: 'POST'
    },
    { manual: true, autoCancel: false, useCache: false }
  )

  return [
    { data, loading, error },
    doFetch,
    () => {
      return
    }
  ]
}

export const useUpdateProject = (id: string): UseAxiosResult => {
  const [{ data, loading, error }, doFetch] = useAxiosClient(
    {
      url: `/Projects/${id}`,
      method: 'PUT'
    },
    { manual: true, autoCancel: false, useCache: false }
  )

  return [
    { data, loading, error },
    doFetch,
    () => {
      return
    }
  ]
}

export async function getProjectStatus(id: string) {
  const res = await apiClient.get(`/Projects/${id}`)
  return res
}
