import { Button } from 'primereact/button'
import { FC, useEffect, useState } from 'react'
import Modal from './Modal'
import CloseButton from './buttons/CloseButton'
import { classNames } from 'primereact/utils'
import { Controller, useForm } from 'react-hook-form'
import { InputText } from 'primereact/inputtext'
import { InputNumber } from 'primereact/inputnumber'
import {
  AttitudeState,
  RunConfigCase
} from '@aero-platform/shared'

const attitudeStateFields = [
  {
    name: 'frh',
    label: 'FRH'
  },
  {
    name: 'rrh',
    label: 'RRH'
  },
  {
    name: 'steer',
    label: 'Steer'
  },
  {
    name: 'roll',
    label: 'Roll'
  },
  {
    name: 'yaw',
    label: 'Yaw'
  },
  {
    name: 'radius',
    label: 'Rad'
  },
  {
    name: 'speed',
    label: 'Spd'
  },
  {
    name: 'exhaust',
    label: 'Exh'
  }
]
const title = {
  'edit': 'Edit State',
  'new': 'Add New State'
}

type AttitudeStateEditorModalProps = {
  mode: string,
  states: AttitudeState[],
  stateIndex: number,
  onConfirm: (runConfigCase: RunConfigCase, mode: string) => void
  onCancel: () => void
}

const AttitudeStateEditorModal: FC<AttitudeStateEditorModalProps> = ({
  mode,
  states,
  stateIndex,
  onConfirm,
  onCancel
}) => {
  const {
    control,
    setValue,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<AttitudeState>()
  const [selectedAttitudeState] = useState<AttitudeState>(
    mode === 'new' ? ({} as AttitudeState) : { ...states[stateIndex] } as AttitudeState)

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="error">{errors[name]?.message}</small>
    ) : (
      <small className="error">&nbsp;</small>
    )
  }

  const handleSave = async (formData) => {
    console.log('formData', formData)
    if (states.find(
      (caseItem, index) => caseItem.name === formData.name && (stateIndex !== index || mode === 'new'))
    ) {
      setError('name', { type: 'manual', message: 'State name already exists.' })
      return
    }
    onConfirm && onConfirm(formData, mode as string)
  }

  const handleConfirm = () => {
    onCancel && onCancel()
  }

  useEffect(() => {
    if (mode === 'edit') {
      setValue('name', selectedAttitudeState.name)
      attitudeStateFields.forEach((fieldItem) => {
        // @ts-ignore
        setValue(fieldItem.name, selectedAttitudeState[fieldItem.name])
      })
    }
  }, [])

  return (
    <Modal
      isOpen={true}
      withAction={false}
      easyClose={true}
      onCancel={() => onCancel && onCancel()}
      title={title[mode || 'new']}
      showCancelButton={true}
      zIndex={30}
      onConfirm={handleConfirm}>
      <div className='relative'>
        <form
          onSubmit={handleSubmit(handleSave)}
          className="w-full grid grid-cols-8 gap-x-2 gap-y-4 mt-8">
          <div className="field-box col-span-2">
            <Controller
              name="name"
              control={control}
              rules={{ required: 'Name is required.' }}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className="mb-2">
                    Name
                  </label>
                  <InputText
                    id={field.name}
                    {...field}
                    className={classNames({ error: fieldState.error })}
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>
          <div className="col-span-6"></div>

          {attitudeStateFields.map((fieldItem) => {
            return (
              <div className="field-box" key={fieldItem.name}>
                <Controller
                  // @ts-ignore
                  name={fieldItem.name}
                  control={control}
                  rules={{ required: `${fieldItem.label} is required.` }}
                  render={({ field, fieldState }) => (
                    <>
                      <label htmlFor={field.name} className="mb-2">
                        {fieldItem.label}
                      </label>
                      <InputNumber
                        id={field.name}
                        mode="decimal"
                        maxFractionDigits={6}
                        // @ts-ignore
                        value={field.value}
                        inputClassName={classNames('w-full', { error: fieldState.error })}
                        onChange={(e) => field.onChange(e.value)}
                      />
                    </>
                  )}
                />
                {getFormErrorMessage(fieldItem.name)}
              </div>
            )
          })}


          <div className="w-full col-span-8 pt-6 flex items-center justify-end space-x-6">
            <Button
              type="submit"
              label="Save State"
            />
            <CloseButton label="Close" onClick={() => onCancel && onCancel()} />
          </div>
        </form>
      </div >
    </Modal >
  )
}

export default AttitudeStateEditorModal
