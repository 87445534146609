import axios, { AxiosInstance } from 'axios'
import { makeUseAxios, UseAxios } from 'axios-hooks'
import { AccessTokenKey, GMMSRaceTeamKey, RescaleTokenKey } from '../../utils/constants'
import { appEventInstance } from '../../utils/event'

export const apiClient: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'x-api-key': process.env.API_KEY,
    Authorization: `Bearer ${localStorage.getItem(AccessTokenKey)}`,
    AuthorizationRescale: localStorage.getItem(RescaleTokenKey)
  },
  timeout: 10 * 1000,
  withCredentials: true
})

export const useAxiosClient: UseAxios = makeUseAxios({
  axios: apiClient,
  defaultOptions: {
    manual: true,
    useCache: false,
    ssr: false,
    autoCancel: false
  }
})

apiClient.interceptors.request.use(
  async (request) => {
    console.log('Request intercepted', request)
    const accessToken = localStorage.getItem(AccessTokenKey)
    const teamId = localStorage.getItem(GMMSRaceTeamKey)
    request.headers.Authorization = `Bearer ${accessToken}`
    request.headers.AuthorizationRescale =
      localStorage.getItem(RescaleTokenKey) || 'dummy-reascale-token'
    request.headers.GMMSRaceTeamId = teamId || 'dummy-race-team-id'

    return request
  },
  (error) => {
    Promise.reject(error)
  }
)

apiClient.interceptors.response.use(
  function (response) {
    console.log('Response intercepted')
    return response
  },
  function (error) {
    console.error('response error: ', error)
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      appEventInstance.error401()
      //window.location.href = '/auth/login'
      return error // upstream code will not catch this error
    } else if (error.response && error.response.status === 404) {
      return Promise.resolve(error)
    } else if (error.code === 'ECONNABORTED' || error.code === 'ERR_NETWORK') {
      appEventInstance.errorNetwork()
      return Promise.resolve(error)
    } else {
      // let upstream code to catch and eal with the error
      return Promise.reject(error)
    }
  }
)
