import { Workflow, WorkflowRunGroup } from "@aero-platform/shared"
import { DataTableRowExpansionTemplate } from "primereact/datatable"
import TaskList from "./TaskList"

const MasterRowExpansionTemplate = (workflowSubmissionId: string, workflowRunGroups: WorkflowRunGroup[]) => {
  return (rowData: WorkflowRunGroup, options: DataTableRowExpansionTemplate) => {
    console.log('idx', options)
    if (!workflowRunGroups || workflowRunGroups.length === 0) {
      return null
    }

    return (rowData?.workflow.map((workflow: Workflow, index: number) => {
      return (
        <section className="flex flex-col items-stretch pl-8">
          <div className="grid grid-cols-12 pb-8">
            <div className="col-span-12 flex space-x-2 text-sm text-gm-blue dark:text-light-blue">
              <div className="font-semibold">Workflow #{index + 1}:</div>
              <div>{workflow?.name}</div>
            </div>

            <div className="col-span-2 px-2 ">
              <div className="text-[10px]">System Run ID</div>
              <div>{workflow?.systemRunId}</div>
            </div>

            <div className="col-span-10">
              <div className="text-[10px]">Description</div>
              <div>{workflow?.description}</div>
            </div>
          </div>

          <TaskList
            tasks={workflow?.workflowTasks}
            runId={workflowSubmissionId}
            workflowRunGroupSysId={rowData.systemRunId}
            workflowSystemRunId={workflow?.systemRunId} />
        </section>
      )
    }))


  }
}

export default MasterRowExpansionTemplate
