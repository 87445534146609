import { Workflow, WorkflowRunGroup } from "@aero-platform/shared"
import { DataTable, DataTableExpandedRows } from "primereact/datatable"
import { FC, useState } from "react"
import MasterRowExpansionTemplate from "./MasterRowExpansion"
import { CompactTableHeaderClassName } from "../../utils/constants"
import { RunStatus } from "../../types/types"
import { MasterStatesNameBodyTemplates } from "./MasterDataTableBodyTemplate"
import { CollapseIcon, ExpandIcon } from "../Icons"
import { Column } from "primereact/column"

type MasterGroupTableProps = {
  workflowSubmissionId: string
  masterWorkflowRunGroup: WorkflowRunGroup[]
}


const MasterGroupTable: FC<MasterGroupTableProps> = ({ workflowSubmissionId, masterWorkflowRunGroup }: MasterGroupTableProps) => {
  const [expandedRowsForMaster, setExpandedRowsForMaster] = useState<DataTableExpandedRows>({})

  const columnsForMaster = [
    {
      header: '',
      field: 'name',
      headerClassName: CompactTableHeaderClassName,
      bodyClassName: 'id-cell',
      body: (workflowGroup: WorkflowRunGroup) => {
        const rowName: string = workflowGroup.name
        const status: RunStatus | undefined = workflowGroup.overallStatus

        return (
          <div
            className="flex items-center space-x-2 cursor-pointer"
            onClick={() => {
              // get current row id => if in expandRows, remove; else, append
              const copyExpandedRows: DataTableExpandedRows = { ...expandedRowsForMaster }
              if (expandedRowsForMaster[rowName]) {  // if rowName exists in expandedRows
                // delete rowName from expandedRows
                delete copyExpandedRows[rowName]
              } else { // if rowName doesnt exist, add rowName to expandedRows
                copyExpandedRows[rowName] = true
              }
              setExpandedRowsForMaster(copyExpandedRows)
            }}>
            {
              expandedRowsForMaster[rowName] ?
                <CollapseIcon className="fill-dark-blue dark:fill-light-blue h-6 w-6" /> :
                <ExpandIcon className="fill-dark-blue dark:fill-light-blue h-6 w-6" />
            }
            {MasterStatesNameBodyTemplates(workflowGroup, status)}
          </div>
        )
      }
    }
  ]

  return (
    <DataTable
      value={masterWorkflowRunGroup}
      expandedRows={expandedRowsForMaster}
      rowExpansionTemplate={MasterRowExpansionTemplate(workflowSubmissionId, masterWorkflowRunGroup)}
      dataKey="name"
      pt={{
        bodyRow: {
          className: 'table-row'
        }
      }}>
      {columnsForMaster.map((item) => {
        return (
          <Column
            key={item.field}
            field={item.field}
            header={item.header}
            headerClassName={item.headerClassName}
            bodyClassName={item.bodyClassName}
            body={item.body}
          >
          </Column>
        )
      })}
    </DataTable>
  )
}

export default MasterGroupTable
