import * as Proto from '@aero-platform/shared'
import { DataTableExpandedRows, DataTableFilterMeta } from 'primereact/datatable'

//By re-exporting the Proto classes and namespaces as objects we can use them as typed object, TS will infer the type or value, whichever one is needed

export type Vehicle = Proto.Vehicle
export const Vehicle = Proto.Vehicle

export type User = Proto.User
export const User = Proto.User

export type LazyTableState = {
  first: number
  rows: number
  page: number
  sortField?: string
  sortOrder: 0 | 1 | -1 | null | undefined
  filters: DataTableFilterMeta
  sumlvl: string
}

export type OutputParameter = {
  cz: string
  czf: string
  czr: string
  cx: string
  czrw: string
  abc: string
} //Think we can remove? Its just a plain string field

export type ProjectStatus = Proto.ProjectStatus
export const ProjectStatus = Proto.ProjectStatus

export type ProjectStatusObj = {
  value: ProjectStatus
  name: string
}

export type RunStatus = Proto.RunStatus
export const RunStatus = Proto.RunStatus
export type RunStatusObj = {
  value: RunStatus
  name: string
}

export const Project = Proto.Project
export type ProjectProto = Proto.Project
export type Project = ProjectProto & {
  stDate?: Date
  enDate?: Date
  engineerId?: string
  engineerName?: string
  methodId?: string
  geometryId?: string
  configId?: string
  sequenceId?: string
  contributorIds?: string[]
  submissionTemplateObj?: WorkflowSubmissionTemplate
}

export const RunConfigProto = Proto.RunConfig
export type RunConfigProto = Proto.RunConfig
export type RunConfig = RunConfigProto & {
  project?: Project
  methodId?: string
  template?: WorkflowSubmissionTemplate
  tyreSetId?: string
  templateId?: string
  sequenceId?: string
  vehicleStates?: string[]
}

export const Trajectory = Proto.Trajectory
export type Trajectory = Proto.Trajectory

export const AttitudeSet = Proto.AttitudeSet
export type AttitudeSet = Proto.AttitudeSet

export const DomainSet = Proto.DomainSet
export type DomainSet = Proto.DomainSet

export const SimulationSet = Proto.SimulationSet
export type SimulationSet = Proto.SimulationSet

export const SecConfig = Proto.SecConfig
export type SecConfig = Proto.SecConfig

export type ProjDoc = Proto.ProjectDocument
export const ProjDoc = Proto.ProjectDocument

export type Methodology = Proto.Methodology
export const Methodology = Proto.Methodology

export type MethodologySet = Proto.MethodologySet
export const MethodologySet = Proto.MethodologySet

export type TyreSet = Proto.TyreSet
export const TyreSet = Proto.TyreSet

export type ProjectConfig = Proto.ProjectConfig
export const ProjectConfig = Proto.ProjectConfig

export type Geometry = Proto.Geometry
export const Geometry = Proto.Geometry

export type RescaleToken = Proto.RescaleToken
export const RescaleToken = Proto.RescaleToken

export type Workflow = Proto.Workflow
export const Workflow = Proto.Workflow

export type WorkflowTask = Proto.WorkflowTask
export const WorkflowTask = Proto.WorkflowTask

export type WorkflowSubmission = Proto.WorkflowSubmission
export const WorkflowSubmission = Proto.WorkflowSubmission

export type WorkflowSubmissionTemplate = Proto.WorkflowSubmissionTemplate
export const WorkflowSubmissionTemplate = Proto.WorkflowSubmissionTemplate

export type WorkflowTaskStatus = Proto.WorkflowTaskStatus
export const WorkflowTaskStatus = Proto.WorkflowTaskStatus

export type WorkflowRecord = Proto.WorkflowRecord
export const WorkflowRecord = Proto.WorkflowRecord

export type RescaleJob = Proto.RescaleJob
export const RescaleJob = Proto.RescaleJob

export type RescaleJobAnalysis = Proto.RescaleJobAnalysis
export const RescaleJobAnalysis = Proto.RescaleJobAnalysis

export type RescaleAnalysisVariable = Proto.RescaleAnalysisVariable
export const RescaleAnalysisVariable = Proto.RescaleAnalysisVariable

export type RescaleHardwareVariable = Proto.RescaleHardwareVariable
export const RescaleHardwareVariable = Proto.RescaleHardwareVariable

export type CoreSummary = Proto.RescaleCoreSummary
export const CoreSummary = Proto.RescaleCoreSummary

export type PlatformJob = Proto.PlatformJob
export const PlatformJob = Proto.PlatformJob

export type JobFile = Proto.JobFile
export const JobFile = Proto.JobFile

export type TaskExpandedData = Map<string, DataTableExpandedRows>

export type TableColDef = {
  field: string
  title: string
  // eslint-disable-next-line
  renderer?: (data: any) => string
}

export type SelectOption = {
  value: string
  label: string
  selected?: boolean
}

export type AxiosResponseProtoData = {
  protoBytesList: string[]
  protoStringList: string[]
  resultString?: string
}

export type RescaleFlag = Proto.RescaleFlag
export const RescaleFlag = Proto.RescaleFlag

export type RaceTeam = Proto.RaceTeam
export const RaceTeam = Proto.RaceTeam

export type TrejactoryDef = Record<string, string[]>
