import { FilterMatchMode } from 'primereact/api'
import { Button } from 'primereact/button'
import { DataTableFilterMeta } from 'primereact/datatable'
import { Dropdown } from 'primereact/dropdown'
import { InputTextarea } from 'primereact/inputtextarea'
import { FC, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useRecoilValue } from 'recoil'
import {
  useCreateProjectConfig,
  useGetProjectConfigs,
  useUpdateProjectConfig
} from '../api/project-config-API'
import { CopyIcon, PlusIcon, SaveIcon } from '../components/Icons'
import { protoService } from '../proto/ProtoService'
import { LazyTableState, ProjectConfig } from '../types/types'
import { queryString2JsonString } from '../types/util'
import { DefaultPageSize, DummyProjectConfig, projectConfigTemplate } from '../utils/constants'
import { currentTeamState } from '../utils/state-atoms'
import { JsonEditor } from 'json-edit-react'
import SaveButton from '../components/buttons/SaveButton'

const defaultFilters: DataTableFilterMeta = {
  name: { value: null, matchMode: FilterMatchMode.CONTAINS },
  methodId: { value: null, matchMode: FilterMatchMode.EQUALS },
  status: { value: null, matchMode: FilterMatchMode.EQUALS }
}

const ProjectConfigs: FC = () => {
  const currentTeam = useRecoilValue(currentTeamState)
  const [mode, setMode] = useState('')
  const [{ loading, data: projectConfigData }, fetchProjectConfigs] = useGetProjectConfigs()
  const [{ loading: creating }, createProjectConfig] = useCreateProjectConfig()
  const [{ loading: updating }, updateProjectConfig] =
    useUpdateProjectConfig('')

  const [projectConfigs, setProjectConfigs] = useState<ProjectConfig[]>([])
  const [currentProjectConfig, setCurrentProjectConfig] = useState(DummyProjectConfig)
  const [currentJsonData, setCurrentJsonData] = useState({})
  const [lazyState, setlazyState] = useState<LazyTableState>({
    first: 0,
    rows: DefaultPageSize,
    page: 1,
    sortField: 'name',
    sortOrder: -1,
    filters: defaultFilters,
    sumlvl: 'vehicleStatesList:0,geometryList:0,projectConfigList:0,cfdSequenceList:0,method:0'
  })

  useEffect(() => {
    if (projectConfigData) {
      console.log(projectConfigData)
      setProjectConfigs(projectConfigData)
      const methodList = projectConfigData.protoBytesList.map((item: string) => {
        const result = protoService.deSerialize(item, 'projectconfig')
        return result
      })
      console.log(methodList)
      methodList && setProjectConfigs(methodList)
    }
  }, [projectConfigData])

  const makeQuery = (state) => {
    let query
    console.log(state)

    if (state.filters.name['value']) {
      const nameSearch = { $regex: state.filters.name['value'], $options: 'i' }
      const q = 'name=' + encodeURIComponent(JSON.stringify(nameSearch))
      query = query ? `${query}&${q}` : q
    }

    if (state.filters.methodId['value']) {
      const q = 'methodId=' + state.filters.methodId['value']
      query = query ? `${query}&${q}` : q
    }

    if (state.filters.status['value']) {
      const q = 'status=' + state.filters.status['value']
      query = query ? `${query}&${q}` : q
    }

    query = queryString2JsonString(query)
    query = query ? `query=${query}&limit=${state.rows}` : `limit=${state.rows}`
    query = `${query}&skip=${state.first}&countTotal=true`
    if (state.sortField) {
      const sort = {
        [state.sortField]: state.sortOrder
      }
      query = `${query}&sort=${JSON.stringify(sort)}`
    }

    return query
  }

  useEffect(() => {
    const query = makeQuery(lazyState)
    fetchProjectConfigs(query).catch((err) => {
      console.error(err)
      toast.error('Error loading project configs.')
    })
  }, [lazyState])

  const handleCreateNewProjectConfig = () => {
    if (mode === 'new') {
      return
    }
    setCurrentProjectConfig({
      configJSON: JSON.stringify(projectConfigTemplate, undefined, 8)
    } as ProjectConfig)

    setCurrentJsonData(projectConfigTemplate)

    setMode('new')
  }

  const handleCopyProjectConfig = (e) => {
    if (mode === 'new') {
      toast.error('Cannot duplicate while creating a new project config.')
      return
    }
    if (!currentProjectConfig._id) {
      toast.error('Please select a project config to duplicate.')
      return
    }
    setCurrentProjectConfig({
      configJSON: currentProjectConfig.configJSON
    } as ProjectConfig)

    const tmpJsonObj = JSON.parse(currentProjectConfig.configJSON)
    tmpJsonObj.id = ''
    setCurrentJsonData(tmpJsonObj)

    setMode('new')
  }

  const handleItemSelected = (config) => {
    setCurrentProjectConfig(config as ProjectConfig)

    const conf = config as ProjectConfig
    setCurrentJsonData(conf.configJSON ? JSON.parse(conf.configJSON) : {})

    setMode('edit')
  }

  const handleSaveProjectConfig = async () => {
    if (mode === 'new') {
      const newConfigData = {
        name: '',
        configJSON: currentJsonData ? JSON.stringify(currentJsonData) : '',
        raceTeamId: currentTeam
      }
      console.log('newConfigData=', newConfigData)
      const dataToPost = protoService.encodePayload(newConfigData, 'projectconfig')
      try {
        const res = await createProjectConfig({ data: dataToPost })
        if (res.data.protoBytesList && res.data.protoBytesList.length > 0) {
          const newMethod: ProjectConfig = protoService.deSerialize(
            res.data.protoBytesList[0],
            'projectconfig'
          )
          setProjectConfigs([...projectConfigs, newMethod])
          setCurrentProjectConfig(newMethod)
          setCurrentJsonData(JSON.parse(newMethod.configJSON))
          setMode('edit')
          toast.success('Project config created.')
        }
      } catch (err) {
        console.error(err)
        toast.error('Error creating project config.')
      }
    } else {
      if (!currentProjectConfig._id) {
        return
      }
      const newConfigData = {
        ...currentProjectConfig,
        configJSON: currentJsonData ? JSON.stringify(currentJsonData) : '',
      } as ProjectConfig
      console.log('newConfigData=', newConfigData)
      const dataToPost = protoService.encodePayload(newConfigData, 'projectconfig')
      try {
        await updateProjectConfig({
          url: `/ProjectConfigs/${currentProjectConfig._id}`,
          data: dataToPost
        })
        setProjectConfigs(
          projectConfigs.map((item) => {
            if (item._id === currentProjectConfig._id) {
              return newConfigData
            } else {
              return item
            }
          })
        )
        toast.success('Project config updated.')
      } catch (err) {
        console.error(err)
        toast.error('Error updating project config.')
      }
    }
  }

  return (
    <div className="basis-0 grow flex flex-col items-stretch mt-6 min-w-full space-y-4">
      <div className="flex items-center justify-between pb-4">
        <h1 className="page-title">Project Configurations</h1>
        <Button
          onClick={handleCreateNewProjectConfig}
          severity="secondary"
          className="icon-button"
          disabled={mode === 'new'}
          tooltip='Create New Project Config'
          tooltipOptions={{ position: 'bottom' }}
          data-pr-at='center+65 bottom'
        >
          <PlusIcon className="fill-white" />
        </Button>
      </div>
      <div className="flex items-center space-x-2">
        <Dropdown
          filter
          filterBy="name"
          filterMatchMode="contains"
          showFilterClear
          onFilter={(e) => {
            setlazyState({
              ...lazyState,
              filters: {
                ...lazyState.filters,
                name: { value: e.filter, matchMode: FilterMatchMode.CONTAINS }
              }
            })
          }}
          value={currentProjectConfig}
          emptyMessage="No Project Configs Found"
          pt={{
            root: {
              className: 'min-w-[30%] max-w-[50%]'
            },
            input: {
              className: 'py-1'
            }
          }}
          optionValue="_id"
          optionLabel="name"
          useOptionAsValue
          options={projectConfigs}
          valueTemplate={(option) => {
            let value
            if (mode === 'new') {
              value = 'New Project Config'
            } else if (option) {
              value = option.name
            } else {
              value = (currentProjectConfig && currentProjectConfig.name) || 'Select Project Config'
            }
            return <div>{value}</div>
          }}
          itemTemplate={(option) => <div>{option && option._id ? option.name : ''}</div>}
          onChange={(e) => handleItemSelected(e.value)}
          placeholder="Select Project Config"
        />
        <Button onClick={handleCopyProjectConfig} className="icon-button" tooltip='Copy Project Config' tooltipOptions={{ position: "bottom" }} data-pr-at='center+5 bottom'>
          <CopyIcon className="fill-white w-4 h-4" />
        </Button>
        <SaveButton onClick={handleSaveProjectConfig} loading={creating || updating} title="Save Project Config" />
      </div>
      <div className='flex-grow overflow-auto border rounded-md p-2 border-gray-400' style={{ maxHeight: 'calc(100vh - 315px)' }}>
        {(currentProjectConfig._id || mode === 'new') &&
          <JsonEditor minWidth={'100%'}
            rootName=''
            theme="githubLight"
            data={currentJsonData}
            setData={(data) => { setCurrentJsonData(data) }}
            enableClipboard={() => {
              toast.success('data copied to clipboard.')
            }}
          />}
      </div>
      <div className="h-0.5"></div>
    </div>
  )
}

export default ProjectConfigs
