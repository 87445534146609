import { classNames } from "primereact/utils";
import { Link } from "react-router-dom";
import { TriangleIcon } from "./Icons";

export type BreadcrumbItem = {
  title?: string;
  name: string;
  link?: string;
}

type BreadcrumbProps = {
  items: BreadcrumbItem[]
}

function Breadcrumb({
  items
}: BreadcrumbProps) {
  return (
    <div className="flex items-stretch space-x-6">
      {
        items.map((item, index) => {
          const textClass = classNames("text-2xl text-left font-semibold leading-none", { "text-gm-blue": item.title, "text-dark-blue": !item.title, })
          return (
            <>
              {index > 0 && <div className="flex items-center justify-center" key={item.title + '-sep'}>
                <TriangleIcon className="fill-black" />
              </div>}
              <div className="flex flex-col items-stretch space-y-0" key={item.title}>
                <div className={classNames('text-[0.6rem] text-neutral-10 font-medium', { 'opacity-0': !item.title })}>
                  {item.title || '-'}
                </div>
                {item.link &&
                  <Link className={textClass} to={item.link}>{item.name}</Link>
                }
                {!item.link &&
                  <div className={textClass}>{item.name}</div>
                }
              </div>
            </>
          )
        })
      }
    </div>
  )
}

export default Breadcrumb
