import format from 'date-fns/format'

export const formatDate = (date: Date) => {
  const langCode = navigator.language || document.documentElement.lang

  if (langCode === 'en-US') {
    return format(date, 'MM/dd/yyyy')
  } else {
    return format(date, 'dd/MM/yyyy')
  }
}

export const formatDateNumber = (date: number | string, formatStr?: string) => {
  if (!date) {
    return ''
  }
  const dt = new Date(date)
  const langCode = navigator.language || document.documentElement.lang

  if (langCode === 'en-US') {
    return format(dt, formatStr || 'MM/dd/yyyy')
  } else {
    return format(dt, formatStr || 'dd/MM/yyyy')
  }
}
