import { ProjectStatus, RunStatus } from '@aero-platform/shared/generated/AeroStructures'
import { ComponentProps, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'


interface StatusCircleProp extends ComponentProps<'div'> {
  children?: ReactNode
}

const StatusCircle = ({ children, className, ...props }: StatusCircleProp) => {
  return (
    <div
      className={twMerge("w-4 h-4 bg-slate-500 rounded-full mr-1.5", className)} {...props}>
      {children}
    </div>
  )
}

type RunStatusCircleProps = {
  status?: RunStatus
}

export const RunStatusCircle = (props: RunStatusCircleProps) => {
  switch (props.status) {
    case RunStatus.UNSPECIFIED:
    case RunStatus.CREATED:
    case RunStatus.WAITING:
      return (
        <StatusCircle className="bg-slate-500" />
      )
    case RunStatus.SUBMITTED:
    case RunStatus.PENDING:
      return (
        <StatusCircle className="bg-pending-run" />
      )
    case RunStatus.WARN_ON_ERROR:
      return (
        <StatusCircle className="bg-warning" />
      )
    case RunStatus.RUNNING: {
      return (
        <StatusCircle className="bg-status-closed" />
      )
    }
    case RunStatus.COMPLETED: {
      return (
        <StatusCircle className="bg-gm-blue" />
      )
    }
    case RunStatus.ERROR: {
      return (
        <StatusCircle className="bg-run-error" />
      )
    }
    default: {
      return (<StatusCircle />)
    }
  }
}

type ProjectStatusCircleProps = {
  status: ProjectStatus
}

export const ProjectStatusCircle = (props: ProjectStatusCircleProps) => {
  switch (props.status) {
    case ProjectStatus.ACTIVE: {
      return (
        <div className=" w-4 h-4 bg-status-in-progress rounded-full mr-1.5" />
      )
    }
    case ProjectStatus.ARCHIVED: {
      return (
        <div className=" w-4 h-4 bg-error rounded-full mr-1.5" />
      )
    }
    default: {
      return null
    }
  }
}
