import { useBreadcrumbNameUpdate, useBreadcrumbPathsPrint, useBreadcrumbRoutePathGet, useBreadcrumbUrlUpdate } from '../contexts/breadcrumbContexts/BreadcrumbContext'
import routes from '../routing/routes'
import { useGetProject, useGetProjects } from '../api/project-API'
import { useGetVehicle, useGetVehicles } from '../api/vehicle-API'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { protoService } from '../proto/ProtoService'
import { routeToProtoType } from '../utils/tree'
import { useGetSubmissions } from '../api/submission-API'
import toast from 'react-hot-toast'
import { RefetchFunction } from 'axios-hooks'
import { DependentFetch } from '../utils/dependent_fetches'
import { queryString2JsonString } from '../types/util'

export interface IAbsoluteRouteID {
    absoluteRoute: string,
    routeID: string
}

const deserializeProtobuf = (deserializeType: string, databaseData): any[] | null => {
    if (databaseData == null){
        return null
    }
    const protoBytesList: Array<string> = databaseData.protoBytesList
    const dataList: any[] = protoBytesList.map((item: string) => {
        const result = protoService.deSerialize(item, deserializeType)
        return result
    })

    return dataList
}

const ROUTE_TO_PARENT_KEY_ATTR = new Map<string, string>([
    [routes.Projects.absolutePath, "vehicleId"],
    [routes.Submission.absolutePath, "projectId"],

])

const FETCH_NAME_KEY: string = "name"

const useSetBreadcrumbs = (
    absoluteRoute: string,  // absolute routes from routes.tsx's IRoutes; assuming route is in any breadcrumb path only once
    urlId: string | undefined  // data id (ex: 66b614a93accca441f80dd65) or alt id (ex: CZL1-1LE_P001_R021)
) => {
    //note: data starts off as UNDEFINED; need to fetch() to get data filled
    const [{ data: allVehicles }, fetchVehicles] = useGetVehicles()
    const [{ data: oneVehicle }, fetchVehicleWithID] = useGetVehicle("")
    const [{ data: allProjects }, fetchProjects] = useGetProjects()
    const [{ data: oneProject }, fetchProjectWithID] = useGetProject()
    const [{ data: allSubmissions}, fetchSubmissions] = useGetSubmissions()

    const [areVehiclesFetched, setAreVehiclesFetched] = useState<Boolean>(false);
    const [areProjectsFetched, setAreProjectsFetched] = useState<Boolean>(false);
    const routeToIsFetched = new Map<string, Boolean>([
        [routes.Projects.absolutePath, areVehiclesFetched],
        [routes.EditProject.absolutePath, areProjectsFetched],

    ])
    const routeToSetisFetched = new Map<string, Dispatch<SetStateAction<Boolean>>>([
        [routes.Projects.absolutePath, setAreVehiclesFetched],
        [routes.EditProject.absolutePath, setAreProjectsFetched],

    ])
    const routeToQueryFetch = new Map<string, RefetchFunction<any, any>>([
        [routes.Projects.absolutePath, fetchVehicles],
        [routes.EditProject.absolutePath, fetchProjects],
    ])
    const routeToIDFetch = new Map<string, RefetchFunction<any, any>>([
        [routes.Projects.absolutePath, fetchVehicleWithID],
        [routes.EditProject.absolutePath, fetchProjectWithID],
    ])
    const dependentFetch = new DependentFetch(routeToIsFetched, routeToSetisFetched, routeToQueryFetch, routeToIDFetch)
    const loadQueryData = async (routePath: string, queryKey: string, queryValue: string) => {
        try {
            // await fetchVehicles("name=C2026")
            const query: string = 'query=' + queryString2JsonString(queryKey + '=' + queryValue)
            console.log("query:", query)
            await dependentFetch.fetchDataUsingQuery(routePath, query)
        } catch (err) {
            console.error(err)
            toast.error('Error loading data')
        }
    }
    const loadIDData = async (routePath: string, id: string) => {
        try {
            console.log("route:", routePath, "id:", id)
            await dependentFetch.fetchDataUsingID(routePath, id)
        } catch (err) {
            console.error(err)
            toast.error('Error loading data')
        }
    }
    const routeToDatabaseData = new Map<string, any>([
        [routes.Projects.absolutePath, allVehicles],
        [routes.EditProject.absolutePath, allProjects],
    ])

    const routeToDatabaseDataID = new Map<string, any>([
        [routes.Projects.absolutePath, oneVehicle],
        [routes.EditProject.absolutePath, oneProject],
    ])

    const getBreadcrumbRoutePath = useBreadcrumbRoutePathGet()
    const updateBreadcrumbName = useBreadcrumbNameUpdate()
    const updateBreadcrumbUrl = useBreadcrumbUrlUpdate()

    // Alternative ID: C2024_P004_R001
    // check if proj.name == urlID, then split based on "_"; else if proj.name is substr of url id, then use vehicle name to get id 
    // and use id in projects w/ project name, and use project id w/ run name.
    // idea: while loop with parent name or id and curr name, and traverse down the tree until no more name
    // T = O(N + M + Z) if have 3 levels in breadcrumbs
    // how to use 1 custom hook with loops/conditions  -> put everyting in 1 custom hook to create breadcrumbs
    // custom hook: if data exists in breadcrumb tree node, then dont fetch

    // ID: 12310423u0982
    // work from bottom up to populate breadcrumbs

    useEffect(() => {
        if (urlId === undefined){
            return
        }

        const routePath: string[] = getBreadcrumbRoutePath(absoluteRoute)  // get predefined route path to get to curr route
        if (routePath.length === 0){
            return
        }

        // if alternative ID: vehicleName_ProjectName_RunName
        if (urlId.includes("_")){

            let itemNames: string[] = urlId.split("_")
            // console.log("itemNames:", itemNames)
            for (let i = 0; i < routePath.length; i++) {  // iter path from ordered names absolute route
                const itemName: string = itemNames[i]
                const absRoute: string = routePath[i]

                // console.log("updateBreadcrumbName(absRoute, itemName):", absRoute, itemName)
                
                updateBreadcrumbName(absRoute, itemName)

                // Set the breadcrumb url for respective route
                if (absRoute.includes(":id")){
                    //vehicle breadcrumb -> project url w/ ?vehicleId=${currentProject?.vehicleId}
                    //projects breadcrumb -> editprojectURL
                    const url: string = absRoute.replace(":id", itemNames.slice(0, i +1).join("_"))
                    updateBreadcrumbUrl(absRoute, url)
                }else if (absRoute === routes.Projects.absolutePath){
                    // iter in vehicle list and find ._id === .name
                    const databaseData = routeToDatabaseData.get(absRoute)  // get respective route's database data
                    if (databaseData === undefined){
                        // fetch the data if havent fetched yet
                        loadQueryData(absRoute, FETCH_NAME_KEY, itemName)
                        return
                    }

                    const deserializeType: string | undefined = routeToProtoType.get(absRoute)
                    if (deserializeType === undefined){
                        console.error(`const "absoluteRoute" ${absRoute} is not setup for proto deserialization! Goto ../utils/tree to setup!`)
                        return
                    }

                    const dataList: any[] | null = deserializeProtobuf(deserializeType, databaseData)
                    if (dataList == null){
                        return
                    }
                    let url: string = "" 
                    for (let data of dataList){
                        if (data.name === itemName){
                            url = routes.Projects.absolutePath.concat(`?vehicleId=${data._id}`)
                            break
                        }
                    }

                    updateBreadcrumbUrl(absRoute, url)
                }else{
                    console.error(`CANNOT HANDLE Breadcrumb URL for absolute route "${absRoute}"`)
                    return
                }

            }
            
        }else{
            let traverseID: string = urlId
            for (let i = routePath.length - 1; i >= 0; i--) {  // iter path from ordered names absolute route
                // if using 64 bit id, then work bottom up setting breadcrumbs
                const absRoute: string = routePath[i]

                const databaseData = routeToDatabaseDataID.get(absRoute)  // get respective route's database data
                if (databaseData === undefined){
                    // fetch the data if havent fetched yet
                    loadIDData(absRoute, traverseID)
                    // return so data is loaded in variable after fetching
                    return
                }
                const deserializeType: string | undefined = routeToProtoType.get(absRoute)
                if (deserializeType === undefined){
                    console.error(`const "absoluteRoute" ${absRoute} is not setup for proto deserialization! Goto ../utils/tree to setup!`)
                    return
                }

                const dataList: any[] | null = deserializeProtobuf(deserializeType, databaseData)
                if (dataList == null){
                    return
                }

                let item = undefined
                for (let element of dataList) {
                    if (element._id === traverseID){
                        // console.log("64 bit absRoute:", absRoute, "| element.name:", element.name)
                        updateBreadcrumbName(absRoute, element.name)

                        if (absRoute.includes(":id")){
                            const url = absRoute.replace(":id", traverseID)
                            updateBreadcrumbUrl(absRoute, url)
                        }else if (absRoute === routes.Projects.absolutePath){
                            const url = absRoute.concat(`?vehicleId=${traverseID}`)
                            updateBreadcrumbUrl(absRoute, url)
                        }else{
                            console.error(`CANNOT HANDLE Breadcrumb URL for absolute route "${absRoute}"`)
                            return
                        }
                        item = element
                                                
                        return
                    }
                };  
                if (item === undefined){
                    return
                }
                const parentKeyAttr: string | undefined = ROUTE_TO_PARENT_KEY_ATTR.get(absRoute)
                if (parentKeyAttr === undefined){
                    return
                }
                const parentID: string = item[parentKeyAttr]
                traverseID = parentID
            }

        }
    }, [urlId, allVehicles, allProjects, oneVehicle, oneProject])

    const printBreadcrumb = useBreadcrumbPathsPrint()
    printBreadcrumb()

    return 
}

export default useSetBreadcrumbs