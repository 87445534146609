import { FilterMatchMode, FilterService } from 'primereact/api'
import { Column } from 'primereact/column'
import {
  DataTable,
  DataTableFilterMeta
} from 'primereact/datatable'
import { FC, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useRecoilState } from 'recoil'
import { useGetVehicles } from '../api/vehicle-API'
import VehicleModal from '../components/project/VehicleModal'
import { LazyTableState, Vehicle } from '../types/types'
import { DummyVehicle } from '../utils/constants'
import { vehicleListState } from '../utils/state-atoms'

import 'primeicons/primeicons.css'
import { Button } from 'primereact/button'
import 'primereact/resources/primereact.css'
import { Link } from 'react-router-dom'
import { EditIcon, PlusIcon } from '../components/Icons'
import { protoService } from '../proto/ProtoService'
import { queryString2JsonString } from '../types/util'
import { InputText } from 'primereact/inputtext'

const defaultFilters: DataTableFilterMeta = {
  name: { value: null, matchMode: FilterMatchMode.CONTAINS },
  vehicleId: { value: null, matchMode: FilterMatchMode.CONTAINS }
}

// The rule argument should be a string in the format "custom_[field]".
FilterService.register('custom_vehicleId', (value, filters) => {
  return value && value.toString().includes(filters)
});

const columns = [
  {
    header: 'ID',
    field: 'vehicleId',
    filter: true,
    headerStyle: {},
    headerClassName: 'w-[200px]',
    bodyStyle: {},
    bodyClassName: 'max-w-[200px] id-cell',
    ptHeaderCell: undefined,
    ptBodyCell: undefined,
    sortable: true,
    body: (rowData: Vehicle) => {
      return (
        <Link to={'/projects?vehicleId=' + rowData._id}
          state={[{ title: "Car", name: rowData.vehicleId, url: "" }]}>
          {rowData.vehicleId}
        </Link>
      )
    },
    filterElement: (options) => {
      return (
        <div className="px-1 py-2">
          <InputText value={options.value} onChange={(e) => {
            console.log('FilterElement', e.target.value)
            options.filterApplyCallback(e.target.value)
          }} className="w-full" />
        </div>
      )
    }
  },
  {
    header: 'Description',
    field: 'description',
    headerStyle: {},
    headerClassName: 'py-4 px-1',
    bodyStyle: {},
    bodyClassName: '!p-1',
    ptHeaderCell: undefined,
    ptBodyCell: undefined
  }
]


const Vehicles: FC = () => {
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    vehicleId: { value: null, matchMode: FilterMatchMode.CUSTOM },
  });
  const [showEditorModal, setShowEditorModal] = useState(false)
  const [mode, setMode] = useState('')
  const [initVehicle, setInitVehicle] = useState(DummyVehicle)
  const [{ loading, data: vehicleData }, fetchVehicles] = useGetVehicles()
  const [vehicles, setVehicles] = useRecoilState<Vehicle[]>(vehicleListState)
  const [totalRecords, setTotalRecords] = useState<number>(10)
  const [lazyState, setLazyState] = useState<LazyTableState>({
    first: 0,
    rows: 0,
    page: 1,
    sortField: 'timeCreated',
    sortOrder: -1,
    filters: defaultFilters,
    sumlvl: 'vehicleStatesList:0,vehicleList:0,tyreSetList:0,cfdSequenceList:0,method:0'
  })

  useEffect(() => {
    const loadData = async () => {
      try {
        await fetchVehicles()
      } catch (err) {
        console.error(err)
        toast.error('Error loading data')
      }
    }
    // loadData()
  }, [])

  useEffect(() => {
    vehicleData && setVehicles(vehicleData)

    const vehicleList =
      vehicleData &&
      vehicleData.protoBytesList.map((item: string) => {
        const result = protoService.deSerialize(item, 'vehicle')
        return result
      })
    vehicleList && setVehicles(vehicleList)
    if (vehicleData && vehicleData.resultString) {
      const result = JSON.parse(vehicleData.resultString)
      setTotalRecords(result.totalCount)
    }
  }, [vehicleData])

  const handleCreateNewVehicle = () => {
    setMode('new')
    setInitVehicle(DummyVehicle)
    setShowEditorModal(true)
  }

  const handleEditVehicle = (vehicle: Vehicle) => {
    console.log(vehicle)
    setInitVehicle(vehicle)
    setMode('edit')
    setShowEditorModal(true)
  }

  const makeQuery = (state) => {
    let query
    console.log("Vehicle state:", state)

    if (state.filters.name['value']) {
      const nameSearch = { $regex: state.filters.name['value'], $options: 'i' }
      const q = 'name=' + encodeURIComponent(JSON.stringify(nameSearch))
      query = query ? `${query}&${q}` : q
    }

    if (state.filters.vehicleId['value']) {
      const q = 'vehicleId=' + state.filters.vehicleId['value']
      query = query ? `${query}&${q}` : q
    }

    query = queryString2JsonString(query)
    query = query ? `query=${query}&limit=${state.rows}` : `limit=${state.rows}`
    query = `${query}&skip=0&countTotal=true`
    if (state.sortField) {
      const sort = {
        [state.sortField]: state.sortOrder
      }
      query = `${query}&sort=${JSON.stringify(sort)}`
    }

    return query
  }

  useEffect(() => {
    const query = makeQuery(lazyState)
    fetchVehicles(query).catch((err) => {
      console.error(err)
      toast.error('Error loading vehicles.')
    })
  }, [lazyState])

  const actionColumnTemplate = (rowData: Vehicle) => {
    return (
      <div>
        <Button
          severity="secondary"
          onClick={() => handleEditVehicle(rowData)}
          className="icon-button"
          tooltip='Edit Car'
          tooltipOptions={{ position: 'bottom' }}
          data-pr-at='center+15 bottom'
        >
          <EditIcon className="h-4 w-4 fill-white stroke-transparent" />
        </Button>
      </div>
    )
  }


  return (
    <div className="basis-0 grow flex flex-col items-stretch mt-6 min-w-full">
      <div className="flex items-center justify-between pb-4">
        <h1 className="page-title">Car List</h1>
        <Button onClick={handleCreateNewVehicle} severity="secondary" className="icon-button" tooltip='Create New Vehicle' tooltipOptions={{ position: "bottom" }} data-pr-at='center+40 bottom'>
          <PlusIcon className="fill-white" />
        </Button>
      </div>
      {vehicles && (
        <DataTable
          value={vehicles}
          loading={loading}
          dataKey="_id"
          filterDisplay="row"
          stripedRows
          sortField='vehicleId'
          pt={{
            bodyRow: {
              className: 'table-row'
            },
          }}>
          {columns.map((item) => {
            return (
              <Column
                key={item.field}
                field={item.field}
                header={item.header}
                body={item.body}
                filter={item.filter}
                filterField={item.field}
                filterElement={item.filterElement}
                showFilterMenu={false}
                sortable={item.sortable}
                headerStyle={item.headerStyle}
                headerClassName={item.headerClassName}
                bodyStyle={item.bodyStyle}
                bodyClassName={item.bodyClassName}></Column>
            )
          })}
          <Column
            body={actionColumnTemplate}
            headerClassName="one-col-editor-header editor-header"
            bodyStyle={{ textAlign: 'center', padding: '12px 8px' }}></Column>
        </DataTable>
      )}

      <div className="flex items-center space-x-4 justify-end my-4"></div>

      {showEditorModal && (
        <VehicleModal
          initVehicle={initVehicle}
          mode={mode}
          onCancel={() => setShowEditorModal(false)}
          onConfirm={(vehicle: Vehicle) => {
            setShowEditorModal(false)
          }}
        />
      )}
    </div>
  )
}

export default Vehicles
