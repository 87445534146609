import { FilterMatchMode } from 'primereact/api'
import { Button } from 'primereact/button'
import { DataTableFilterMeta } from 'primereact/datatable'
import { Dropdown } from 'primereact/dropdown'
import { FC, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useRecoilValue } from 'recoil'
import {
  useCreateWorkflowTask,
  useGetWorkflowTasks,
  useUpdateWorkflowTask
} from '../api/workflow-task-API'
import { CopyIcon, PlusIcon, SaveIcon } from '../components/Icons'
import { protoService } from '../proto/ProtoService'
import { LazyTableState, WorkflowTask } from '../types/types'
import { queryString2JsonString } from '../types/util'
import { DefaultPageSize, DummyWorkflowTask, methodTemplate } from '../utils/constants'
import { currentTeamState } from '../utils/state-atoms'
import { JsonEditor } from 'json-edit-react'
import SaveButton from '../components/buttons/SaveButton'

const defaultFilters: DataTableFilterMeta = {
  name: { value: null, matchMode: FilterMatchMode.CONTAINS },
  methodId: { value: null, matchMode: FilterMatchMode.EQUALS },
  status: { value: null, matchMode: FilterMatchMode.EQUALS }
}

const WorkflowTasks: FC = () => {
  const currentTeam = useRecoilValue(currentTeamState)
  const [mode, setMode] = useState('')
  const [{ loading, data: workflowTaskData }, fetchWorkflowTasks] = useGetWorkflowTasks()
  const [{ loading: creating }, createWorkflowTask] = useCreateWorkflowTask()
  const [{ loading: updating }, updateWorkflowTask] = useUpdateWorkflowTask('')

  const [workflowTasks, setWorkflowTasks] = useState<WorkflowTask[]>([])
  const [currentWorkflowTask, setCurrentWorkflowTask] = useState(DummyWorkflowTask)
  const [currentJsonData, setCurrentJsonData] = useState({})
  const [lazyState, setlazyState] = useState<LazyTableState>({
    first: 0,
    rows: DefaultPageSize,
    page: 1,
    sortField: 'name',
    sortOrder: -1,
    filters: defaultFilters,
    sumlvl: 'vehicleStatesList:0,geometryList:0,tyreSetList:0,cfdSequenceList:0,method:0'
  })

  useEffect(() => {
    if (workflowTaskData) {
      console.log(workflowTaskData)
      setWorkflowTasks(workflowTaskData)
      const methodList = workflowTaskData.protoBytesList.map((item: string) => {
        const result = protoService.deSerialize(item, 'workflowtask')
        return result
      })
      console.log(methodList)
      methodList && setWorkflowTasks(methodList)
    }
  }, [workflowTaskData])

  const makeQuery = (state) => {
    let query
    console.log(state)

    if (state.filters.name['value']) {
      const nameSearch = { $regex: state.filters.name['value'], $options: 'i' }
      const q = 'name=' + encodeURIComponent(JSON.stringify(nameSearch))
      query = query ? `${query}&${q}` : q
    }

    if (state.filters.methodId['value']) {
      const q = 'methodId=' + state.filters.methodId['value']
      query = query ? `${query}&${q}` : q
    }

    if (state.filters.status['value']) {
      const q = 'status=' + state.filters.status['value']
      query = query ? `${query}&${q}` : q
    }

    query = queryString2JsonString(query)
    query = query ? `query=${query}&limit=${state.rows}` : `limit=${state.rows}`
    query = `${query}&skip=${state.first}&countTotal=true`
    if (state.sortField) {
      const sort = {
        [state.sortField]: state.sortOrder
      }
      query = `${query}&sort=${JSON.stringify(sort)}`
    }

    return query
  }

  useEffect(() => {
    const query = makeQuery(lazyState)
    fetchWorkflowTasks(query).catch((err) => {
      console.error(err)
      toast.error('Error loading workflowTasks.')
    })
  }, [lazyState])

  const handleCreateNewWorkflowTask = () => {
    if (mode === 'new') {
      return
    }
    setCurrentWorkflowTask({
      configJSON: JSON.stringify(methodTemplate, undefined, 8)
    } as WorkflowTask)

    setCurrentJsonData(methodTemplate)
    setMode('new')
  }

  const handleCopyWorkflowTask = (e) => {
    if (mode === 'new') {
      toast.error('Cannot duplicate while creating a new workflowTask.')
      return
    }
    if (!currentWorkflowTask._id) {
      toast.error('Please select a workflowTask to duplicate.')
      return
    }
    setCurrentWorkflowTask({
      configJSON: currentWorkflowTask.configJSON
    } as WorkflowTask)

    console.log(currentWorkflowTask)
    const tmpJsonObj = JSON.parse(currentWorkflowTask.configJSON)
    tmpJsonObj.id = ''
    setCurrentJsonData(tmpJsonObj)

    setMode('new')
  }

  const handleMethodSelected = (method) => {
    console.log(method)
    setCurrentWorkflowTask({ ...method } as WorkflowTask)
    try {
      setCurrentJsonData(JSON.parse(method.configJSON))
    } catch (err) {
      console.error(err)
      console.log(method.configJSON)
      toast.error('Error parsing JSON data.')
    }
    setMode('edit')
  }

  const handleSaveWorkflowTask = async () => {
    if (mode === 'new') {
      const newMethodData = {
        name: '',
        configJSON: currentJsonData ? JSON.stringify(currentJsonData) : '',
        raceTeamId: currentTeam
      }
      console.log('newMethodData=', newMethodData)
      const dataToPost = protoService.encodePayload(newMethodData, 'workflowtask')
      try {
        const res = await createWorkflowTask({ data: dataToPost })
        if (res.data.protoBytesList && res.data.protoBytesList.length > 0) {
          const newMethod: WorkflowTask = protoService.deSerialize(
            res.data.protoBytesList[0],
            'workflowtask'
          )
          setWorkflowTasks([...workflowTasks, newMethod])
          setCurrentWorkflowTask(newMethod)
          setCurrentJsonData(JSON.parse(newMethod.configJSON))
          // setSelectedWorkflowTaskId(newMethod._id)
          toast.success('WorkflowTask created.')
          setMode('edit')
        }
      } catch (err) {
        console.error(err)
        toast.error('Error creating workflowTask.')
      }
    } else {
      if (!currentWorkflowTask._id) {
        return
      }
      const newMethodData = {
        ...currentWorkflowTask,
        configJSON: currentJsonData ? JSON.stringify(currentJsonData) : '',
      } as WorkflowTask
      console.log('newMethodData=', newMethodData)
      const dataToPost = protoService.encodePayload(newMethodData, 'workflowtask')
      try {
        await updateWorkflowTask({
          url: `/WorkflowTasks/${currentWorkflowTask._id}`,
          data: dataToPost
        })
        setWorkflowTasks(
          workflowTasks.map((item) => {
            if (item._id === currentWorkflowTask._id) {
              return newMethodData
            } else {
              return item
            }
          })
        )
        toast.success('WorkflowTask updated.')
      } catch (err) {
        console.error(err)
        toast.error('Error updating workflowTask.')
      }
    }
  }

  return (
    <div className="flex flex-col items-stretch mt-6 min-w-full">
      <div className="flex items-center justify-between">
        <h1 className="text-lg mt-11">Workflow Tasks</h1>
      </div>
      <div className="flex items-center justify-between space-x-2">
        <Dropdown
          filter
          filterBy="name"
          filterMatchMode="contains"
          showFilterClear
          onFilter={(e) => {
            setlazyState({
              ...lazyState,
              filters: {
                ...lazyState.filters,
                name: { value: e.filter, matchMode: FilterMatchMode.CONTAINS }
              }
            })
          }}
          value={currentWorkflowTask}
          emptyMessage="No Workflow Tasks Found"
          pt={{
            root: {
              className: 'min-w-[50%] max-w-[50%]'
            },
            input: {
              className: 'py-1'
            }
          }}
          optionValue="_id"
          optionLabel="name"
          useOptionAsValue
          options={workflowTasks}
          valueTemplate={(option) => {
            let value
            if (mode === 'new') {
              value = 'New Workflow Task'
            } else if (option) {
              value = option.name
            } else {
              value = (currentWorkflowTask && currentWorkflowTask.name) || 'Select workflow task'
            }
            return <div>{value}</div>
          }}
          itemTemplate={(option) => <div>{option && option._id ? option.name : ''}</div>}
          onChange={(e) => handleMethodSelected(e.value)}
          placeholder="Select Workflow Task"
        />
        <div className='flex items-center justify-end space-x-2'>
          <Button onClick={handleCopyWorkflowTask} className="icon-button" tooltip='Duplicate Workflow Task' tooltipOptions={{ position: 'bottom' }} data-pr-at='center+15 bottom' disabled={mode === 'new'}>
            <CopyIcon className="fill-white stroke-white w-4 h-4" />
          </Button>
          <SaveButton onClick={handleSaveWorkflowTask} loading={creating || updating} title="Save Workflow Task" dataPrAt='center+15 bottom' />
          <Button
            onClick={handleCreateNewWorkflowTask}
            severity="secondary"
            className="icon-button"
            disabled={mode === 'new'}
            tooltip='Create New Workflow Task'
            tooltipOptions={{ position: 'bottom' }}
            data-pr-at='center+63 bottom'
          >
            <PlusIcon className="fill-white" />
          </Button>
        </div>

      </div>
      <div className='mt-4 flex-grow overflow-auto border rounded-md p-2 border-gray-400' style={{ height: 'calc(100vh - 410px)' }}>
        {(currentWorkflowTask._id || mode === 'new') &&
          <JsonEditor minWidth={'100%'}
            rootName=''
            theme="githubLight"
            data={currentJsonData}
            setData={(data) => {
              console.log(data)
              setCurrentJsonData(data)
            }}
            enableClipboard={() => {
              toast.success('data copied to clipboard.')
            }}
          />}
      </div>
    </div >
  )
}

export default WorkflowTasks
