import {
  ColumnPinningState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortDirection,
  SortingState,
  useReactTable
} from '@tanstack/react-table'
import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox'
import { DataTableFilterMeta, DataTablePageEvent, DataTableSortEvent } from 'primereact/datatable'
import { Dropdown } from 'primereact/dropdown'
import { CSSProperties, FC, useEffect, useReducer, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router'
import { TableVirtuoso } from 'react-virtuoso'
import { useRecoilValue } from 'recoil'
import { useGetProjects } from '../api/project-API'
import { protoService } from '../proto/ProtoService'
import { LazyTableState, Project, SelectOption } from '../types/types'

import { queryString2JsonString } from '../types/util'
import { CurrentUserKey, DefaultPageSize } from '../utils/constants'
import { currentUserState, geometryListState } from '../utils/state-atoms'

const tmpData = []
for (let i = 0; i < 100000; i++) {
  const row = { desc: 'row' + i + ' - ' + Math.random() }
  for (let j = 0; j < 50; j++) {
    row['col' + j] = Math.random().toFixed(3)
  }
  tmpData.push(row as never)
}

/*
const columns: any[] = []
for (let j = 0; j < 50; j++) {
  columns.push({
    size: 80,
    header: 'Col' + j, // can be a func
    accessorKey: 'col' + j,
    // accessorFn: (row) => row.lastName,
    cell: (info) => {
      return info.getValue()
    }
  })
}
*/

const columns: any[] = [
  {
    header: 'RUN',
    accessorKey: 'RUN',
    cell: (info) => {
      return info.getValue()
    }
  },
  {
    header: 'REF',
    accessorKey: 'ref',
    cell: (info) => {
      return info.getValue()
    }
  },
  {
    header: 'VS',
    accessorKey: 'vs',
    cell: (info) => {
      return info.getValue()
    }
  },
  {
    header: 'AHF',
    accessorKey: 'ahf',
    cell: (info) => {
      return info.getValue()
    }
  },
  {
    header: 'AHR',
    accessorKey: 'ahr',
    cell: (info) => {
      return info.getValue()
    }
  },
  {
    header: 'Steer',
    accessorKey: 'steer',
    cell: (info) => {
      return info.getValue()
    }
  },
  {
    header: 'Roll',
    accessorKey: 'roll',
    cell: (info) => {
      return info.getValue()
    }
  },
  {
    header: 'Yaw',
    accessorKey: 'yaw',
    cell: (info) => {
      return info.getValue()
    }
  },
  {
    header: 'CR',
    accessorKey: 'cr',
    cell: (info) => {
      return info.getValue()
    }
  },
  {
    header: 'FF',
    accessorKey: 'ff',
    cell: (info) => {
      return info.getValue()
    }
  },
  {
    header: 'RF',
    accessorKey: 'rf',
    cell: (info) => {
      return info.getValue()
    }
  },
  {
    header: 'Spd',
    accessorKey: 'speed',
    cell: (info) => {
      return info.getValue()
    }
  },
  {
    header: 'ΔCL',
    accessorKey: 'deltaCL',
    cell: (info) => {
      return info.getValue()
    }
  },
  {
    header: 'ΔCLF',
    accessorKey: 'deltaCLF',
    cell: (info) => {
      return info.getValue()
    }
  },
  {
    header: 'ΔCLR',
    accessorKey: 'deltaCLR',
    cell: (info) => {
      return info.getValue()
    }
  },
  {
    header: 'ΔCD',
    accessorKey: 'deltaCD',
    cell: (info) => {
      return info.getValue()
    }
  },
  {
    header: 'Δ%CLF',
    accessorKey: 'deltaPercentCLF',
    cell: (info) => {
      return info.getValue()
    }
  },
  {
    header: 'ΔFWCL',
    accessorKey: 'deltaFWCL',
    cell: (info) => {
      return info.getValue()
    }
  },
  {
    header: 'ΔRWCL',
    accessorKey: 'deltaRWCL',
    cell: (info) => {
      return info.getValue()
    }
  },
  {
    header: 'CL',
    accessorKey: 'cl',
    cell: (info) => {
      return info.getValue()
    }
  },
  {
    header: 'CD',
    accessorKey: 'cd',
    cell: (info) => {
      return info.getValue()
    }
  },
  {
    header: 'CLR',
    accessorKey: 'clr',
    cell: (info) => {
      return info.getValue()
    }
  },
  {
    header: 'CLF',
    accessorKey: 'clf',
    cell: (info) => {
      return info.getValue()
    }
  },
  {
    header: '%CLF',
    accessorKey: 'percentclf',
    cell: (info) => {
      return info.getValue()
    }
  },
  {
    header: 'FWCL',
    accessorKey: 'fwcl',
    cell: (info) => {
      return info.getValue()
    }
  },
  {
    header: 'FWCD',
    accessorKey: 'fwcd',
    cell: (info) => {
      return info.getValue()
    }
  },
  {
    header: 'RWCL',
    accessorKey: 'rwcl',
    cell: (info) => {
      return info.getValue()
    }
  },
  {
    header: 'RWCD',
    accessorKey: 'rwcd',
    cell: (info) => {
      return info.getValue()
    }
  },
  {
    header: 'LS',
    accessorKey: 'ls',
    cell: (info) => {
      return info.getValue()
    }
  },
  {
    header: 'RS',
    accessorKey: 'rs',
    cell: (info) => {
      return info.getValue()
    }
  },
  {
    header: 'CTR',
    accessorKey: 'ctr',
    cell: (info) => {
      return info.getValue()
    }
  },
  {
    header: 'Total',
    accessorKey: 'total',
    cell: (info) => {
      return info.getValue()
    }
  },
  {
    header: 'FL_Cn_Usg_CL',
    accessorKey: 'fl_cn_usg_cl',
    cell: (info) => {
      return info.getValue()
    }
  },
  {
    header: 'FR_Cn_Usg_CL',
    accessorKey: 'fr_cn_usg_cl',
    cell: (info) => {
      return info.getValue()
    }
  },
  {
    header: 'RL_Cn_Usg_CL',
    accessorKey: 'rl_cn_usg_cl',
    cell: (info) => {
      return info.getValue()
    }
  },
  {
    header: 'RR_Cn_Usg_CL',
    accessorKey: 'rr_cn_usg_CL',
    cell: (info) => {
      return info.getValue()
    }
  },
  {
    header: 'FL_Cn_Usg_NR_CL',
    accessorKey: 'fl_cn_usg_nr_cl',
    cell: (info) => {
      return info.getValue()
    }
  },
  {
    header: 'FR_Cn_Usg_NR_CL',
    accessorKey: 'fr_cn_usg_nr_cl',
    cell: (info) => {
      return info.getValue()
    }
  },
  {
    header: 'RL_Cn_Usg_NR_CL',
    accessorKey: 'rl_cn_usg_nr_cl',
    cell: (info) => {
      return info.getValue()
    }
  },
  {
    header: 'RR_Cn_Usg_NR_CL',
    accessorKey: 'rr_cn_usg_nr_cl',
    cell: (info) => {
      return info.getValue()
    }
  }
]

const refColumns: any[] = [
  {
    size: 80,
    header: 'Baseline', // can be a func
    accessorKey: 'label',
    // accessorFn: (row) => row.lastName,
    cell: (info) => {
      return info.getValue()
    }
  }
]

/*
const columns = [
  {
    header: 'Project',
    field: 'name',
    sortable: true,
    headerStyle: {},
    headerClassName: 'py-2 px-1',
    bodyStyle: {},
    bodyClassName: '!p-1',
    ptHeaderCell: undefined,
    ptBodyCell: undefined
  },
  {
    header: 'ID',
    field: 'projectId',
    sortable: true,
    headerStyle: {},
    headerClassName: 'py-2 px-1',
    bodyStyle: {},
    bodyClassName: '!p-1',
    ptHeaderCell: undefined,
    ptBodyCell: undefined
  },
  {
    header: 'Area',
    field: 'area',
    sortable: true,
    headerStyle: {},
    headerClassName: 'py-2 px-1',
    bodyStyle: {},
    bodyClassName: '!p-1',
    ptHeaderCell: undefined,
    ptBodyCell: undefined
  },
  {
    header: 'Responsible Engineer',
    field: 'engineer',
    sortable: true,
    body: (rowData: Project) => {
      return ``
    },
    headerStyle: {},
    headerClassName: 'py-2 px-1',
    bodyStyle: {},
    bodyClassName: '!p-1',
    ptHeaderCell: undefined,
    ptBodyCell: undefined
  },
  {
    header: 'Start Date',
    field: 'startDate',
    sortable: true,
    body: (rowData: Project) => {
      return formatDateNumber(rowData.stDate?.getTime() as number)
    },
    headerStyle: {},
    headerClassName: 'py-2 px-1',
    bodyStyle: {},
    bodyClassName: '!p-1 min-w-[120px]',
    ptHeaderCell: undefined,
    ptBodyCell: undefined
  },
  {
    header: 'Created by',
    field: 'createByUserName',
    sortable: true,
    headerStyle: {},
    headerClassName: 'py-2 px-1',
    bodyStyle: {},
    bodyClassName: '!p-1',
    ptHeaderCell: undefined,
    ptBodyCell: undefined
  },
  {
    header: 'Status',
    field: 'status',
    sortable: true,
    body: (rowData: Project) => {
      return <ProjectStatusCell status={rowData.status} />
    },
    headerStyle: {},
    headerClassName: 'py-2 px-1',
    bodyStyle: {},
    bodyClassName: '!p-1 min-w-[120px]',
    ptHeaderCell: undefined,
    ptBodyCell: undefined
  }
]
*/

const PROJECT_BY_ME_ONLY_KEY = 'PROJECT_BY_ME_ONLY'

const defaultFilters: DataTableFilterMeta = {
  vehicle: { value: undefined, matchMode: 'equals' },
  status: { value: undefined, matchMode: 'equals' },
  engineer: { value: undefined, matchMode: 'contains' },
  startDate: { value: undefined, matchMode: 'dateAfter' },
  endDate: { value: undefined, matchMode: 'dateBefore' },
  area: { value: undefined, matchMode: 'equals' },
  byMeOnly: { value: false, matchMode: 'equals' }
}

const refs: SelectOption[] = []
for (let i = 0; i < 1000; i++) {
  refs.push({
    label: i.toString(),
    value: i.toString()
  } as SelectOption)
}

const getCommonPinningStyles = (column): CSSProperties => {
  const isPinned = column.getIsPinned()
  const isLastLeftPinnedColumn = isPinned === 'left' && column.getIsLastColumn('left')
  const isFirstRightPinnedColumn = isPinned === 'right' && column.getIsFirstColumn('right')

  return {
    boxShadow: isLastLeftPinnedColumn
      ? '-4px 0 4px -4px gray inset'
      : isFirstRightPinnedColumn
        ? '4px 0 4px -4px gray inset'
        : undefined,
    left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
    right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
    opacity: isPinned ? 0.95 : 1,
    position: isPinned ? 'sticky' : 'relative',
    width: column.getSize(),
    zIndex: isPinned ? 1 : 0
  }
}

const DataOverview: FC = () => {
  const [{ data: projData, loading: loadingProjects, error }, fetchProjects] = useGetProjects()

  const currentUser = useRecoilValue(currentUserState)
  const [selectedProjectId, setSelectedProjectId] = useState('')
  const [selectedGeometryId, setSelectedGeometryId] = useState('')
  const [selectedVSId, setSelectedVSId] = useState('')

  const [totalRecords, setTotalRecords] = useState<number>(10)
  const [projectList, setProjectList] = useState<Project[]>([])
  const geometryList = useRecoilValue(geometryListState)
  //const vsList = useRecoilValue(vehicleStateListState)
  const vsList = []

  const [selectedRef, setSelectedRef] = useState<SelectOption>({} as SelectOption)
  const [refList, setRefList] = useState<SelectOption[]>(refs)
  const [dataList, setDataList] = useState(tmpData)
  const rerender = useReducer(() => ({}), {})[1]

  const [sorting, setSorting] = useState<SortingState>([])
  const [columnPinning, setColumnPinning] = useState<ColumnPinningState>({
    left: ['col0']
  })

  const handleBaseline = (evt) => {
    console.log(evt)
  }

  const dataTable = useReactTable({
    data: tmpData,
    columns,
    initialState: {
      columnPinning: {
        left: ['Col0', 'col0']
      }
    },
    state: {
      sorting,
      columnPinning
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableColumnPinning: true,
    debugTable: true,
    debugHeaders: true,
    debugColumns: true
  })

  const { rows: dataRows } = dataTable.getRowModel()

  const refTable = useReactTable<SelectOption>({
    data: refList,
    columns: refColumns,
    getCoreRowModel: getCoreRowModel()
  })

  const { rows: refRows } = refTable.getRowModel()

  const byMeSaved = localStorage.getItem(PROJECT_BY_ME_ONLY_KEY)

  const [lazyState, setlazyState] = useState<LazyTableState>({
    first: 0,
    rows: DefaultPageSize,
    page: 1,
    sortField: 'name',
    sortOrder: -1,
    filters: {
      ...defaultFilters,
      byMeOnly: { matchMode: 'equals', value: byMeSaved === 'true' || byMeSaved === null }
    },
    sumlvl: 'vehicleStatesList:0,geometryList:0,tyreSetList:0,cfdSequenceList:0,method:0'
  })

  useEffect(() => {
    let query
    if (lazyState.filters.vehicle['value']) {
      const q = 'vehicleId=' + lazyState.filters.vehicle['value']
      query = query ? `${query}&${q}` : q
    }

    const userStr = localStorage.getItem(CurrentUserKey)
    if (userStr && lazyState.filters.byMeOnly['value']) {
      const user = JSON.parse(userStr)
      const q = 'createByUser=' + user.cloudProviderLDAPRef
      query = query ? `${query}&${q}` : q
    }

    if (lazyState.filters.status['value']) {
      const q = 'status=' + lazyState.filters.status['value']
      query = query ? `${query}&${q}` : q
    }

    if (lazyState.filters.area['value']) {
      const q = 'area=' + lazyState.filters.area['value']
      query = query ? `${query}&${q}` : q
    }

    if (lazyState.filters.engineer['value']) {
      const q = 'engineer._id=' + lazyState.filters.engineer['value']
      query = query ? `${query}&${q}` : q
    }

    const q =
      '$or=' +
      encodeURIComponent(JSON.stringify([{ name: { $regex: '^T' } }, { name: { $regex: '^C' } }]))
    query = query ? `${query}&${q}` : q

    const dateRange = {}
    if (lazyState.filters.startDate['value']) {
      dateRange['$gte'] = (lazyState.filters.startDate['value'] as Date).getTime()
    }

    if (lazyState.filters.endDate['value']) {
      dateRange['$lte'] = (lazyState.filters.endDate['value'] as Date).getTime()
    }

    if (dateRange['$gte'] || dateRange['$lte']) {
      const dateRangeStr = JSON.stringify(dateRange)
      const q = 'startDate=' + encodeURIComponent(dateRangeStr)
      query = query ? `${query}&${q}` : q
    }

    if (!query) {
      return
    }

    query = queryString2JsonString(query)
    query = query ? `query=${query}&limit=${lazyState.rows}` : `limit=${lazyState.rows}`
    query = `${query}&skip=${lazyState.first}&countTotal=true`
    if (lazyState.sortField) {
      const sort = {
        [lazyState.sortField]: lazyState.sortOrder
      }
      query = `${query}&sort=${JSON.stringify(sort)}`
    }
    // query = `${query}&_sumlvl=${lazyState.sumlvl}`

    fetchProjects(query).catch((err) => {
      console.error(err)
      toast.error('Error loading projects.')
    })
  }, [lazyState])

  useEffect(() => {
    if (projData) {
      const projList = protoService.decodeProtoResponse(projData, 'project')
      if (projData.resultString) {
        const result = JSON.parse(projData.resultString)
        if (result.totalCount && currentUser && currentUser.role && currentUser.role.raceTeamId) {
          setTotalRecords(result.totalCount[currentUser.role.raceTeamId].n)
        }
      }
      setProjectList(projList)
    }
  }, [projData])

  const navigate = useNavigate()

  const handleApplyFilter = () => {
    const filters: DataTableFilterMeta = { ...defaultFilters }
    /*
    if (filterDateRange) {
      if (filterDateRange[0]) {
        filters.startDate = { value: filterDateRange[0], matchMode: 'dateAfter' }
      }
      if (filterDateRange[1]) {
        filters.endDate = { value: filterDateRange[1], matchMode: 'dateBefore' }
      }
    }

    if (filterEngineer) {
      filters.engineer = { value: filterEngineer, matchMode: 'equals' }
    }

    if (filterArea) {
      filters.area = { value: filterArea, matchMode: 'equals' }
    }

    if (filterStatus) {
      filters.status = { value: ProjectStatus[filterStatus], matchMode: 'equals' }
    }
    */

    setlazyState({
      ...lazyState,
      first: 0,
      page: 0,
      filters
    })
  }

  const handleDataPage = (event: DataTablePageEvent) => {
    setlazyState({
      ...lazyState,
      first: event.first,
      page: event.page as number,
      rows: event.rows
    })
  }

  const handleSort = (event: DataTableSortEvent) => {
    setlazyState({
      ...lazyState,
      first: 0,
      page: 0,
      sortField: event.sortField,
      sortOrder: event.sortOrder
    })
  }

  return (
    <div className="basis-0 grow flex flex-col items-stretch mt-2 min-w-full">
      <div className="flex items-start justify-between space-x-4">
        <div className="text-dark-blue dark:text-white page-title">CFD Headline Data</div>
        <div className="flex flex-col items-stretch basis-0 grow space-x-2 space-y-2">
          <div className="flex items-center space-x-2">
            <div className=" justify-self-end">Project</div>
            <Dropdown
              value={selectedProjectId}
              pt={{
                root: {
                  className: 'basis-0 min-w-[fit]'
                },
                filterInput: {
                  className: '!py-1 min-w-[fit]'
                },
                input: {
                  className: 'py-1'
                }
              }}
              filter
              filterBy="name,projectId"
              placeholder={loadingProjects ? 'Loading...' : 'Select Project'}
              onChange={(e) => setSelectedProjectId(e.value)}
              options={projectList}
              optionValue="_id"
              optionLabel="name"
              useOptionAsValue
              valueTemplate={(item) => {
                if (item) {
                  return `P${item.projectId} - ` + item.name
                } else {
                  return 'Select Project'
                }
              }}
              itemTemplate={(item) => {
                return `P${item.projectId} - ` + item.name
              }}
              emptyMessage="&nbsp;&nbsp;No data"
            />
            <div className="col-span-2"></div>
            <div className=" justify-self-end">Geometry</div>
            <Dropdown
              value={selectedGeometryId}
              pt={{
                root: {
                  className: 'basis-0 min-w-[fit]'
                },
                input: {
                  className: 'py-1 min-w-[fit]'
                },
                filterInput: {
                  className: '!py-1 min-w-[fit]'
                }
              }}
              filter
              filterBy="geoId,name"
              placeholder="Select Geometry"
              onChange={(e) => setSelectedGeometryId(e.value)}
              options={geometryList}
              optionValue="_id"
              optionLabel="name"
              useOptionAsValue
              valueTemplate={(item) => {
                if (item) {
                  return `G${item.geoId} - ` + item.name
                } else {
                  return 'Select Geometry'
                }
              }}
              itemTemplate={(item) => {
                return `G${item.geoId} - ` + item.name
              }}
              emptyMessage="&nbsp;&nbsp;No data"
            />
            <div className=" justify-self-end ">Vehicle States</div>
            <Dropdown
              value={selectedVSId}
              pt={{
                root: {
                  className: 'basis-0 min-w-[fit]'
                },
                input: {
                  className: 'py-1 min-w-[fit]'
                },
                filterInput: {
                  className: '!py-1 min-w-[fit]'
                }
              }}
              filter
              filterBy="vehicleStateId,name"
              placeholder="Select Vehicle State"
              onChange={(e) => setSelectedVSId(e.value)}
              options={vsList}
              optionValue="_id"
              optionLabel="name"
              useOptionAsValue
              valueTemplate={(item) => {
                if (item) {
                  return `${item.vehicleStateId} - ` + item.name
                } else {
                  return 'Select Vehicle State'
                }
              }}
              itemTemplate={(item) => {
                return `${item.vehicleStateId} - ` + item.name
              }}
              emptyMessage="&nbsp;&nbsp;No data"
            />
            <div className=" justify-self-end ">Run</div>
          </div>
        </div>
        <Button onClick={handleApplyFilter} tooltip='Apply Filters' tooltipOptions={{ position: "bottom" }}>Apply Filters</Button>
      </div>
      <div className="flex mt-4 space-x-1">
        <div className="">
          <TableVirtuoso
            style={{ height: '500px', width: 100, border: '1px solid lightgray' }}
            totalCount={refList.length}
            components={{
              Table: ({ style, ...props }) => {
                return (
                  <table
                    {...props}
                    style={{
                      ...style,
                      width: '100%',
                      tableLayout: 'fixed',
                      borderCollapse: 'collapse',
                      borderSpacing: 0
                    }}
                  />
                )
              },
              TableHead: (props) => {
                return (
                  <thead>
                    <tr className="bg-gray-300 sticky top-0 z-20">
                      <th className="bg-gray-300">Baseline</th>
                    </tr>
                  </thead>
                )
              },
              TableRow: (props) => {
                const index = props['data-index']
                const row = refRows[index]
                return (
                  <tr {...props}>
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id}>
                        <div className="flex items-center pb-0.5 px-2">
                          <Checkbox
                            pt={{
                              root: {
                                className: 'border-0 flex items-center !w-5 !h-5'
                              },
                              input: {
                                className: 'max-w-[16px] max-h-[16px]',
                                ['data-index']: index
                              },
                              icon: {
                                className: 'max-w-[12px] max-h-[12px]'
                              }
                            }}
                            value={cell.getValue()}
                            inputId={`ref-${cell.id}`}
                            name="ref"
                            checked={selectedRef === cell.getValue()}
                            onChange={handleBaseline}
                          />
                          <label htmlFor={`ref-${cell.id}`}>{cell.getValue() as string}</label>
                        </div>
                      </td>
                    ))}
                  </tr>
                )
              }
            }}
          />
        </div>
        <div className="grow">
          <TableVirtuoso
            style={{ height: '500px' }}
            className="border border-gray-300 dark:border-gray-500"
            totalCount={dataRows.length}
            components={{
              Table: ({ style, ...props }) => {
                return (
                  <table
                    {...props}
                    style={{
                      ...style,
                      width: '100%',
                      borderCollapse: 'collapse',
                      borderSpacing: 0,
                      border: 'none'
                    }}
                  />
                )
              },
              TableHead: (props) => {
                return (
                  <thead>
                    {dataTable.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id} className="bg-gray-300 sticky top-0 z-20">
                        {headerGroup.headers.map((header, index) => {
                          const { column } = header
                          return (
                            <th
                              key={header.id}
                              colSpan={header.colSpan}
                              className="font-medium bg-gray-300 dark:bg-black dark:text-gray-500 dark:border-gray-500 border-gray-400"
                              //IMPORTANT: This is where the magic happens!
                              style={{
                                ...getCommonPinningStyles(column),
                                zIndex: index > 0 ? 20 : 21,
                                paddingLeft: 8,
                                paddingRight: 8
                              }}>
                              <div className="whitespace-nowrap">
                                {header.isPlaceholder
                                  ? null
                                  : flexRender(header.column.columnDef.header, header.getContext())}
                              </div>
                            </th>
                          )
                        })}
                      </tr>
                    ))}
                  </thead>
                )
              },
              TableRow: (props) => {
                const index = props['data-index']
                const row = dataRows[index]

                const stickyStyle = {
                  boxShadow: '-4px 0 4px -4px gray inset',
                  left: 0,
                  opacity: 0.95,
                  position: 'sticky',
                  zIndex: 1,
                  paddingLeft: '4px',
                  background: 'white'
                }

                return (
                  <tr {...props}>
                    {row.getVisibleCells().map((cell, index) => (
                      <td
                        key={cell.id}
                        className="border bg-white border-gray-300 dark:text-gray-500 dark:border-gray-500 !py-0"
                        style={
                          ((index === 0 ? stickyStyle : undefined) || {
                            padding: '4px'
                          }) as CSSProperties
                        }>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                )
              }
            }}
            fixedHeaderContent={() => {
              return dataTable.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} style={{ background: 'lightgray', margin: 0 }}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        style={{
                          width: header.getSize(),
                          borderBottom: '1px solid lightgray',
                          padding: '2px 4px',
                          textAlign: 'left'
                        }}>
                        {header.isPlaceholder ? null : (
                          // eslint-disable-next-line
                          <div
                            {...{
                              style: header.column.getCanSort()
                                ? { cursor: 'pointer', userSelect: 'none' }
                                : {},
                              onClick: header.column.getToggleSortingHandler()
                            }}>
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {{
                              asc: ' ↑',
                              desc: ' ↓'
                            }[header.column.getIsSorted() as SortDirection] ?? null}
                          </div>
                        )}
                      </th>
                    )
                  })}
                </tr>
              ))
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default DataOverview
