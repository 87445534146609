const URL_SEARCH_KEYWORD: string = "?"

// windowLocationHref: the current url in browser (window.location.href )
export const extractTempQuery = (windowLocationHref: string): string | null => {

    if (windowLocationHref.includes(URL_SEARCH_KEYWORD)){
        const searchIndex: number = windowLocationHref.indexOf(URL_SEARCH_KEYWORD)
        const queryKeyAndId: string = windowLocationHref.slice(searchIndex + 1)
        // console.log("urlQuery:", urlQuery)
        // const assignIndex: number = queryKeyAndId.indexOf("=")
        // const searchIdValue: string | undefined = queryKeyAndId.slice(assignIndex + 1)
        return queryKeyAndId
    }
    return null
}

export const getQueryId = (session_storage_key: string): string | undefined => {
    const queryId: string | null = window.sessionStorage.getItem(session_storage_key)
    if (queryId == null){
        return undefined
    }
    return queryId
}

export const setQueryId = (session_storage_key: string, queryId: string | null) => {
    if (queryId == null){
        return
    }
    window.sessionStorage.setItem(session_storage_key, queryId);  
}