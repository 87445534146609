/* eslint-disable react/jsx-boolean-value */
import { PropsWithChildren } from "react"
import { ReactComponent as LogoIcon } from '../assets/svg/gm-logo.svg'

const LoadingModal = ({ }: PropsWithChildren) => {
  return (
    <div className="flex items-center justify-center h-screen w-screen bg-white">
      <div className="flex flex-col items-center justify-center p-8">
        <LogoIcon className="animate-pulse h-full w-full fill-gm-blue" />
        <p className="animate-pulse text-lg font-semibold mt-4">Loading...</p>
      </div>
    </div>
  )
}

export default LoadingModal
