import { useContext, createContext } from 'react';
import { TreeNode, workflowSubmissionBreadcrumbTitles, workflowSubmissionApiRoutes, workflowSubmissionPathLinkageKeys } from '../../utils/tree';
import { BreadcrumbPaths } from '../../utils/breadcrumb_paths';

// note: useContext is like a global scope component wrapper:
//pass in a value in the context component and it can be seen in ALL DESCendants
// Create own context hook to contain vars and f(x) relating to global val for decsendant children
// General Composition: pass in Components than individual data
// note: can set a function with properties, so can store relavent components in one mega component (almost tree like)
// -- limits where components can be used! Only in Mega component or not

const breadcrumbTree = new BreadcrumbPaths()
breadcrumbTree.addBreadcrumbPath(
  workflowSubmissionApiRoutes,
  workflowSubmissionPathLinkageKeys,
  workflowSubmissionBreadcrumbTitles
)

const BreadcrumbNameUpdateContext = createContext<(route: string, name: string) => void>((route: string, name: string) => { })

export function useBreadcrumbNameUpdate() {
  const context = useContext(BreadcrumbNameUpdateContext)
  if (!context) {
    throw new Error('Need BreadcrumbNameUpdateContext')
  }
  return context
}

const BreadcrumbGetRouteQueryIdContext = createContext<(route: string) => string | undefined>((route: string) => undefined)
export function useBreadcrumbQueryIdGet() {
  const context = useContext(BreadcrumbGetRouteQueryIdContext)
  if (!context) {
    throw new Error('Need BreadcrumbGetRouteQueryIdContext')
  }
  return context
}

const BreadcrumbPathsPrintContext = createContext(() => { })
export function useBreadcrumbPathsPrint() {
  const context = useContext(BreadcrumbPathsPrintContext)
  if (!context) {
    throw new Error('Need BreadcrumbPathsPrintContext')
  }
  return context
}


const BreadcrumbGetNamesContext = createContext<(routePaths: string[]) => string[]>((routePaths: string[]) => [])
export function useBreadcrumbNamesGet() {
  const context = useContext(BreadcrumbGetNamesContext)
  if (!context) {
    throw new Error('Need BreadcrumbGetNamesContext')
  }
  return context
}


const BreadcrumbGetTitlesContext = createContext<(routePaths: string[]) => string[]>((routePaths: string[]) => [])
export function useBreadcrumbTitlesGet() {
  const context = useContext(BreadcrumbGetTitlesContext)
  if (!context) {
    throw new Error('Need BreadcrumbGetTitlesContext')
  }
  return context
}
const BreadcrumbUpdateTitlesContext = createContext<(routePaths: string[]) => string[]>((routePaths: string[]) => [])
export function useBreadcrumbTitlesUpdate() {
  const context = useContext(BreadcrumbUpdateTitlesContext)
  if (!context) {
    throw new Error('Need BreadcrumbUpdateTitlesContext')
  }
  return context
}


const BreadcrumbGetUrlsContext = createContext<(routePaths: string[]) => string[]>((routePaths: string[]) => [])
export function useBreadcrumbUrlsGet() {
  const context = useContext(BreadcrumbGetUrlsContext)
  if (!context) {
    throw new Error('Need BreadcrumbGetUrlsContext')
  }
  return context
}
const BreadcrumbUpdateUrlContext = createContext<(route: string, url: string) => void>((route: string, url: string) => { })
export function useBreadcrumbUrlUpdate() {
  const context = useContext(BreadcrumbUpdateUrlContext)
  if (!context) {
    throw new Error('Need BreadcrumbUpdateUrlsContext')
  }
  return context
}

const BreacrumbGetRoutePath = createContext<(routeName: string) => string[]>((routeName: string) => [])
export function useBreadcrumbRoutePathGet() {
  const context = useContext(BreacrumbGetRoutePath)
  if (!context) {
    throw new Error('Need BreadcrumbUpdateUrlsContext')
  }
  return context
}

export const BreadcrumbProvider = ({ children }) => {

  //Below are functions that users can use to update the Breadcrumb tree
  // so no one touches the breadcrumb tree directly

  function setBreadcrumbName(route: string, name: string) {

    breadcrumbTree.setBreadcrumbName(route, name)
  }

  function getBreadcrumbQueryId(route: string): string | undefined {
    const out: TreeNode | null = breadcrumbTree.findNodeRoute(route)

    return out?.getValue()
  }

  function getBreadcrumbNames(routePaths: string[]): string[] {
    return breadcrumbTree.getBreadcrumbNames(routePaths)
  }

  function getBreadcrumbTitles(routePaths: string[]): string[] {
    return breadcrumbTree.getBreadcrumbTitles(routePaths)
  }

  function setBreadcrumbUrl(route: string, url: string) {
    breadcrumbTree.setBreadcrumbUrl(route, url)
  }
  function getBreadcrumbUrls(routePaths: string[]): string[] {
    return breadcrumbTree.getBreadcrumbUrl(routePaths)
  }

  function getBreadcrumbRoutePath(routeName: string): string[]{
    return breadcrumbTree.getBreadcrumbRoutePath(routeName)
  }

  function printBreadcrumbPaths(): void {
    breadcrumbTree.print()
  }


  // Context Provider: passes "value" (ex: value={tree}, value={displayTree}) to its children and descendant components
  // so functions are available throughout children components thru custom hooks (ex: useBreadcrumbUrlUpdate())
  // Desc func comps get the "value" w/ above custom hooks
  return (
    <BreadcrumbNameUpdateContext.Provider value={setBreadcrumbName}>
      <BreadcrumbGetRouteQueryIdContext.Provider value={getBreadcrumbQueryId}>
        <BreadcrumbGetNamesContext.Provider value={getBreadcrumbNames}>
          <BreadcrumbGetTitlesContext.Provider value={getBreadcrumbTitles}>

            <BreadcrumbGetUrlsContext.Provider value={getBreadcrumbUrls}>
              <BreadcrumbUpdateUrlContext.Provider value={setBreadcrumbUrl}>
                {/* for debugging*/}
                <BreadcrumbPathsPrintContext.Provider value={printBreadcrumbPaths}>
                  <BreacrumbGetRoutePath.Provider value={getBreadcrumbRoutePath}>
                    {children}
                  </BreacrumbGetRoutePath.Provider>
                </BreadcrumbPathsPrintContext.Provider>

              </BreadcrumbUpdateUrlContext.Provider>
            </BreadcrumbGetUrlsContext.Provider>

          </BreadcrumbGetTitlesContext.Provider>
        </BreadcrumbGetNamesContext.Provider>
      </BreadcrumbGetRouteQueryIdContext.Provider>
    </BreadcrumbNameUpdateContext.Provider>
  )
}
