import { FC, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router'
import { useRecoilValue } from 'recoil'
import { useCreateProject } from '../api/project-API'
import ProjectForm from '../components/project/ProjectForm'
import { protoService } from '../proto/ProtoService'
import { Project, ProjectConfig } from '../types/types'
import { cloneAttitudeSet, cloneDomainSet, cloneMethodologySet, cloneSimulationSet, cloneTyreSet, cloneUser } from '../types/util'
import { DummyProject } from '../utils/constants'
import { currentTeamState, currentVehicleState } from '../utils/state-atoms'
import Breadcrumb, { BreadcrumbItem } from '../components/Breadcrumb'

const NewProject: FC = () => {
  const [newProject] = useState<Project>(DummyProject)
  const [breadcrumItems, setBreadcrumItems] = useState<BreadcrumbItem[]>([])
  const currentTeam = useRecoilValue(currentTeamState)
  const currentVehicle = useRecoilValue(currentVehicleState)
  const navigate = useNavigate()

  const [{ loading: creatingProject, }, createProject] =
    useCreateProject()

  useEffect(() => {
    setBreadcrumItems([
      {
        title: 'CAR',
        name: currentVehicle?.name || '',
        link: `/projects?vehicleId=${currentVehicle?._id}`
      }, {
        name: 'New Project'
      }
    ])
  }, [])

  const saveProject = async (projectData: Project) => {
    const newProj = new Project()
    newProj.status = projectData.status
    newProj.isBaseline = projectData.isBaseline
    newProj.raceTeamId = currentTeam
    newProj.startDate = projectData.stDate ? projectData.stDate.getTime() : 0
    newProj.description = projectData.description
    newProj.area = projectData.area
    newProj.vehicleId = currentVehicle?._id as string
    newProj.engineer = cloneUser(projectData.engineer)
    newProj.referenceRun = projectData.referenceRun
    newProj.referenceRunName = projectData.referenceRunName
    newProj.baselineRunName = projectData.baselineRunName
    newProj.baselineRunId = projectData.baselineRunId
    newProj.baselineSubmissionTemplate = projectData.baselineSubmissionTemplate
    newProj.methodSet = cloneMethodologySet(projectData.methodSet)
    newProj.attitudeSet = cloneAttitudeSet(projectData.attitudeSet)
    newProj.domainSet = cloneDomainSet(projectData.domainSet)
    newProj.tyreSet = cloneTyreSet(projectData.tyreSet)
    newProj.simulationSet = cloneSimulationSet(projectData.simulationSet)

    if (projectData.projectConfig) {
      newProj.projectConfig = new ProjectConfig(projectData.projectConfig)
    }

    const dataToPost = protoService.encodePayload(newProj, 'project')

    try {
      const res = await createProject({
        data: dataToPost
      })

      if (res.data.protoBytesList && res.data.protoBytesList.length > 0) {
        const newProj = protoService.deSerialize(res.data.protoBytesList[0], 'project')
        navigate('/projects/' + newProj._id)
      }
      toast.success('Project saved.')
    } catch (err) {
      console.error(err)
      toast.error('Error saving project.')
    }
  }

  return (
    <>
      <div className="relative w-full flex flex-col items-stretch mt-6 space-y-6">
        <Breadcrumb items={breadcrumItems} />
        <ProjectForm
          saving={creatingProject}
          onSave={saveProject}
          initProject={newProject}
          mode="new"
        />
      </div>
    </>
  )
}

export default NewProject
