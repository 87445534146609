import { RunConfigCase, Workflow, WorkflowRunGroup, WorkflowSubmission } from "@aero-platform/shared"
import { DataTableExpandedRows, DataTableRowExpansionTemplate } from "primereact/datatable"
import TaskList from "./TaskList"
import { CollapseIcon, ExpandIcon } from "../Icons"
import { RunStatusCircle } from "./StatusCircle"
import { WorkflowStatusDef } from "../../utils/constants"
import { formatDateNumber } from "../../utils/intl"

export const getMatchingWorkflowRunGroup = (workflowRunGroups: WorkflowRunGroup[] | undefined, name: string): WorkflowRunGroup | null => {
  if (workflowRunGroups === undefined) {
    return null
  }

  for (let i = 0; i < workflowRunGroups.length; i++) {
    let workflowRunGroup: WorkflowRunGroup = workflowRunGroups[i]

    //assuming 1 workflow w/ inner vehicleState per WorkflowRunGroup
    if (workflowRunGroup?.workflow[0].runConfigCases[0]?.name === name) {
      return workflowRunGroup
    }
  }
  return null
}

const StateRowExpansionTemplate = (
  workflowSubmissionId: string,
  workflowRunGroups: WorkflowRunGroup[],
  expandedWorkflows: DataTableExpandedRows,
  setExpandedWorkflows
) => {
  return (rowData: WorkflowRunGroup, options: DataTableRowExpansionTemplate) => {
    console.log('idx', options)
    if (!workflowRunGroups || workflowRunGroups.length === 0) {
      return null
    }

    // get WorkflowRunGroup that matches rowData's name
    const rowVehicleStateName: string = rowData.name
    const workflowRunGroup: WorkflowRunGroup | null = getMatchingWorkflowRunGroup(workflowRunGroups, rowVehicleStateName)

    if (!workflowRunGroup || workflowRunGroup.workflow === undefined || workflowRunGroup.workflow.length === 0) {
      return null
    }

    return (workflowRunGroup?.workflow.map((workflow: Workflow, index: number) => {
      const rowName: string = workflow?.systemRunId
      return (
        <section className="flex flex-col items-stretch ml-8">
          <div className="grid grid-cols-12 pb-8">
            <div className="col-span-12 flex items-center space-x-2 text-sm text-gm-blue dark:text-light-blue cursor-pointer"
              onClick={() => {
                const copyExpandedRows: DataTableExpandedRows = { ...expandedWorkflows }
                if (expandedWorkflows[rowName]) {  // if rowName exists in expandedRows
                  // delete rowName from expandedRows
                  delete copyExpandedRows[rowName]
                } else { // if rowName doesnt exist, add rowName to expandedRows
                  copyExpandedRows[rowName] = true
                }
                setExpandedWorkflows(copyExpandedRows)
              }}>
              {
                expandedWorkflows[rowName] ?
                  <CollapseIcon className="fill-dark-blue dark:fill-light-blue h-6 w-6" /> :
                  <ExpandIcon className="fill-dark-blue dark:fill-light-blue h-6 w-6" />}
              <div className="font-semibold">Workflow #{index + 1}:</div>
              <div>{workflow?.name}</div>
            </div>

            <div className="col-span-2 px-8 flex items-center">
              <RunStatusCircle status={workflow?.overallStatus} />
              <div className="flex flex-col">
                <div className="text-[10px]">Status</div>
                <div>{WorkflowStatusDef[workflow?.overallStatus].name}</div>
              </div>

            </div>

            <div className="col-span-2 px-8 ">
              <div className="text-[10px]">System Run ID</div>
              <div>{workflow?.systemRunId}</div>
            </div>

            <div className="col-span-4">
              <div className="text-[10px]">Description</div>
              <div>{workflow?.description}</div>
            </div>

            <div className="col-span-2 px-8 ">
              <div className="text-[10px]">Created</div>
              <div>{workflow?.timeCreated ? formatDateNumber(0 + workflow?.timeCreated, 'dd-MM-yy HH:mm:ss(xxx)') : 'N/A'}</div>
            </div>

            <div className="col-span-2 px-8 ">
              <div className="text-[10px]">Updated</div>
              <div>{workflow?.timeUpdated ? formatDateNumber(0 + workflow?.timeUpdated, 'dd-MM-yy HH:mm:ss(xxx)') : 'N/A'}</div>
            </div>

          </div>
          {expandedWorkflows[rowName] && <div className="pl-8">
            <TaskList
              tasks={workflow?.workflowTasks}
              runId={workflowSubmissionId}
              workflowRunGroupSysId={workflowRunGroup.systemRunId}
              workflowSystemRunId={workflow.systemRunId}
            />
          </div>}
        </section>
      )
    }))


  }
}

export default StateRowExpansionTemplate
