import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox'
import { Column } from 'primereact/column'
import {
  DataTable,
  DataTableFilterMeta,
  DataTableSortMeta,
  SortOrder,
} from 'primereact/datatable'
import { Dropdown } from 'primereact/dropdown'
import { FC, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useGetProjects } from '../api/project-API'
import { protoService } from '../proto/ProtoService'
import { LazyTableState, Project, ProjectStatus } from '../types/types'
import { queryString2JsonString } from '../types/util'

import { Link, useSearchParams } from 'react-router-dom'
import { CloseIcon, FilterIcon, PlusIcon } from '../components/Icons'
import { Areas, CurrentUserKey, ProjectStatusList } from '../utils/constants'
import {
  currentUserState,
  currentVehicleState,
  engineerListState,
  projectListState,
  vehicleListState
} from '../utils/state-atoms'
import { CheckboxStyle } from '../utils/styling-constants'
import Breadcrumbs, { Crumb } from '../components/Breadcrumbs'
import routes from '../routing/routes'
import {
  useBreadcrumbNamesGet,
  useBreadcrumbRoutePathGet,
  useBreadcrumbTitlesGet,
  useBreadcrumbUrlsGet
} from '../contexts/breadcrumbContexts/BreadcrumbContext'
import { createBreadcrumbs } from '../utils/breadcrumb_paths'
import { BreadcrumbItem } from '../components/Breadcrumb'
import { extractTempQuery, getQueryId, setQueryId } from '../utils/global_shared_tempURL'
import ProjectStatusCell from '../components/project/ProjectStatusCell'
import useSetBreadcrumbs from '../hooks/useSetBreadcrumbs'

const PROJECT_FILTER_KEY = 'PROJECT_FILTER'

type ProjectFilter = {
  vehicle?: string
  byMeOnly?: string
  status?: string
  engineer?: string
  area?: string
}

const defaultFilters: DataTableFilterMeta = {
  vehicle: { value: undefined, matchMode: 'equals' },
  status: { value: undefined, matchMode: 'equals' },
  engineer: { value: undefined, matchMode: 'contains' },
  area: { value: undefined, matchMode: 'equals' },
  byMeOnly: { value: false, matchMode: 'equals' }
}

export const SESSION_STORAGE_VEHICLE_ID_KEY: string = "vehicleId="

const Projects: FC = () => {
  const savedFilterString = localStorage.getItem(PROJECT_FILTER_KEY)
  let savedFilter: ProjectFilter | null = null
  if (savedFilterString) {
    savedFilter = JSON.parse(savedFilterString) as ProjectFilter
  }

  const currentUser = useRecoilValue(currentUserState)
  const [searchParams] = useSearchParams()
  const [selectedVehicleId] = useState<string>(
    searchParams.get('vehicleId') || ''
  )

  const [byMeOnly, setByMeOnly] = useState(savedFilter ? savedFilter.byMeOnly === 'true' : false)
  const [filterEngineer, setFilterEngineer] = useState<string | undefined>(savedFilter ? savedFilter.engineer : '')
  const [filterStatus, setFilterStatus] = useState<string | undefined>(savedFilter ? savedFilter.status : '')
  const [filterArea, setFilterArea] = useState<string | undefined>(savedFilter ? savedFilter.area : '')


  const [{ data: projData, loading: laodingProjects, }, fetchProjects] = useGetProjects()

  /* Breadcrumb init and set */
  const getBreadcrumbRoutePath = useBreadcrumbRoutePathGet()
  const ANCESTOR_API_ROUTES: string[] = getBreadcrumbRoutePath(routes.Projects.absolutePath)

  // ScrollBarCheck
  const [scrollBarCheck, setScrollBarCheck] = useState(false)

  // get Vehicle search id from ur =>  "vehicleId=66857ee159a15bd4829ff73c"
  const urlQuery: string | null = extractTempQuery(window.location.href)
  if (urlQuery != null && urlQuery.length > 0) {
    //if query ID exists, then update the global variable bc visited this page now
    const searchIdValue: string | undefined = urlQuery?.replace(SESSION_STORAGE_VEHICLE_ID_KEY, "")
    setQueryId(SESSION_STORAGE_VEHICLE_ID_KEY, searchIdValue)
  }

  const searchIdValue: string | undefined = getQueryId(SESSION_STORAGE_VEHICLE_ID_KEY)

  useSetBreadcrumbs(routes.Projects.absolutePath, searchIdValue)

  // setting up breadcrumbs from Breadcrumb Tree for display
  const getBreadcrumbNames = useBreadcrumbNamesGet()
  const names: string[] = getBreadcrumbNames(ANCESTOR_API_ROUTES)
  const getBreadcrumbTitles = useBreadcrumbTitlesGet()
  const titles: string[] = getBreadcrumbTitles(ANCESTOR_API_ROUTES)
  const getBreadcrumbUrls = useBreadcrumbUrlsGet()
  const urls: string[] = getBreadcrumbUrls(ANCESTOR_API_ROUTES)

  const breadcrumbs: Crumb[] = createBreadcrumbs(titles, names, urls)

  const [breadcrumItems, setBreadcrumItems] = useState<BreadcrumbItem[]>([])

  const [filterOpen, setFilterOpen] = useState(savedFilter && (!!savedFilter.engineer || !!savedFilter.status || !!savedFilter.area))
  const toggleFilter = () => {
    setFilterOpen(!filterOpen)
  }

  const [projects, setProjects] = useRecoilState(projectListState)
  const [engineers] = useRecoilState(engineerListState)
  const vehicles = useRecoilValue(vehicleListState)
  const [currentVehicle, setCurrentVehicle] = useRecoilState(currentVehicleState)
  const [baselineSplitIndex, setBaselineSplitIndex] = useState<string>()

  const [multiSortMeta, setMultiSortMeta] = useState<DataTableSortMeta[]>([
    { field: 'isBaseline', order: -1 as SortOrder },
    { field: 'name', order: -1 as SortOrder }
  ])

  const [lazyState, setLazyState] = useState<LazyTableState>({
    first: 0,
    rows: 1000,
    page: 1,
    sortField: 'shortName',
    sortOrder: -1,
    filters: {
      ...defaultFilters,
      vehicle: { value: selectedVehicleId, matchMode: 'equals' },
      engineer: { value: savedFilter?.engineer, matchMode: 'equals' },
      area: { value: savedFilter?.area, matchMode: 'equals' },
      status: { value: savedFilter?.status, matchMode: 'equals' },
      byMeOnly: { value: savedFilter?.byMeOnly === 'true', matchMode: 'equals' }
    },
    sumlvl: 'vehicleStatesList:0,geometryList:0,tyreSetList:0,cfdSequenceList:0,method:0'
  })

  useEffect(() => {
    const scrollBarActive = document.body.scrollHeight > window.innerHeight
    setScrollBarCheck(scrollBarActive)
  }, [document.body.scrollHeight, window.innerHeight])

  useEffect(() => {
    let query
    if (lazyState.filters.vehicle['value']) {
      const q = 'vehicleId=' + lazyState.filters.vehicle['value']
      query = query ? `${query}&${q}` : q
    }

    console.log(query)
    if (!query) {
      return
    }

    const currentUserId = currentUser?._id as string
    if (currentUserId && lazyState.filters.byMeOnly['value']) {
      const q = 'createByUser=' + currentUserId
      query = query ? `${query}&${q}` : q
    }

    if (lazyState.filters.status['value']) {
      const q = 'status=' + lazyState.filters.status['value']
      query = query ? `${query}&${q}` : q
    }

    if (lazyState.filters.area['value']) {
      const q = 'area=' + lazyState.filters.area['value']
      query = query ? `${query}&${q}` : q
    }

    if (lazyState.filters.engineer['value']) {
      const q = 'engineer._id=' + lazyState.filters.engineer['value']
      query = query ? `${query}&${q}` : q
    }

    if (!query) {
      return
    }

    query = queryString2JsonString(query)
    query = query ? `query=${query}&limit=${lazyState.rows}` : `limit=${lazyState.rows}`
    query = `${query}&skip=${lazyState.first}&countTotal=true`
    if (multiSortMeta) {
      const sort = {
        [multiSortMeta[1].field]: multiSortMeta[1].order
      }
      query = `${query}&sort=${JSON.stringify(sort)}`
    }

    fetchProjects(query).catch((err) => {
      console.error(err)
      toast.error('Error loading projects.')
    })
  }, [lazyState])


  useEffect(() => {
    // this will trigger loading projects
    if (selectedVehicleId) {
      /*
      setLazyState({
        ...lazyState,
        filters: {
          ...lazyState.filters,
          vehicle: { value: selectedVehicleId, matchMode: 'equals' }
        }
      })
        */
      // get vehicle name
      const vehicle = vehicles.find((v) => v._id === selectedVehicleId)
      setBreadcrumItems([
        {
          title: 'CAR',
          name: vehicle?.name || ''
        }, {
          name: 'Projects'
        }
      ])
      setCurrentVehicle(vehicle)
    }
  }, [selectedVehicleId])

  useEffect(() => {
    // console.log("projData:", projData)
    if (projData) {
      const projList = protoService.decodeProtoResponse(projData, 'project')
      projList && setProjects(projList)
      if (projData && projData.resultString) {
        const result = JSON.parse(projData.resultString)
      }

      for (let i = 0; i < projList.length; i++) {
        if (i === 0 && !projList[i].isBaseline) break;
        if (i === 0) continue
        if (projList[i - 1].isBaseline && !projList[i].isBaseline) {
          const baselineProject = projList[i - 1]
          const newProjList = [...projList]
          const clonedProject = {
            ...baselineProject,
            _id: 'split-' + baselineProject._id,
            name: '',
            description: '',
            area: '',
            engineer: { _id: '', firstName: '', lastName: '' },
            stDate: '',
            status: '',
            shortName: '',
            createByUserName: '',
            isBaseline: true
          }
          newProjList.splice(i, 0, { ...clonedProject })
          clonedProject._id = 'next-' + baselineProject._id
          newProjList.splice(i + 1, 0, { ...clonedProject })
          setProjects(newProjList)
          setBaselineSplitIndex(projList[i - 1]._id)
          break;
        }
      }
    }
  }, [projData])


  const navigate = useNavigate()

  const handleShowMeOnlyChange = (evt: CheckboxChangeEvent) => {
    const filterToSave: ProjectFilter = {
      vehicle: selectedVehicleId,
      byMeOnly: evt.checked ? 'true' : 'false',
      status: filterStatus,
      engineer: filterEngineer,
      area: filterArea
    }
    localStorage.setItem(PROJECT_FILTER_KEY, JSON.stringify(filterToSave))

    setByMeOnly(evt.checked as boolean)
    setLazyState({
      ...lazyState,
      first: 0,
      page: 0,
      filters: {
        ...lazyState.filters,
        byMeOnly: { value: evt.checked, matchMode: 'equals' }
      }
    })
  }

  const handleCreateNewProject = () => {
    navigate('/new-project')
  }

  const handleClearFilter = () => {
    setFilterArea('')
    setFilterEngineer('')
    setFilterStatus('')
    setFilterOpen(false)
  }

  const handleApplyFilter = () => {
    const filters: DataTableFilterMeta = { ...defaultFilters }
    if (selectedVehicleId) {
      filters.vehicle = { value: selectedVehicleId, matchMode: 'equals' }
    }

    if (filterEngineer) {
      filters.engineer = { value: filterEngineer, matchMode: 'equals' }
    }

    if (filterArea) {
      filters.area = { value: filterArea, matchMode: 'equals' }
    }

    if (filterStatus) {
      filters.status = { value: ProjectStatus[filterStatus], matchMode: 'equals' }
    }

    const filterToSave: ProjectFilter = {
      vehicle: selectedVehicleId,
      byMeOnly: byMeOnly ? 'true' : 'false',
      status: filterStatus,
      engineer: filterEngineer,
      area: filterArea

    }
    localStorage.setItem(PROJECT_FILTER_KEY, JSON.stringify(filterToSave))

    setLazyState({
      ...lazyState,
      first: 0,
      page: 0,
      filters
    })
  }

  const applyRowClassName = (rowData: Project) => {
    const baseCSS = '[&>*:nth-child(n)]:!py-2 pointer-events-none !bg-transparent'
    if (baselineSplitIndex && rowData._id.includes('split-' + baselineSplitIndex)) {
      return baseCSS + ' !border-b-[2px] border-b-gray-300'
    } else if (baselineSplitIndex && rowData._id.includes('next-' + baselineSplitIndex)) {
      return baseCSS
    }
  }

  const columns = [
    {
      header: 'ID',
      field: 'projectId',
      sortable: true,
      headerStyle: {},
      headerClassName: 'w-[120px]',
      bodyClassName: 'w-[120px] id-cell',
      bodyStyle: {},
      ptHeaderCell: undefined,
      ptBodyCell: undefined,
      body: (rowData: Project) => {
        return (
          <div className='flex flex-col'>
            <Link className='white-space-nowrap' to={`/projects/${rowData.name}`}>{rowData.shortName}</Link>
            {rowData.isBaseline && <div className="text-neutral-gray dark:text-neutral text-xs">
              {rowData.name ? 'BASELINE' : ''}
            </div>}
          </div>
        )
      }
    },
    {
      header: 'Description',
      field: 'description',
      headerStyle: {},
      bodyStyle: {},
      ptHeaderCell: undefined,
      ptBodyCell: undefined
    },
    {
      header: 'Area',
      field: 'area',
      sortable: true,
      headerStyle: {},
      headerClassName: 'w-[150px]',
      bodyStyle: {},
      ptHeaderCell: undefined,
      ptBodyCell: undefined
    },
    {
      header: 'Engineer',
      headerClassName: 'w-[180px]',
      field: 'engineerName',
      sortable: true,
      body: (rowData: Project) => {
        return `${rowData.engineer.firstName} ${rowData.engineer.lastName}`
      },
      headerStyle: {},
      bodyStyle: {},
      ptHeaderCell: undefined,
      ptBodyCell: undefined
    },
    {
      header: 'Created by',
      field: 'createByUserName',
      sortable: true,
      headerStyle: {},
      headerClassName: 'w-[150px]',
      bodyStyle: {},
      ptHeaderCell: undefined,
      ptBodyCell: undefined
    },
    {
      header: 'Status',
      field: 'status',
      sortable: true,
      body: (rowData: Project) => {
        return <ProjectStatusCell status={rowData.status} />
      },
      headerStyle: {},
      headerClassName: 'w-[140px]',
      bodyStyle: {},
      ptHeaderCell: undefined,
      ptBodyCell: undefined
    }
  ]


  return (
    <div className="basis-0 grow flex flex-col items-stretch mt-6 min-w-full">
      <div className="flex items-center space-x-4">
        <div className="basis-0 grow font-bold flex items-center space-x-2 ">
          <Breadcrumbs ancestorBreadcrumbs={breadcrumbs} />
          {/* <div className="page-title">{currentVehicle && currentVehicle.vehicleId}</div> */}
          <div className="grow text-right flex justify-end items-center">
            <Checkbox
              inputId="byMeOnly"
              pt={CheckboxStyle}
              onChange={handleShowMeOnlyChange}
              checked={byMeOnly}></Checkbox>
            <label htmlFor="byMeOnly" className="ml-2 font-normal">
              Created by me only
            </label>
          </div>
        </div>
        <Button onClick={toggleFilter} className="dark-button icon-button" tooltip='Show/Hide Filters' tooltipOptions={{ position: "bottom" }} data-pr-at={!scrollBarCheck ? 'center+15 bottom' : 'center bottom'}>
          <FilterIcon className="fill-white" />
        </Button>
        <Button onClick={handleCreateNewProject} severity="secondary" className="icon-button" tooltip='Create New Project' tooltipOptions={{ position: "bottom" }} data-pr-at={!scrollBarCheck ? 'center+40 bottom' : 'center+25 bottom'}>
          <PlusIcon className="fill-white" />
        </Button>
      </div>
      {/* filter block begins */}
      {filterOpen && (
        <div className="flex items-end p-4 space-x-6 bg-gray-100 mt-4 animate-fade-in-down">
          <div className="flex flex-col items-stretch basis-0 grow space-y-2">
            <label>Responsible Engineer</label>
            <Dropdown
              pt={{
                root: {
                  className: 'min-w-full'
                },
                input: {
                  className: '!py-2'
                }
              }}
              onChange={(evt) => {
                setFilterEngineer(evt.value)
              }}
              showClear
              filter
              filterBy="firstName,lastName"
              value={filterEngineer}
              options={engineers}
              optionValue="_id"
              optionLabel="firstName"
              itemTemplate={(item) => {
                return `${item.firstName} ${item.lastName}`
              }}
              valueTemplate={(item) => {
                return item ? `${item.firstName} ${item.lastName}` : 'Select Engineer'
              }}
              placeholder="Select Engineer"
            />
          </div>
          <div className="flex flex-col items-stretch basis-0 grow space-y-2">
            <label>Status</label>
            <Dropdown
              pt={{
                root: {
                  className: 'min-w-full'
                },
                input: {
                  className: '!py-2'
                }
              }}
              onChange={(evt) => {
                console.log(evt.value)
                setFilterStatus(evt.value)
              }}
              showClear
              value={filterStatus}
              options={ProjectStatusList}
              placeholder="Select Status"
              optionValue="value"
              optionLabel="name"
            />
          </div>
          <div className="flex flex-col items-stretch basis-0 grow space-y-2">
            <label>Area</label>
            <Dropdown
              value={filterArea}
              pt={{
                root: {
                  className: 'min-w-full'
                },
                input: {
                  className: '!py-2'
                }
              }}
              showClear
              onChange={(e) => setFilterArea(e.value)}
              options={Areas}
              placeholder="Select Area"
            />
          </div>
          <div className='flex items-center justify-end space-x-2'>
            <Button onClick={handleApplyFilter} className='icon-button' tooltip='Apply Filter' tooltipOptions={{ position: "bottom" }} data-pr-at={!scrollBarCheck ? 'center+15 bottom' : 'center bottom'}>
              <FilterIcon className='fill-white' />
            </Button>
            <Button onClick={handleClearFilter} className="dark-button icon-button" tooltip='Clear Filter' tooltipOptions={{ position: "bottom" }} data-pr-at={!scrollBarCheck ? 'center+15 bottom' : 'center bottom'}>
              <CloseIcon className="fill-white h-4 w-4" />
            </Button>
          </div>
        </div>
      )}
      {/* filter block ends */}

      <DataTable
        value={projects}
        sortMode="multiple"
        multiSortMeta={multiSortMeta}
        first={lazyState.first}
        loading={laodingProjects}
        onSort={(e) => {
          if (e.multiSortMeta) {
            setMultiSortMeta([
              { field: 'isBaseline', order: -1 },
              { field: e.multiSortMeta[0].field, order: e.multiSortMeta[0].order }
            ])
          }
        }}
        dataKey="_id"
        stripedRows
        rowClassName={applyRowClassName}
        rows={lazyState.rows}
        pt={{
          root: {
            className: 'mt-4'
          },
          bodyRow: {
            className: 'table-row'
          },
        }}>
        {columns.map((item) => {
          return (
            <Column
              key={item.field}
              field={item.field}
              header={item.header}
              body={item.body}
              sortable={item.sortable}
              headerStyle={item.headerStyle}
              bodyClassName={item.bodyClassName}
              headerClassName={item.headerClassName}
              bodyStyle={item.bodyStyle}></Column>
          )
        })}
      </DataTable>
    </div >
  )
}

export default Projects
