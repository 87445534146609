import { UseAxiosResult } from 'axios-hooks'
import { useAxiosClient } from './configs/axios-setup'

export interface IRequestError {
  status: number
  text: string
}

export const useGetDomainSets = (): UseAxiosResult => {
  const [{ data, loading, error }, doFetch] = useAxiosClient(
    {
      url: '/DomainSets',
      method: 'GET'
    },
    { manual: true, autoCancel: false, useCache: false }
  )

  const refetch = (query) => {
    return doFetch({
      url: '/DomainSets' + (query ? `?${query}` : '')
    })
  }

  return [
    { data, loading, error },
    refetch,
    () => {
      return
    }
  ]
}

export const useGetDomainSet = (): UseAxiosResult => {
  const [{ data, loading, error }, doFetch] = useAxiosClient(
    {
      method: 'GET'
    },
    { manual: true, autoCancel: false, useCache: false }
  )

  const refetch = (id) => {
    return doFetch({
      url: `/DomainSets/${id}`,
    })
  }

  return [
    { data, loading, error },
    refetch,
    () => {
      return
    }
  ]
}

export const useCreateDomainSet = (): UseAxiosResult => {
  const [{ data, loading, error }, doFetch] = useAxiosClient(
    {
      url: '/DomainSets',
      method: 'POST'
    },
    { manual: true, autoCancel: false, useCache: false }
  )

  return [
    { data, loading, error },
    doFetch,
    () => {
      return
    }
  ]
}

export const useUpdateDomainSet = (): UseAxiosResult => {
  const [{ data, loading, error }, doFetch] = useAxiosClient(
    {
      method: 'PUT'
    },
    { manual: true, autoCancel: false, useCache: false }
  )

  const refetch = ({ id, data }: any) => {
    return doFetch({
      url: `/DomainSets/${id}`,
      data
    })
  }

  return [
    { data, loading, error },
    refetch,
    () => {
      return
    }
  ]
}

export const useUpdateDomainState = (): UseAxiosResult => {
  const [{ data, loading, error }, doFetch] = useAxiosClient(
    {
      method: 'PUT'
    },
    { manual: true, autoCancel: false, useCache: false }
  )

  const refetch = ({ id, data }: any) => {
    return doFetch({
      url: `/DomainSets/${id}/states`,
      data
    })
  }

  return [
    { data, loading, error },
    refetch,
    () => {
      return
    }
  ]
}
