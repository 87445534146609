import { ColumnEditorOptions } from 'primereact/column'
import { InputNumber, InputNumberValueChangeEvent } from 'primereact/inputnumber'
import { InputText } from 'primereact/inputtext'
import React from 'react'

export const InlineNumberEditor = (options: ColumnEditorOptions, width: number | string) => {
  return (
    <InputNumber
      value={options ? (options.value as number) : undefined}
      inputClassName={`!p-1`}
      inputStyle={{ width }}
      onValueChange={(e: InputNumberValueChangeEvent) =>
        options.editorCallback && options.editorCallback(e.target.value)
      }
    />
  )
}

export const InlineTextEditor = (options: ColumnEditorOptions, width: number | string) => {
  return (
    <InputText
      type="text"
      value={options ? (options.value as string) : ''}
      className={`!p-1`}
      style={{ width }}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        options.editorCallback && options.editorCallback(e.target.value)
      }
    />
  )
}
